/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperService } from '../servicios/helper.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
} from 'rxjs';
import { DireccionesService } from '../servicios/http/direcciones.service';
import { ICoordenadas, IGeoJSONCircle, IGeoJSONPoint } from 'modelos/src';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OpenLayersService } from '../servicios/openLayers.service';

@Component({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    selector: 'app-autocomplete-direccion',
    templateUrl: './autocomplete-direccion.component.html',
    styleUrls: ['./autocomplete-direccion.component.scss']
})
export class AutocompleteDireccionComponent implements OnInit, AfterViewInit {
  private loading = false;

  @ViewChild('inputDireccion') inputDireccion?: ElementRef;
  public direcciones: string[] = [];

  @Input() form?: FormGroup;
  @Input() formControlDireccion?: string;
  @Input() formControlGeoJSON?: string;
  @Input() direccion?: string;
  @Output() direccionChange = new EventEmitter<string>();
  @Output() geoJSONChange = new EventEmitter<IGeoJSONPoint | IGeoJSONCircle>();

  private ubicacion?: ICoordenadas;

  constructor(
    private helper: HelperService,
    private service: DireccionesService,
  ) {}

  public async onSelect() {
    try {
      this.direccionChange.emit(this.direccion);
      this.form?.get(this.formControlDireccion!)?.setValue(this.direccion);

      // Geocode
      const res = await this.service.geocode(this.direccion!);
      const valorActual = this.form?.get(this.formControlGeoJSON!)?.value;
      const geoJSON: IGeoJSONPoint | IGeoJSONCircle = {
        type: 'Point',
        coordinates: [res.lng, res.lat],
        radius: (valorActual as IGeoJSONCircle)?.radius,
      };
      if (this.formControlGeoJSON) {
        this.form?.get(this.formControlGeoJSON!)?.setValue(geoJSON);
      }
      this.geoJSONChange.emit(geoJSON);
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
  }

  public async buscarDireccion() {
    this.loading = true;
    try {
      const res = await this.service.autocomplete(
        this.direccion,
        this.ubicacion,
      );
      this.direcciones = res.resultados;
    } catch (error) {
      this.helper.notifError(error);
      console.error(error);
    }
    this.loading = false;
  }

  private listenDirChange(): void {
    fromEvent(this.inputDireccion?.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        filter(() => !this.loading && this.direccion?.length > 3),
        debounceTime(700),
        distinctUntilChanged(),
      )
      .subscribe(async () => {
        await this.buscarDireccion();
      });
  }

  async ngOnInit(): Promise<void> {
    this.direccion =
      this.form?.get(this.formControlDireccion)?.value || this.direccion;
    const ubicacion = await OpenLayersService.getCurrentPositionGeoJSON();
    if (ubicacion) {
      this.ubicacion = {
        lat: ubicacion[1],
        lng: ubicacion[0],
      };
    }
  }

  ngAfterViewInit(): void {
    this.listenDirChange();
  }
}
