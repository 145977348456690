@if (!this.loading) {
  <!-- Título -->
  <div class="row-center">
    <span class="title1">{{ colectivo?.identificacion }}</span>
  </div>
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedTabChange)="tabChanged($event)"
  >
    <!-- Info -->
    <mat-tab label="Info">
      <ng-template matTabContent>
        <app-detalles-colectivo-info
          class="cardContentPestaña"
          [colectivo]="colectivo"
        ></app-detalles-colectivo-info>
      </ng-template>
    </mat-tab>
    <!-- Ver Viajes/Paradas -->
    <mat-tab label="Viajes y Paradas">
      <ng-template matTabContent>
        <app-viajes-paradas
          class="cardContentPestaña"
          [vehiculo]="colectivo"
        ></app-viajes-paradas>
      </ng-template>
    </mat-tab>
    <!-- Historial de Eventos -->
    <mat-tab label="Historial Eventos">
      <ng-template matTabContent>
        <app-listado-eventos-historico
          class="cardContentPestaña"
          [id]="colectivo._id"
          tipo="Activo"
        ></app-listado-eventos-historico>
      </ng-template>
    </mat-tab>

    <!-- Codigos -->
    <mat-tab label="Códigos Eventos">
      <ng-template matTabContent>
        <app-listado-configs-eventos
          class="cardContentPestaña"
          [dispositivo]="colectivo"
          tipo="Activo"
        ></app-listado-configs-eventos>
      </ng-template>
    </mat-tab>
    <!--Eventos Personalizados -->
    <mat-tab label="Eventos Personalizados">
      <ng-template matTabContent>
        @if (params) {
          <app-listado-config-evento-usuario
            class="cardContentPestaña"
            [params]="params"
          ></app-listado-config-evento-usuario>
        }
      </ng-template>
    </mat-tab>
    <!-- Notas -->
    <mat-tab label="Notas">
      <ng-template matTabContent>
        <app-listado-notas
          class="cardContentPestaña"
          [idAsignado]="colectivo._id"
          tipo="Nota"
          tipoDispositivo="Activo"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
    <!-- Contactos -->
    <mat-tab label="Contactos">
      <ng-template matTabContent>
        <app-listado-notas
          class="cardContentPestaña"
          [idAsignado]="this.colectivo._id"
          tipo="Contacto"
          tipoDispositivo="Activo"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
    <!-- Documentos -->
    <mat-tab label="Documentos">
      <ng-template matTabContent>
        <app-listado-documentos
          class="cardContentPestaña"
          [idEntidad]="colectivo._id"
          [idCliente]="colectivo.idCliente"
          tipoEntidad="vehiculo"
        ></app-listado-documentos>
      </ng-template>
    </mat-tab>
    @if (helper.esNivel0()) {
      <!-- Trackeos -->
      <mat-tab label="Trackeos">
        <ng-template matTabContent>
          <app-listado-trackeos
            class="cardContentPestaña"
            [vehiculo]="colectivo"
          ></app-listado-trackeos>
        </ng-template>
      </mat-tab>
      <!-- Log Trackeos-->
      <mat-tab label="Log Trackeos">
        <ng-template matTabContent>
          <app-listado-logs-trackeos
            class="cardContentPestaña"
            [vehiculo]="colectivo"
          ></app-listado-logs-trackeos>
        </ng-template>
      </mat-tab>
    }
    <!-- Servicios Técnicos -->
    <mat-tab label="Servicios Técnicos">
      <ng-template matTabContent>
        <app-eventos-tecnicos [activo]="colectivo"></app-eventos-tecnicos>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
}
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.colectivos()"
    text="Volver"
    icon="arrow_back"
    z
  ></app-button>
</div>
