<h2
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  Configurar comunicador de {{ data.nombre }}
</h2>
<mat-dialog-content>
  <!-- modelo?: string;
    passComunicador?: string;
    idComunicador?: string;
    apn?: string;
    usr?: string;
    pwd?: string; -->
  <!-- ED5200*1234,ID=6573,igprs.claro.com.ar=clarogprs,PWD=clarogprs999,IP=34.46.185.217,PORT=6000,,, -->
  <!-- <ul>
    <li>Comunicador: {{ data.comunicador?.modelo }}</li>
    <li>Password Comunicador: {{ data.passwordComunicador }}</li>
    <li>ID: {{ data.idComunicador }}</li>
    <li>ID Único: {{ data.idUnicoComunicador }}</li>
    <li>SIM 1</li>
    <li>APN: {{ data.sim1.apn }}</li>
    <li>Usuario: {{ data.sim1.usuario }}</li>
    <li>Password: {{ data.sim1.password }}</li>
    <li>SIM 2</li>
    <li>APN: {{ data.sim2.apn }}</li>
    <li>Usuario: {{ data.sim2.usuario }}</li>
    <li>Password: {{ data.sim2.password }}</li>
  </ul> -->
  <div class="column-center" style="width: 100%; gap: 8px">
    <div class="row-space-between" style="width: 100%">
      ID Comunicador
      @if (this.data.idUnicoComunicador) {
        <span>{{ this.data.idUnicoComunicador }}</span>
      } @else {
        <span class="no-info">No configurado</span>
      }
    </div>
    <div class="row-space-between" style="width: 100%">
      Password Comunicador
      @if (this.data.passwordComunicador) {
        <span>{{ this.data.passwordComunicador }}</span>
      } @else {
        <span class="no-info">No configurado</span>
      }
    </div>
    <div class="row-center" style="width: 100%; gap: 8px">
      <div class="column-center borde" style="gap: 8px; width: 50%">
        <span class="sim">SIM 1</span>
        <div class="row-space-between" style="width: 100%">
          Teléfono
          @if (this.data.sim1.numero) {
            <span>{{ this.data.sim1.numero }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          APN
          @if (this.data.sim1.apn) {
            <span>{{ this.data.sim1.apn }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          Usuario
          @if (this.data.sim1.usuario) {
            <span>{{ this.data.sim1.usuario }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          Password
          @if (this.data.sim1.password) {
            <span>{{ this.data.sim1.password }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
      </div>
      <div class="column-center borde" style="gap: 8px; width: 50%">
        <span class="sim">SIM 2</span>
        <div class="row-space-between" style="width: 100%">
          Teléfono
          @if (this.data.sim2.numero) {
            <span>{{ this.data.sim2.numero }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          APN
          @if (this.data.sim2.apn) {
            <span>{{ this.data.sim2.apn }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          Usuario
          @if (this.data.sim2.usuario) {
            <span>{{ this.data.sim2.usuario }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
        <div class="row-space-between" style="width: 100%">
          Password
          @if (this.data.sim2.password) {
            <span>{{ this.data.sim2.password }}</span>
          } @else {
            <span class="no-info">No configurado</span>
          }
        </div>
      </div>
    </div>
  </div>
  <br />
  <mat-form-field>
    <mat-label>Sims</mat-label>
    <mat-select (selectionChange)="this.selected.set($event.value)">
      @for (s of sims; track s) {
        <mat-option [value]="s">{{ s.numero }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <br />
  @if (mensajePreview()) {
    <div class="row-center" style="width: 100%">
      <span>{{ mensajePreview() }}</span>
    </div>
  } @else {
    <div class="row-center" style="width: 100%">
      <span>No hay sim seleccionada</span>
    </div>
  }
  <br />
  @if (error()) {
    <div class="row-center" style="width: 100%">
      <span class="error">{{ error() }}</span>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" style="gap: 8px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    text="Cerrar"
    icon="arrow_back"
    mat-dialog-close
  ></app-button>
  <app-button
    matType="mat-fab extended"
    color="accent"
    text="Enviar"
    icon="send"
    type="button"
    (click)="enviarConfig()"
    [disabled]="!selected || loading() || !valid()"
  ></app-button>
</mat-dialog-actions>
