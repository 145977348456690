import { EventEmitter, Injectable } from '@angular/core';
import { ListadosService } from './listados.service';
import { Subscription } from 'rxjs';
import { IEvento } from 'modelos/src';
import { DialogService } from '../dialog/dialog.service';
import { Howl } from 'howler';
import { IdleService } from './idle.service';

@Injectable({
  providedIn: 'root',
})
export class ListadoEventosVivoService {
  private eventosAnteriores: IEvento[] = [];
  public eventos: IEvento[] = [];
  public totalCount = 0;

  private sonidos = {
    Fondo: new Howl({
      src: ['/assets/sonidos/empty_loop_for_js_performance.ogg'],
      volume: 0.5,
      autoplay: false,
      loop: true,
    }),
    Sirena: new Howl({
      src: ['/assets/sonidos/sirena1.mp3'],
      volume: 1,
      autoplay: false,
      loop: false,
    }),
    Campana: new Howl({
      src: ['/assets/sonidos/campana.mp3'],
      volume: 1,
      autoplay: false,
      loop: false,
    }),
  };

  private eventos$?: Subscription;
  public eventosChange = new EventEmitter<IEvento[]>();

  constructor(
    private listadosService: ListadosService,
    private idleService: IdleService,
    private dialogService: DialogService,
  ) {}

  private async listar(): Promise<void> {
    this.eventos$?.unsubscribe();
    this.eventos$ = this.listadosService
      .subscribe<IEvento[]>('eventosEnVivo', { includeChildren: true })
      .subscribe((data) => {
        this.totalCount = data.length;
        this.eventosAnteriores = JSON.parse(JSON.stringify(this.eventos));
        this.eventos = JSON.parse(JSON.stringify(data));
        console.log(`eventos`, this.eventos);
        this.reproducirSonido();
      });
    await this.listadosService.getLastValue('eventosEnVivo', {
      includeChildren: true,
    });
  }

  private hayNuevosEventos() {
    // Se fija si hay algun id en los eventos nuevos que no esté en los anteriores y devuelve el evento nuevo
    const eventoNuevo: IEvento = this.eventos.find(
      (e) => !this.eventosAnteriores.map((ea) => ea._id).includes(e._id),
    );
    return eventoNuevo;
  }

  private reproducirSonido() {
    // Si hay un evento nuevo, reproduce el sonido correspondiente
    const eventoNuevo = this.hayNuevosEventos();
    if (eventoNuevo) {
      const sonido = eventoNuevo.valores?.sonido;
      switch (sonido) {
        case 'Silencio': {
          break;
        }
        case 'Sirena':
        case 'Campana': {
          this.sonidos[sonido].play();
          break;
        }
        default: {
          this.sonidos.Campana.play();
          break;
        }
      }
    }
  }

  private InitBackSound() {
    return this.sonidos.Fondo.play();
  }

  private stopBackSound() {
    return this.sonidos.Fondo.stop();
  }

  public async iniciarServicio() {
    // await this.dialogService.dialog(
    //   'Sonido en segundo plano',
    //   'La aplicación reproducirá el sonido de eventos en segundo plano, independientemente de la pantalla en la que se encuentre.',
    //   'Aceptar',
    // );
    this.idleService.idle$.subscribe(() => this.InitBackSound());
    this.idleService.wake$.subscribe(() => this.stopBackSound());
    await this.listar();
  }
}
