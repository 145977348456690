@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ apikey?.identificacion }}</span>
      } @else {
        <span class="titulo">Crear Apikey</span>
      }
    </div>
    <br />
    <section class="layout">
      <div class="grow1">
        <!-- Identificación -->
        <mat-form-field style="width: 100%" class="no-hint">
          <mat-label>Identificación</mat-label>
          <input matInput formControlName="identificacion" />
        </mat-form-field>
      </div>
      <div>
        <!-- Global  -->
        <mat-checkbox formControlName="global">¿Global?</mat-checkbox>
      </div>
    </section>
    <br />

    <!-- Clientes  -->
    @if (clientes && this.form.get("global")?.value === false) {
      <ng-select
        [items]="clientes"
        bindLabel="nombre"
        bindValue="_id"
        formControlName="idClientes"
        [multiple]="true"
        placeholder="Cliente"
        style="width: 100%"
      >
      </ng-select>
      <mat-error *ngIf="this.form.hasError('forbiddenIdClientes')">
        Si la Apikey no es global debe tener al menos un cliente.
      </mat-error>
    }
    <!-- Botones -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>

      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
