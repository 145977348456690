import { Injectable } from '@angular/core';
import { ICoordenadas } from 'modelos/src';
import { PAIS } from '../../../../environments/environment';
import { HttpClientService } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class DireccionesService {
  constructor(private http: HttpClientService) {}

  public autocomplete(
    text: string,
    coordenadas?: ICoordenadas,
  ): Promise<{ resultados: string[] }> {
    return this.http.post(`/geocode/direcciones`, { text, PAIS, coordenadas });
  }

  public geocode(text: string): Promise<ICoordenadas> {
    return this.http.post(`/geocode/geocode`, { text });
  }
}
