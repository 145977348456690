<section class="layout">
  <div class="column-center">
    <div class="avatar">
      {{ iniciales }}
    </div>
  </div>
  <div class="grow1 column-center text-on-primary">
    <span class="nombre">{{ usuario?.usuario | titlecase }}</span>
    @for (r of usuario?.roles; track $index) {
      <span class="roles">{{ r }}</span>
    }
  </div>
</section>
