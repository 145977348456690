<app-button
  matType="mat-fab extended"
  color="accent"
  [disabled]="disabled"
  (click)="fileInput.click()"
  icon="upload"
  [text]="textoBoton"
></app-button>

<input
  #fileInput
  matInput
  type="file"
  style="position: fixed; top: -20000px"
  accept=".jpg, .jpeg, .png"
  (change)="setFile($event)"
/>
