import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../auxiliares/servicios/http.service';
import {
  IQueryParam,
  IListado,
  INotificacion,
  ICreateNotificacion,
} from 'modelos/src';
import { HelperService } from '../../../auxiliares/servicios/helper.service';

@Injectable({ providedIn: 'root' })
export class NotificacionesService {
  constructor(private http: HttpClientService) {}

  public listar(queryParams?: IQueryParam): Promise<IListado<INotificacion>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/notificaciones`, { params });
  }

  public cantidadSinLeer(): Promise<{ totalCount: number }> {
    return this.http.get(`/notificaciones/countDocuments`);
  }

  public marcarComoLeida(id: string): Promise<INotificacion> {
    return this.http.put(`/notificaciones/${id}/leida`, {});
  }

  public marcarComoNoLeida(id: string): Promise<INotificacion> {
    return this.http.put(`/notificaciones/${id}/noLeida`, {});
  }

  public eliminar(id: string): Promise<void> {
    return this.http.delete(`/notificaciones/${id}`);
  }

  public eliminarTodos(queryParams?: IQueryParam): Promise<void> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.delete(`/notificaciones`, { params });
  }

  public marcarTodasComoLeidas(): Promise<void> {
    return this.http.put(`/notificaciones/marcarLeidas`, {});
  }

  public create(dato: ICreateNotificacion): Promise<INotificacion> {
    return this.http.post(`/notificaciones`, dato);
  }
}
