import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ITipoEvento,
  IFilter,
  IListado,
  IQueryParam,
  ICliente,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CrearEditarTipoEventoComponent } from '../crear-editar-tipo-evento/crear-editar-tipo-evento.component';
import { TiposEventosService } from '../../../../../auxiliares/servicios/http/tipos-eventos.service';

@Component({
    selector: 'app-listado-tipos-eventos',
    templateUrl: './listado-tipos-eventos.component.html',
    styleUrl: './listado-tipos-eventos.component.scss',
    standalone: false
})
export class ListadoTiposEventosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoTiposEventosComponent.name;
  public tiposEventos: ITipoEvento[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ITipoEvento>[];
  //Subs
  public tiposEventos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Tipo de Evento',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crearTipoEvento(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public categoria: IFiltroTabla = {
    elementos: ['Alarma', 'Tracker'],
    filter: {
      field: 'categoria',
    },
    label: 'Categoría',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [this.categoria];
  //Query

  public filter: IFilter<ITipoEvento> = {};
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: TiposEventosService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<ITipoEvento> = JSON.parse(this.query.filter);
      this.query.filter = JSON.stringify(filter);
    }
    this.tiposEventos$?.unsubscribe();
    this.tiposEventos$ = this.listados
      .subscribe<IListado<ITipoEvento>>('tiposEventos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.tiposEventos = data.datos;
        console.log(`listado de tiposEventos`, data);
      });
    await this.listados.getLastValue('tiposEventos', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  // Acciones
  public crearTipoEvento() {
    const config: MatDialogConfig = {
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarTipoEventoComponent, config);
  }

  public async editar(tipoEvento: ITipoEvento) {
    const config: MatDialogConfig = {
      data: tipoEvento,
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarTipoEventoComponent, config);
  }

  public async eliminar(tipoevento: ITipoEvento) {
    const res = await this.dialogService.confirm(
      'Eliminar TipoEvento',
      `¿Desea eliminar el tipoevento ${tipoevento.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(tipoevento._id!);
      this.helper.notifSuccess('TipoEvento eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Categoria
      {
        header: {
          label: 'Categoría',
          sortable: true,
        },
        row: {
          field: 'categoria',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  async ngOnDestroy() {
    this.tiposEventos$?.unsubscribe();
  }
}
