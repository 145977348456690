import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  ICliente,
  ICreateCliente,
  IUpdateCliente,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<ICliente>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/clientes`, { params });
  }

  public getHijosById(id: string): Promise<IListado<ICliente>> {
    return this.http.get(`/clientes/hijos/${id}`);
  }

  public getById(id: string): Promise<ICliente> {
    return this.http.get(`/clientes/${id}`);
  }

  public getPropio(): Promise<ICliente> {
    return this.http.get(`/clientes/propio`);
  }

  public create(dato: ICreateCliente): Promise<ICliente> {
    return this.http.post(`/clientes`, dato);
  }

  public update(id: string, dato: IUpdateCliente): Promise<ICliente> {
    return this.http.put(`/clientes/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/clientes/${id}`);
  }
}
