import { Injectable } from '@angular/core';
import {
  ICategoriaEvento,
  ICreateCodigosDispositivo,
  IListado,
  IQueryParam,
  IUpdateCodigosDispositivo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriaEventosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ICategoriaEvento>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/categoriaeventos`, { params });
  }

  public getById(id: string): Promise<ICategoriaEvento> {
    return this.http.get(`/categoriaeventos/${id}`);
  }

  public create(dato: ICreateCodigosDispositivo): Promise<ICategoriaEvento> {
    return this.http.post(`/categoriaeventos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateCodigosDispositivo,
  ): Promise<ICategoriaEvento> {
    return this.http.put(`/categoriaeventos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/categoriaeventos/${id}`);
  }
}
