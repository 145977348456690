<mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
  <!-- Header -->
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
  </ng-container>
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content
        style="
          max-height: 60vh;
          min-width: 40vh;
          max-width: 60vh;
          overflow: auto;
        "
      >
        <!-- Nombre y identificacion  -->
        <section
          class="column-center"
          style="margin-top: 2%; width: 100%; gap: 16px"
        >
          <!-- Nombre -->
          <mat-form-field style="width: 100%" class="no-hint">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" required />
          </mat-form-field>
          <!-- Identificación -->
          <mat-form-field style="width: 100%" class="no-hint">
            <mat-label>Identificación</mat-label>
            <input matInput formControlName="identificacion" />
          </mat-form-field>
        </section>
        <!-- Clientes  -->
        @if (clientes && !paramsService.getParams()["idCliente"]) {
          <section
            class="column-center"
            style="margin-top: 16px; width: 100%; gap: 16px"
          >
            <ng-select
              [items]="clientes"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idCliente"
              [multiple]="false"
              placeholder="Cliente"
              style="width: 100%; margin-bottom: 16px"
              appendTo="body"
            >
            </ng-select>
          </section>
        }
        <!-- Tipo de Tracker -->
        <section class="column-center" style="width: 100%">
          <ng-select
            [items]="tipos"
            formControlName="tipo"
            [multiple]="false"
            placeholder="Tipo"
            style="width: 100%"
            appendTo="body"
          >
          </ng-select>
          <ng-select
            [items]="modelos"
            bindLabel="modelo"
            bindValue="_id"
            formControlName="idModelo"
            [multiple]="false"
            placeholder="Modelo de tracker"
            style="width: 100%; margin-bottom: 16px"
            appendTo="body"
          >
          </ng-select>
        </section>

        @if (this.form.value.tipo === "Traccar") {
          <div class="row-center" style="gap: 10px">
            <!-- Tiene Imei -->
            <mat-checkbox (change)="tieneImei = $event.checked">
              Tiene IMEI
            </mat-checkbox>
            @if (!tieneImei) {
              <!-- Unique ID -->
              <mat-form-field style="width: 100%" class="no-hint">
                <mat-label>ID. Único</mat-label>
                <input matInput formControlName="uniqueId" required />
              </mat-form-field>
            } @else {
              <!-- Imei -->
              <mat-form-field style="width: 100%" class="no-hint">
                <mat-label>IMEI</mat-label>
                <input
                  matInput
                  #input
                  maxlength="15"
                  minlength="14"
                  formControlName="uniqueId"
                  required
                />
              </mat-form-field>
            }
          </div>
          <div class="row-center" style="gap: 10px">
            <!-- Sims -->
            <section class="row-center" style="gap: 16px">
              <!-- Sim 1 -->
              <div [formGroup]="sim1">
                <h3>SIM 1</h3>

                <mat-form-field>
                  <mat-label>ICCID</mat-label>
                  <input matInput formControlName="iccid" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Número de teléfono</mat-label>
                  <input matInput formControlName="numero" type="tel" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Operador</mat-label>
                  <mat-select formControlName="operador">
                    @for (s of operadores; track s) {
                      <mat-option [value]="s">{{ s }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>APN</mat-label>
                  <input
                    type="text"
                    placeholder="datos.personal.com"
                    matInput
                    formControlName="apn"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    @for (option of filteredOptionsSim1 | async; track option) {
                      <mat-option [value]="option">{{ option }}</mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Usuario</mat-label>
                  <input matInput formControlName="usuario" type="text" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="text" />
                </mat-form-field>
              </div>

              <!-- Sim 2 -->
              <div [formGroup]="sim2">
                <h3>SIM 2</h3>

                <mat-form-field>
                  <mat-label>ICCID</mat-label>
                  <input matInput formControlName="iccid" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Número de teléfono</mat-label>
                  <input matInput formControlName="numero" type="tel" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Operador</mat-label>
                  <mat-select formControlName="operador">
                    @for (s of operadores; track s) {
                      <mat-option [value]="s">{{ s }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>APN</mat-label>
                  <input
                    type="text"
                    placeholder="datos.personal.com"
                    matInput
                    formControlName="apn"
                    [matAutocomplete]="auto1"
                  />
                  <mat-autocomplete #auto1="matAutocomplete">
                    @for (option of filteredOptionsSim2 | async; track option) {
                      <mat-option [value]="option">{{ option }}</mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Usuario</mat-label>
                  <input matInput formControlName="usuario" type="text" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="text" />
                </mat-form-field>
              </div>
            </section>
          </div>
        }
        @if (this.form.value.tipo === "Qualcomm") {
          <div class="row-center">
            <!-- Unique ID -->
            <mat-form-field
              style="width: 100%"
              class="no-hint"
              [formGroup]="qualcomm"
            >
              <mat-label>Numero Serial</mat-label>
              <input matInput formControlName="serialNumber" />
            </mat-form-field>
          </div>
        }
      </mat-card-content>
      <!-- Botones -->
      <mat-card-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
