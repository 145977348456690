/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
// Import the functions you need from the SDKs you need
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { Router } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG, VAPID_KEY } from '../../../environments/environment';
import { ITokenPush } from 'modelos/src';
import { LoginService } from '../../modulos/login/login.service';
import { HttpClientService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private http: HttpClientService,
    private router: Router,
  ) {}

  //

  public async estadoPermisos() {
    let response: 'denied' | 'granted' | 'prompt' = 'prompt';
    if (Capacitor.getPlatform() === 'web') {
      // const state: NotificationPermission = Notification.permission;
      const { state } = await navigator?.permissions?.query({
        name: 'notifications' as any,
      });

      if (state === 'granted') {
        response = 'granted';
        await this.iniciarPermisos();
      }
      if (state === 'denied') {
        response = 'denied';
      }
    }

    if (
      Capacitor.getPlatform() === 'android' ||
      Capacitor.getPlatform() === 'ios'
    ) {
      const status = await PushNotifications.checkPermissions();
      const state = status.receive;
      if (state === 'granted') {
        response = 'granted';
        await this.iniciarPermisos();
      }
      if (state === 'denied') {
        response = 'denied';
      }
    }
    return response;
  }

  public async iniciarPermisos() {
    initializeApp(FIREBASE_CONFIG);

    if (Capacitor.getPlatform() !== 'web') {
      await this.initNativo();
    } else {
      await this.initWeb();
    }
  }

  public async requestPermission() {
    if (Capacitor.getPlatform() === 'web') {
      const res = await Notification.requestPermission();
      return res;
    } else {
      const status = await PushNotifications.requestPermissions();
      if (status.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }
      return status.receive;
    }
  }

  //

  private async addListeners() {
    // On success, we should be able to receive notifications
    await PushNotifications.addListener('registration', async (token) => {
      const usuario = LoginService.getUsuario();
      await this.upsertToken(token.value, usuario._id);
      console.info('Registration token: ', token.value);
    });

    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log(
          'Push notification received: ',
          JSON.stringify(notification),
        );
      },
    );

    // Method called when tapping on a notification
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        if (
          notification.notification?.data?.action === 'abrir notificaciones'
        ) {
          this.router.navigate(['/main/notificaciones']);
        }
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue,
        );
      },
    );
  }

  private async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  private async getDeliveredNotifications() {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  private async initNativo() {
    await this.addListeners();
    await this.registerNotifications();
    await this.getDeliveredNotifications();
  }
  /// WEB

  public async upsertToken(
    token: string,
    idUsuario: string,
  ): Promise<ITokenPush> {
    const url = `/tokenPushs/upsert`;
    const body = { tokenPush: token, idUsuario };
    return await this.http.post<ITokenPush>(url, body);
  }

  private async initWeb() {
    // NO ANDA EN FF
    if (await isSupported()) {
      await Notification.requestPermission();
      const messaging = getMessaging();
      const token = await this.getToken(messaging);
      if (token) {
        const usuario = LoginService.getUsuario();
        await this.upsertToken(token, usuario._id);
        console.info('Registration token: ', token);
        try {
          await this.listen(messaging);
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error(
          'No registration token available. Request permission to generate one.',
        );
      }
    } else {
      console.error('Notifications not supported');
    }
  }

  private async getToken(messaging: Messaging) {
    return getToken(messaging, { vapidKey: VAPID_KEY });
  }

  private async listen(messaging: Messaging) {
    onMessage(messaging, (payload) => {
      // Acá se pueden hacer cosas con el mensaje
      console.log('Message received. ', payload);
    });
  }
}
