import { Injectable } from '@angular/core';
import {
  ICreateRecordatorio,
  IListado,
  IQueryParam,
  IUpdateRecordatorio,
  IRecordatorio,
} from 'modelos/src';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class RecordatoriosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IRecordatorio>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/recordatorios`, { params });
  }

  public getById(id: string): Promise<IRecordatorio> {
    return this.http.get(`/recordatorios/${id}`);
  }

  public create(dato: ICreateRecordatorio): Promise<IRecordatorio> {
    return this.http.post(`/recordatorios`, dato);
  }

  public update(id: string, dato: IUpdateRecordatorio): Promise<IRecordatorio> {
    return this.http.put(`/recordatorios/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/recordatorios/${id}`);
  }
}
