import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DespachosComponent } from './despachos/despachos.component';
import { DespachosRoutingModule } from './despachos.routing.module';
import { ListadoComponent } from './listado/listado.component';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { EditarDespachoComponent } from './editar-despacho/editar-despacho.component';

@NgModule({
  declarations: [DespachosComponent, ListadoComponent, EditarDespachoComponent],
  imports: [CommonModule, AuxiliaresModule, DespachosRoutingModule],
  exports: [DespachosComponent, ListadoComponent],
})
export class DespachosModule {}
