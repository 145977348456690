@if (!loading.loading) {
  <!-- Clientes que pueden atender -->
  <ng-select
    [items]="clientesQuePuedenAtender"
    [(ngModel)]="clientesIn"
    bindLabel="nombre"
    bindValue="_id"
    placeholder="Clientes que atienden los eventos"
    [notFoundText]="'no se encontraron clientes'"
    style="width: 100%"
    multiple="true"
    searchable="true"
    (change)="onChange($event)"
    appendTo="body"
  >
  </ng-select>
}
