@if (!this.loading) {
  <!-- Título -->
  <div class="row-center">
    <span class="title1">{{ cliente?.nombre }}</span>
  </div>
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Info">
      <ng-template matTabContent>
        <app-detalles-cliente-info
          [cliente]="cliente"
        ></app-detalles-cliente-info>
      </ng-template>
    </mat-tab>
    <mat-tab label="Eventos">
      <ng-template matTabContent>
        <app-listado-eventos-historico
          [id]="idCliente"
          tipo="Cliente"
        ></app-listado-eventos-historico
      ></ng-template>
    </mat-tab>
    <mat-tab label="Notas">
      <ng-template matTabContent>
        <app-listado-notas
          [idAsignado]="this.idCliente"
          tipo="Nota"
          tipoAsignado="Cliente"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
    <mat-tab label="Contacto">
      <ng-template matTabContent>
        <app-listado-notas
          [idAsignado]="this.idCliente"
          tipo="Contacto"
          tipoAsignado="Cliente"
        ></app-listado-notas>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
}
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.clientes()"
    text="Volver"
    icon="arrow_back"
    z
  ></app-button>
</div>
