@if (matType === "mat-fab extended") {
  <button
    mat-fab
    extended
    [matMenuTriggerFor]="matMenuTriggerFor"
    [color]="color"
    [style]="style"
    [matTooltip]="matTooltip"
    [disabled]="disabled"
    [type]="type"
  >
    <div class="row-center gap">
      @if (icon) {
        <mat-icon
          [ngClass]="ngClass"
          [class.text-on-primary]="color === 'primary'"
          [class.text-on-accent]="color === 'accent'"
          [class.text-on-warn]="color === 'warn'"
        >
          {{ icon }}
        </mat-icon>
      }
      <span
        [ngClass]="ngClass"
        [class.text-on-primary]="color === 'primary'"
        [class.text-on-accent]="color === 'accent'"
        [class.text-on-warn]="color === 'warn'"
      >
        {{ text }}
      </span>
    </div>
  </button>
}

@if (matType === "mat-fab") {
  <button
    mat-fab
    [matMenuTriggerFor]="matMenuTriggerFor"
    [color]="color"
    [style]="style"
    [matTooltip]="matTooltip"
    [disabled]="disabled"
    [type]="type"
  >
    <mat-icon
      [ngClass]="ngClass"
      [class.text-on-primary]="color === 'primary'"
      [class.text-on-accent]="color === 'accent'"
      [class.text-on-warn]="color === 'warn'"
    >
      {{ icon }}
    </mat-icon>
  </button>
}

@if (matType === "mat-mini-fab") {
  <button
    mat-mini-fab
    [matMenuTriggerFor]="matMenuTriggerFor"
    [color]="color"
    [style]="style"
    [matTooltip]="matTooltip"
    [disabled]="disabled"
    [type]="type"
  >
    <mat-icon
      [ngClass]="ngClass"
      [class.text-on-primary]="color === 'primary'"
      [class.text-on-accent]="color === 'accent'"
      [class.text-on-warn]="color === 'warn'"
    >
      {{ icon }}
    </mat-icon>
  </button>
}
