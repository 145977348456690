import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICliente, IServicioContratado } from 'modelos/src';
import { ListadosService } from '../servicios/listados.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mostrar-servicios',
    imports: [CommonModule, MatTooltipModule],
    templateUrl: './mostrar-servicios.component.html',
    styleUrl: './mostrar-servicios.component.scss'
})
export class MostrarServiciosComponent implements OnInit, OnDestroy {
  @Input() cliente?: ICliente;
  @Input() idCliente?: string;
  @Input() orientacion: 'vertical' | 'horizontal' = 'horizontal';
  /**
   * True = Muestra todo en una sola línea
   * False = Grid copado
   */
  @Input() linea = false;
  /**
   * Tamaño de la imagen en px
   * @default 75
   */
  /**
   * Rows, solo funciona con linea en false
   */
  @Input() gridRows = 3;
  /**
   * Columns, solo funciona con linea en false
   */
  @Input() gridCols = 3;
  @Input() size = 75;
  @Input() gap = 8;

  private cliente$?: Subscription;

  public servicios: IServicioContratado[] = [];

  constructor(private listados: ListadosService) {}

  public getStyleLinea() {
    return {
      width: '100%',
      display: 'grid',
      'grid-template-rows': `${this.size}px`,
      'grid-template-columns': `repeat(auto-fit, ${this.size}px)`,
      'grid-auto-flow': `${this.orientacion === 'horizontal' ? 'column' : 'row'}`,
      gap: `${this.gap}px`,
    };
  }

  public getStyleGrid() {
    return {
      width: '100%',
      display: 'grid',
      'grid-template-rows': `repeat(${this.gridRows}, ${this.size}px)`,
      'grid-template-columns': `repeat(${this.gridCols}, ${this.size}px)`,
      'grid-auto-flow': `${this.orientacion === 'horizontal' ? 'column' : 'row'}`,
      gap: `${this.gap}px`,
    };
  }

  public async listar(id: string): Promise<void> {
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<ICliente>('cliente', id)
      .subscribe((data) => {
        this.cliente = data;
        console.log(`listado de cliente`, data);
      });
    await this.listados.getLastValue('cliente', id);
  }

  async ngOnInit(): Promise<void> {
    if (this.cliente) {
      this.servicios = this.cliente.serviciosContratados;
    }
    if (this.idCliente) {
      //// Hay que listar y poner en service los cosos
      await this.listar(this.idCliente);
      this.servicios = this.cliente?.serviciosContratados || [];
    }
  }

  ngOnDestroy(): void {
    this.cliente$?.unsubscribe();
  }
}
