@if (!loading) {
  <div class="column-center mat-elevation-z1" style="gap: 15px; padding: 0.5em">
    <section>
      <div class="row-center" style="gap: 100px; padding: 0.5em">
        <div class="column-flex-start">
          <!-- Evento -->
          <h3 class="row-flex-start" style="font-size: 2em">Evento</h3>
          <span class="row-flex-start">{{
            this.tratamientoEvento.eventos[0].valores.titulo
          }}</span>
        </div>

        <div class="column-flex-start">
          <!-- Detalles -->
          <h3 class="row-flex-start" style="font-size: 2em">Detalles</h3>
          <span class="row-flex-start">{{
            this.tratamientoEvento.eventos[0].valores.mensaje ||
              this.tratamientoEvento.eventos[0].valores["detalles"]
          }}</span>
        </div>
      </div>
      <div class="row-flex-start" style="gap: 100px; padding: 0.5em">
        <div class="column-flex-start">
          <!--Dispositivo-->
          <h3 class="row-flex-start" style="font-size: 2em">Dispositivo</h3>
          <span class="row-flex-start">
            {{
              this.tratamientoEvento.eventos[0].tracker?.identificacion ||
                this.tratamientoEvento.eventos[0].alarma?.nombre +
                  " - " +
                  this.tratamientoEvento.eventos[0].alarma?.domicilio?.direccion
            }}</span
          >
        </div>

        <div class="column-flex-start">
          <!--Cliente-->
          <h3 class="row-flex-start" style="font-size: 2em">Cliente</h3>
          <span class="row-flex-start">
            {{ this.tratamientoEvento.eventos[0].cliente.nombre }}</span
          >
        </div>

        <div class="column-flex-start">
          <!--Notificar-->
          <h3 class="row-flex-start" style="font-size: 2em">Notificar</h3>
          <span class="row-flex-start">
            {{ this.tratamientoEvento.eventos[0].notificar ? "Si" : "No" }}
          </span>
        </div>
      </div>
    </section>

    <br />
    @if (tratamientoEvento.eventos[0].alarma) {
      <!-- Estados -->
      <h3 class="row-flex-start" style="font-size: 2em">Estados</h3>
      <section style="width: 100%; max-height: 200px; overflow: auto">
        <mat-table [dataSource]="estados">
          <!-- Usuario -->
          <ng-container matColumnDef="usuarios">
            <mat-cell *matCellDef="let element">
              {{ element.usuario }}
            </mat-cell>
          </ng-container>
          <!-- Estado -->
          <ng-container matColumnDef="estados">
            <mat-cell *matCellDef="let element">
              {{ element.estado }}
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: columnasEstados"></mat-row>
        </mat-table>
      </section>

      <!-- Notas -->
      <h3 class="row-flex-start" style="font-size: 2em">Notas</h3>
      <section class="layout">
        <!-- Notas -->
        <div class="column" style="width: 50%">
          <mat-label>Notas</mat-label>
          <div
            class="grow1"
            style="min-width: 50%; overflow: auto; max-height: 200px"
          >
            <mat-table [dataSource]="notas">
              <!-- Usuario -->
              <ng-container matColumnDef="usuarios">
                <mat-cell *matCellDef="let element">
                  {{ element.usuario }}
                </mat-cell>
              </ng-container>
              <!-- Estado -->
              <ng-container matColumnDef="notas">
                <mat-cell *matCellDef="let element">
                  {{ element.nota }}
                </mat-cell>
              </ng-container>
              <mat-row *matRowDef="let row; columns: columnasNotas"></mat-row>
            </mat-table>
          </div>
        </div>
        <!-- NotasInternas -->
        <div class="column" style="width: 50%">
          <mat-label>Notas Internas</mat-label>
          <div
            class="grow1"
            style="min-width: 50%; overflow: auto; max-height: 200px"
          >
            <mat-table [dataSource]="notasInternas" class="mat-elevation-z8">
              <!-- Usuario -->
              <ng-container matColumnDef="usuarios">
                <mat-cell *matCellDef="let element">
                  {{ element.usuario }}
                </mat-cell>
              </ng-container>
              <!-- Estado -->
              <ng-container matColumnDef="notasInternas">
                <mat-cell *matCellDef="let element">
                  {{ element.nota }}
                </mat-cell>
              </ng-container>
              <mat-row
                *matRowDef="let row; columns: columnasNotasInternas"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </section>
    }
    <section style="width: 100%; height: 600px; margin-bottom: 150px">
      <h3 align="center" style="font-size: 2em">Ubicacion</h3>
      <app-mapa-facil [data]="ubicacion"></app-mapa-facil>
    </section>
  </div>
  <!-- Botones  -->
  <div class="row-flex-end" style="gap: 10px">
    <app-button
      matType="mat-fab extended"
      color="warn"
      (click)="this.helper.volver()"
      text="Volver"
      icon="arrow_back"
    ></app-button>
  </div>
}
