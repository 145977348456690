import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ITracker,
  IFilter,
  IQueryParam,
  IListado,
  IPopulate,
  IReporte,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { TrackersService } from '../trackers.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { CrearEditarTrackerComponent } from '../crear-editar-tracker/crear-editar-tracker.component';

interface ITrackerTabla extends ITracker {
  ultimoReporte?: IReporte;
  color?: string;
}

@Component({
    selector: 'app-trackers',
    templateUrl: './trackers.component.html',
    styleUrl: './trackers.component.scss',
    standalone: false
})
export class ListadoTrackersComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoTrackersComponent.name;

  public datos: ITrackerTabla[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ITrackerTabla>[];
  public botonCrear: ICrearBoton = {
    mostrar:
      (HelperService.getTipoCliente() !== 'Final' ||
        this.helper.puedeEditar()) &&
      (HelperService.getConfigCliente().moduloActivos?.crearDispositivos ||
        HelperService.getConfigCliente().moduloColectivos?.crearDispositivos),
    tooltip: 'Crear Tracker',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };

  // Suscripciones
  public trackers$?: Subscription;
  public clientes$?: Subscription;
  public reportes$?: Subscription;

  public search: IRegExpSearch = {
    fields: ['nombre', 'identificacion'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public tipo: IFiltroTabla = {
    elementos: ['Traccar', 'Qualcomm'],
    filter: {
      field: 'tipo',
    },
    label: 'Tipo',
    tipo: 'select',
  };
  public asignado: IFiltroTabla = {
    elementos: [
      { label: 'Si', value: [{ asignadoA: { $ne: null } }] },
      { label: 'No', value: [{ asignadoA: { $eq: null } }] },
    ],
    filter: {
      field: '$and',
    },
    label: 'Asignado',
    tipo: 'select',
    selectLabel: 'label',
    selectValue: 'value',
  };

  public filtros: IFiltroTabla[] = [this.tipo, this.asignado];

  public filter: IFilter<ITracker> = {};
  public populate: IPopulate = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    { path: 'modelo' },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: TrackersService,
    private navigator: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public paramsService: ParamsService,
  ) {}

  // Listados para filtros
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: true,
    };

    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ITracker>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Listados

  public async listar(): Promise<void> {
    this.trackers$?.unsubscribe();
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        await this.completaUltimoReporte();
      });
    await this.listados.getLastValue('trackers', this.query);
  }
  public async completaUltimoReporte(): Promise<void> {
    const idsTrackers = this.datos.map((v) => v._id);
    const query = { idsTrackers, agruparPor: 'tracker', includeChildren: true };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IReporte[]>('reportesLastFiltered', query)
      .subscribe((data) => {
        console.log(`Listado de reportes`, data);

        for (const tracker of this.datos) {
          tracker.ultimoReporte = data.find(
            (r) => r.tracker?._id === tracker._id,
          );
          if (tracker.ultimoReporte) {
            const timeReporte = new Date(
              tracker.ultimoReporte.fechaCreacion,
            ).getTime();
            const timeNow = new Date().getTime();
            const diff = timeNow - timeReporte;
            // 1 hora
            if (diff > 1000 * 60 * 60) {
              tracker.color = 'red';
            } else if (diff > 1000 * 60 * 30) {
              tracker.color = 'yellow';
            } else {
              tracker.color = 'green';
            }
          }
        }
        console.log(`listado de trackers`, this.datos);
      });
    await this.listados.getLastValue('reportesLastFiltered', query);
  }

  // Acciones
  public async crear() {
    this.paramsService.setParams({});
    this.dialog.open(CrearEditarTrackerComponent);
    // this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(tracker: ITracker) {
    this.paramsService.setParams({ tracker: tracker });
    this.dialog.open(CrearEditarTrackerComponent);
    // this.navigator.navigate(['editar', tracker._id], {
    //   relativeTo: this.route,
    // });
  }

  public detalles(data: ITracker) {
    this.navigator.navigate(['detallesTracker', data._id], {
      relativeTo: this.route,
    });
  }

  private async crearEnTraccar(tracker: ITracker) {
    const res = await this.dialogService.confirm(
      'Crear en Traccar',
      `¿Desea crear el tracker ${tracker.nombre} en traccar?`,
    );
    if (!res) return;
    try {
      await this.service.createTraccar(tracker._id!);
      this.helper.notifSuccess('Tracker Creado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async eliminar(tracker: ITracker) {
    const res = await this.dialogService.confirm(
      'Eliminar Tracker',
      `¿Desea eliminar el tracker ${tracker.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(tracker._id!);
      this.helper.notifSuccess('Tracker Eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<ITrackerTabla>[] = [
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Identificación
      {
        header: {
          label: 'Identificación',
          sortable: true,
        },
        row: {
          field: 'identificacion',
          noData: 'Sin Identificación',
        },
      },
      // Asignado
      {
        header: {
          label: '¿Asignado?',
        },
        row: {
          parse(dato) {
            return dato.asignadoA ? '✔️' : '❌';
          },
        },
      },
      // Tipo
      {
        header: {
          label: 'Tipo',
        },
        row: {
          field: 'tipo',
          noData: 'Sin Tipo',
        },
      },
      // Marca y modelo
      {
        header: {
          label: 'Modelo',
          sortable: true,
        },
        row: {
          parse(dato) {
            let text = '';
            if (dato.modelo)
              text = `${dato.modelo.marca} - ${dato.modelo.modelo}`;
            return text;
          },
        },
      },
      // Ultima Comunicacion
      {
        header: {
          label: 'Última Comunicación',
        },
        row: {
          html: (dato) => {
            if (dato.ultimoReporte) {
              const time = new Date(dato.ultimoReporte?.fechaCreacion);
              const fecha = HelperService.parseDate(time);

              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.color) {
                html += `<span style="color: ${dato.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${fecha.toLocaleString()} </span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Comunicación',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'primary',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            oculta() {
              return HelperService.getTipoCliente() === 'Final';
            },
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'local_shipping',
            tooltip: 'Crear en Traccar',
            click: (dato) => this.crearEnTraccar(dato),
            oculta() {
              return HelperService.getTipoCliente() === 'Final';
            },
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            oculta() {
              return HelperService.getTipoCliente() === 'Final';
            },
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await Promise.all([this.listar(), this.listarClientes()]);
  }

  ngOnDestroy() {
    this.trackers$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.reportes$?.unsubscribe();
  }
}
