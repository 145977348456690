<mat-drawer-container class="navigator-container" autosize>
  <!-- Menu de la izquierda -->
  <mat-drawer
    #drawer
    class="navigator-sidenav bg-85"
    mode="side"
    opened="true"
    [attr.role]="(helper.isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(helper.isHandset$ | async) ? 'over' : 'side'"
    [opened]="(helper.isHandset$ | async) === false"
    style="width: 300px"
  >
    <section class="layout2">
      <app-usuario></app-usuario>
      <div class="main2" style="overflow-y: auto">
        <mat-nav-list>
          <mat-divider class="navigator-divider"></mat-divider>
          <!-- Mapa General -->
          <a mat-list-item routerLinkActive="active" [routerLink]="['mapa']">
            <span class="nav-link">
              <mat-icon matSuffix color="accent">map</mat-icon>
              <span class="text-menu"> Mapa </span>
            </span>
          </a>

          <!-- Eventos -->
          <a mat-list-item routerLinkActive="active" [routerLink]="['eventos']">
            <span class="nav-link">
              <mat-icon matSuffix color="accent">info</mat-icon>
              <span class="text-menu"> Eventos </span>
              @if (listadoEventosVivoService.totalCount) {
                <span class="badge">
                  {{ listadoEventosVivoService.totalCount }}
                </span>
              }
            </span>
          </a>

          @if (helper.verModuloEventosTecnicos()) {
            <!-- Eventos Técnicos -->
            <a
              mat-list-item
              routerLinkActive="active"
              [routerLink]="['administracion/eventosTecnicos']"
            >
              <span class="nav-link">
                <mat-icon matSuffix color="accent">event</mat-icon>
                <span class="text-menu"> Servicios Técnicos </span>
              </span>
            </a>
          }
          <mat-divider class="navigator-divider"></mat-divider>
          <br />

          <!-- Modulos -->
          <mat-accordion displayMode="flat">
            <!-- Alarmas -->
            @if (helper.verModuloAlarmas()) {
              <mat-expansion-panel [expanded]="expandedPanel === 2">
                <mat-expansion-panel-header>
                  <mat-panel-title> Gestión de Alarmas </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Mapa -->
                <a
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="['alarmas/mapa']"
                >
                  <span class="nav-link">
                    <mat-icon matSuffix color="accent">map</mat-icon>
                    <span class="text-menu"> Mapa </span>
                  </span>
                </a>
                <!-- Alarma Domiciliaria-->
                <a
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="['alarmas/alarmasDomiciliarias']"
                >
                  <span class="nav-link">
                    <mat-icon matSuffix color="accent">doorbell</mat-icon>
                    <span class="text-menu"> Alarmas Domiciliarias </span>
                  </span>
                </a>
              </mat-expansion-panel>
            }

            <!-- Activos -->
            @if (helper.verModuloActivos()) {
              <mat-expansion-panel [expanded]="expandedPanel === 0">
                <mat-expansion-panel-header>
                  <mat-panel-title> Gestión de Activos </mat-panel-title>
                  <!-- <mat-panel-description> This is a summary of the content </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!-- Contenido de activos -->
                <div class="expansion-content">
                  <!-- Inicio - Mapa -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['activos/mapa']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">map</mat-icon>
                      <span class="text-menu"> Mapa </span>
                    </span>
                  </a>
                  <!-- Activos -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['activos/activos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        assistant_navigation
                      </mat-icon>
                      <span class="text-menu"> Activos </span>
                    </span>
                  </a>
                  <!-- Ubicaciones -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['activos/ubicaciones/Activos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        emoji_transportation
                      </mat-icon>
                      <span class="text-menu"> Ubicaciones </span>
                    </span>
                  </a>
                  <!-- Agrupaciones -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['activos/grupos/Flota']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">workspaces</mat-icon>
                      <span class="text-menu"> Agrupaciones </span>
                    </span>
                  </a>
                </div>
              </mat-expansion-panel>
            }

            <!-- Colectivos -->
            @if (helper.verModuloColectivos()) {
              <mat-expansion-panel [expanded]="expandedPanel === 1">
                <mat-expansion-panel-header>
                  <mat-panel-title> Transporte Público </mat-panel-title>
                  <!-- <mat-panel-description> This is a summary of the content </mat-panel-description> -->
                </mat-expansion-panel-header>
                <!-- Contenido de alarmas -->
                <div class="expansion-content">
                  <!-- Inicio - Mapa -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/mapaColectivos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">map</mat-icon>
                      <span class="text-menu"> Mapa </span>
                    </span>
                  </a>
                  <!-- Colectivos -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/colectivos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        directions_bus
                      </mat-icon>
                      <span class="text-menu"> Colectivos </span>
                    </span>
                  </a>
                  <!-- Despachos -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/despachos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        support_agent
                      </mat-icon>
                      <span class="text-menu"> Despachos </span>
                    </span>
                  </a>
                  <!-- Recorridos -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/recorridos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">route</mat-icon>
                      <span class="text-menu"> Recorridos </span>
                    </span>
                  </a>
                  <!-- Terminales -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/ubicaciones/Terminal']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        emoji_transportation
                      </mat-icon>
                      <span class="text-menu"> Terminales </span>
                    </span>
                  </a>
                  <!-- Lineas -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/grupos/Línea de colectivo']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">workspaces</mat-icon>
                      <span class="text-menu"> Líneas </span>
                    </span>
                  </a>
                  <!-- Choferes -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/choferes']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">account_box</mat-icon>
                      <span class="text-menu"> Choferes </span>
                    </span>
                  </a>
                  <!-- Cronogramas -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/cronogramas']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent"
                        >calendar_month</mat-icon
                      >
                      Cronogramas
                    </span>
                  </a>
                  <!-- Recordatorios -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/recordatorios']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">alarm</mat-icon>
                      <span class="text-menu"> Recordatorios </span>
                    </span>
                  </a>
                  <!-- Servicios -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/servicios']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent"
                        >home_repair_service</mat-icon
                      >
                      Servicios
                    </span>
                  </a>
                  <!-- Proveedores -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['colectivos/proveedores']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent"
                        >request_quote</mat-icon
                      >
                      Proveedores
                    </span>
                  </a>
                </div>
              </mat-expansion-panel>
            }

            <!-- Configuración -->
            <mat-expansion-panel [expanded]="expandedPanel === 3">
              <mat-expansion-panel-header>
                <mat-panel-title> Configuración </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- Contenido de administracion -->
              <div class="expansion-content">
                <span class="subtitle">Eventos</span>

                <!-- Config Eventos Usuario -->
                <a
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="['configuracion/config-evento-usuario']"
                  [queryParams]="{ crear: 'Page' }"
                >
                  <span class="nav-link">
                    <mat-icon matSuffix color="accent">notifications</mat-icon>
                    <span class="text-menu"> Eventos Personalizados </span>
                  </span>
                </a>

                <!-- Categorias Eventos-->
                <a
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="['configuracion/categoriasEventos']"
                >
                  <span class="nav-link">
                    <mat-icon matSuffix color="accent">edit_square</mat-icon>
                    <span class="text-menu"> Categorías Eventos </span>
                  </span>
                </a>
                <!-- Tipos Eventos-->
                <a
                  mat-list-item
                  routerLinkActive="active"
                  [routerLink]="['configuracion/tiposEventos']"
                >
                  <span class="nav-link">
                    <mat-icon matSuffix color="accent">edit_square</mat-icon>
                    <span class="text-menu"> Tipos Eventos </span>
                  </span>
                </a>
                @if (helper.verModuloAlarmas() && crearAlarmas) {
                  <span class="subtitle">Alarmas</span>
                  <!-- Codigos Alarmas -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['configuracion/codigosDispositivos/Alarma']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        integration_instructions
                      </mat-icon>
                      <span class="text-menu"> Códigos Alarmas </span>
                    </span>
                  </a>
                  <!-- Modelos Alarma -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['configuracion/modelosDispositivos/Alarma']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        edit_notifications
                      </mat-icon>
                      <span class="text-menu"> Modelos Alarmas </span>
                    </span>
                  </a>
                  <!-- Codigos Comunicadores -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="[
                      'configuracion/codigosDispositivos/Comunicador',
                    ]"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        integration_instructions
                      </mat-icon>
                      <span class="text-menu"> Códigos Comunicadores </span>
                    </span>
                  </a>
                  <!-- Modelos Comunicadores -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="[
                      'configuracion/modelosDispositivos/Comunicador',
                    ]"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent"> router </mat-icon>
                      <span class="text-menu"> Modelos Comunicadores </span>
                    </span>
                  </a>
                }
                @if (
                  (helper.verModuloActivos() && crearActivos) ||
                  (helper.verModuloColectivos() && crearColectivos)
                ) {
                  <span class="subtitle">Trackers</span>
                  <!-- Codigos Trackers -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['configuracion/codigosDispositivos/Tracker']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">
                        integration_instructions
                      </mat-icon>
                      <span class="text-menu"> Códigos Trackers </span>
                    </span>
                  </a>
                  <!-- Modelos Trackers -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['configuracion/modelosDispositivos/Tracker']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent"> router </mat-icon>
                      <span class="text-menu"> Modelos Trackers </span>
                    </span>
                  </a>
                }
              </div>
            </mat-expansion-panel>

            @if (helper.verModuloAdministracion()) {
              <!-- Administracion -->
              <mat-expansion-panel [expanded]="expandedPanel === 4">
                <mat-expansion-panel-header>
                  <mat-panel-title> Administración </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Contenido de administracion -->
                <div class="expansion-content">
                  <!-- Clientes -->
                  @if (verClientes) {
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="['administracion/clientes']"
                    >
                      <span class="nav-link">
                        <mat-icon matSuffix color="accent">business</mat-icon>
                        <span class="text-menu"> Clientes </span>
                      </span>
                    </a>
                  }
                  <!-- Usuarios -->
                  @if (verUsuarios && crearUsuarios) {
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="['administracion/usuarios']"
                    >
                      <span class="nav-link">
                        <mat-icon matSuffix color="accent">person</mat-icon>
                        <span class="text-menu"> Usuarios </span>
                      </span>
                    </a>
                  }
                  <!-- Técnicos  -->
                  <a
                    mat-list-item
                    routerLinkActive="active"
                    [routerLink]="['administracion/tecnicos']"
                  >
                    <span class="nav-link">
                      <mat-icon matSuffix color="accent">engineering</mat-icon>
                      <span class="text-menu"> Técnicos </span>
                    </span>
                  </a>

                  <!-- Trackers -->
                  @if (verColectivos || verActivos) {
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="['administracion/trackers']"
                    >
                      <span class="nav-link">
                        <mat-icon matSuffix color="accent">gps_fixed</mat-icon>
                        <span class="text-menu"> Trackers </span>
                      </span>
                    </a>
                  }
                  <!-- Servicios Contratados -->
                  @if (
                    !helper.esClienteFinal() && verServicios && crearServicios
                  ) {
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="['administracion/servicios']"
                    >
                      <span class="nav-link">
                        <mat-icon matSuffix color="accent">post_add</mat-icon>
                        <span class="text-menu"> Servicios Ofrecidos </span>
                      </span>
                    </a>
                  }
                  @if (verApikeys && crearApikeys) {
                    <!-- Apikeys -->
                    <a
                      mat-list-item
                      routerLinkActive="active"
                      [routerLink]="['administracion/apikeys']"
                    >
                      <span class="nav-link">
                        <mat-icon matSuffix color="accent">key</mat-icon>
                        <span class="text-menu"> Apikeys </span>
                      </span>
                    </a>
                  }
                </div>
              </mat-expansion-panel>
            }
          </mat-accordion>
        </mat-nav-list>
      </div>
      <div class="footer2">
        <mat-divider class="navigator-divider"></mat-divider>
        <br />
        <app-version></app-version>
      </div>
    </section>
  </mat-drawer>

  <!-- Arriba y contenido -->
  <div class="navigator-sidenav-content">
    <section class="layout">
      <!-- NAVBAR -->
      <div class="header">
        <mat-toolbar class="hideScroll">
          <section class="layout3">
            <!-- Togle menu -->
            <div>
              <button mat-icon-button (click)="drawer.toggle()" color="accent">
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <!-- Spinner -->
            <div class="grow3">
              <mat-progress-spinner
                diameter="25"
                mode="indeterminate"
                *ngIf="loading.getLoading()"
              ></mat-progress-spinner>
            </div>
            <!-- Botones de la derecha -->
            <div class="row-center">
              <!-- Banner -->
              @if (temas.banner) {
                <img src="{{ temas.banner }}" alt="banner" class="banner" />
              }
              <!-- Activar notificaciones -->
              @if (notificacionesActivas === false) {
                <app-button
                  matType="mat-fab extended"
                  color="warn"
                  (click)="activarNotificaciones()"
                  text="Activar Notificaciones"
                ></app-button>
              }
              <!-- Notificaciones -->
              <app-notificaciones></app-notificaciones>
              <!-- Cambio de tema -->
              <button mat-icon-button (click)="helper.toggleTheme()">
                @if (helper.darkTheme) {
                  <mat-icon>nightlight</mat-icon>
                } @else {
                  <mat-icon>brightness_7</mat-icon>
                }
              </button>
              <!-- Config -->
              @if (this.clientePropio?.tipoCliente !== "Final") {
                <button
                  mat-icon-button
                  [routerLink]="[
                    '/main/administracion/clientes/editar/',
                    this.clientePropio?._id,
                  ]"
                  matTooltip="Personalizar"
                  [disabled]="!this.clientePropio?._id"
                >
                  <mat-icon>settings</mat-icon>
                </button>
              }
            </div>
          </section>
        </mat-toolbar>
      </div>
      <!-- MAIN -->
      <div class="main" id="router">
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
</mat-drawer-container>
