import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CategoriaGrupo,
  ICliente,
  IFilter,
  IGrupo,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarAgrupacionesComponent } from '../crear-editar-grupos/crear-editar-grupos.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../auxiliares/tabla/tabla.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { GruposService } from '../../../../auxiliares/servicios/http/grupos.service';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    imports: [CommonModule, AuxiliaresModule],
    selector: 'app-listado-grupos',
    templateUrl: './listado-grupos.component.html',
    styleUrl: './listado-grupos.component.scss'
})
export class ListadoGruposComponent implements OnInit, OnDestroy {
  public categoria: CategoriaGrupo;
  public name = ListadoGruposComponent.name;
  public grupos: IGrupo[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IGrupo>[];
  //Subs
  public grupos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: false,
    tooltip: 'Crear',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private dialog: MatDialog,
    private service: GruposService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
  ) {}

  private puedeMostrarBotonCrear(): boolean {
    if (this.helper.puedeEditar()) {
      if (
        this.categoria === 'Línea de colectivo' &&
        HelperService.getConfigCliente().moduloColectivos?.crearDispositivos
      ) {
        return true;
      }
      if (
        this.categoria === 'Flota' &&
        HelperService.getConfigCliente().moduloActivos?.crearDispositivos
      ) {
        return true;
      }
      if (this.categoria === 'Normal') {
        return true;
      }
      return false;
    }
    return false;
  }

  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    const filter: IFilter<IGrupo> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    filter.categoria = this.categoria;
    this.query.filter = JSON.stringify(filter);

    //
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listados.getLastValue('grupos', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public crear() {
    const config: MatDialogConfig = {
      data: {
        categoria: this.categoria,
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarAgrupacionesComponent, config);
  }

  public async editar(grupo: IGrupo) {
    const config: MatDialogConfig = {
      data: {
        edit: grupo,
        categoria: this.categoria,
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarAgrupacionesComponent, config);
  }

  public async eliminar(agrupacion: IGrupo) {
    const res = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea eliminar el grupo ${agrupacion.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(agrupacion._id!);
      this.helper.notifSuccess('Eliminado correctamente');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  private setColumnas() {
    const columnas: IColumnas<IGrupo>[] = [
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Color
      {
        header: {
          label: 'Color',
        },
        row: {
          html(dato) {
            return `<span style="color: ${dato.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
          },
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  /// HOOKS

  public async ngOnInit(): Promise<void> {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();

    this.route.params.subscribe(async (params) => {
      this.categoria = params['categoria'] as CategoriaGrupo;
      await this.listarTodo();
    });
    this.botonCrear.mostrar = this.puedeMostrarBotonCrear();
  }

  public async ngOnDestroy(): Promise<void> {
    this.grupos$?.unsubscribe();
  }
}
