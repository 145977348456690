import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IFilter,
  IGrupo,
  IListado,
  IQueryParam,
  IRecorrido,
  ITracker,
  IUsuario,
  IActivo,
  ICreateActivo,
  IUpdateActivo,
  ICliente,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';

@Component({
    selector: 'app-crear-editar-colectivo',
    templateUrl: './crear-editar-colectivo.component.html',
    styleUrl: './crear-editar-colectivo.component.scss',
    standalone: false
})
export class CrearEditarColectivoComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public id?: string | null;
  public vehiculo?: IActivo;
  public vehiculo$?: Subscription;

  public trackers?: ITracker[];
  public trackers$?: Subscription;

  public recorridos?: IRecorrido[];
  public recorridos$?: Subscription;

  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;

  public grupos?: IGrupo[];
  public grupos$?: Subscription;

  public choferes?: IUsuario[];
  public choferes$?: Subscription;

  public estados = ['Operativo', 'En mantenimiento', 'Fuera de servicio'];

  get vehiculoForm() {
    return this.form?.get('vehiculo') as FormGroup;
  }

  constructor(
    public helper: HelperService,
    private route: ActivatedRoute,
    private service: ActivosService,
    private listados: ListadosService,
    private dialogService: DialogService,
  ) {}

  public createForm() {
    this.form = new FormGroup({
      identificacion: new FormControl(
        this.vehiculo?.identificacion,
        Validators.required,
      ),
      idGrupo: new FormControl(this.vehiculo?.idGrupo),
      idTracker: new FormControl(this.vehiculo?.idTracker, Validators.required),
      idUnicoTraccar: new FormControl(this.vehiculo?.idUnicoTraccar),
      idCliente: new FormControl(this.vehiculo?.idCliente, Validators.required),
      categoria: new FormControl('Vehículo'),
      vehiculo: new FormGroup({
        idChofer: new FormControl(this.vehiculo?.vehiculo.idChofer),
        idRecorrido: new FormControl(this.vehiculo?.vehiculo.idRecorrido),
        idsRecorridos: new FormControl(this.vehiculo?.vehiculo.idsRecorridos),
        patente: new FormControl(this.vehiculo?.vehiculo.patente),
        estado: new FormControl(this.vehiculo?.vehiculo.estado),
        tipo: new FormControl('Colectivo'),
      }),
    });
  }

  public async onSubmit() {
    this.loading = true;
    try {
      if (this.id) {
        // Editar
        const data = this.getData();
        if (data) {
          await this.service.update(this.id, data);
          this.helper.notifSuccess('Vehiculo actualizado correctamente');
          this.helper.volver();
        }
      } else {
        // Crear
        const data = this.getData();
        if (data) {
          await this.service.create(data);
          this.helper.notifSuccess('Vehiculo creado correctamente');
          this.helper.volver();
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public async clienteElegido(cliente: ICliente) {
    this.form.patchValue({ idTracker: null });
    this.form.patchValue({ idGrupo: null });
    this.form.patchValue({ idRecorrido: null });
    this.vehiculoForm.patchValue({ idChofer: null });
    if (cliente) {
      await Promise.all([
        this.listarGrupos(),
        this.listarRecorridos(),
        this.listarTrackers(),
        this.listarChoferes(),
      ]);
    } else {
      this.grupos = [];
      this.recorridos = [];
      this.trackers = [];
      this.choferes = [];
    }
  }

  private getData() {
    const data: ICreateActivo | IUpdateActivo = this.form?.value;
    data.categoria = 'Vehículo';
    if (data.idTracker) {
      const tracker = this.trackers?.find((t) => t._id === data.idTracker);
      if (tracker.traccar?.id) {
        data.idUnicoTraccar = tracker.traccar.id;
      } else {
        data.idUnicoTraccar = null;
      }
    } else {
      data.idUnicoTraccar = null;
    }
    return data;
  }

  public async listar() {
    this.vehiculo$?.unsubscribe();
    this.vehiculo$ = this.listados
      .subscribe<IActivo>('vehiculo', this.id!)
      .subscribe((data) => {
        this.vehiculo = data;
        console.log(`listado de vehiculo`, data);
      });
    await this.listados.getLastValue('vehiculo', this.id!);
  }
  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  public async listarRecorridos() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IRecorrido> = {
        idCliente: this.form.value.idCliente,
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'nombre',
        select: 'nombre idCliente',
        limit: 0,
        includeChildren: true,
      };
      this.recorridos$?.unsubscribe();
      this.recorridos$ = this.listados
        .subscribe<IListado<IRecorrido>>('recorridos', query)
        .subscribe((data) => {
          this.recorridos = data.datos;
          console.log(`listado de recorridos`, data);
        });
      await this.listados.getLastValue('recorridos', query);
    }
  }

  public async listarGrupos() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IGrupo> = {
        idCliente: this.form.value.idCliente,
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'nombre',
        select: 'nombre idCliente',
        limit: 0,
        includeChildren: true,
      };
      this.grupos$?.unsubscribe();
      this.grupos$ = this.listados
        .subscribe<IListado<IGrupo>>('grupos', query)
        .subscribe((data) => {
          this.grupos = data.datos;
          console.log(`listado de grupos`, data);
        });
      await this.listados.getLastValue('grupos', query);
    }
  }

  public async listarTrackers() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<ITracker> = {
        tipo: 'Traccar',
        $and: [
          { idCliente: this.form?.value?.idCliente },
          {
            $or: [
              {
                asignadoA: null,
              },
              {
                asignadoA: { $exists: false },
              },
            ],
          },
        ],
      };
      if (this.vehiculo?._id) {
        filter.$and[1].$or.push({
          _id: this.vehiculo?.idTracker,
        });
      }
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'identificacion',
        limit: 0,
        includeChildren: true,
      };
      //
      this.trackers$?.unsubscribe();
      this.trackers$ = this.listados
        .subscribe<IListado<ITracker>>('trackers', query)
        .subscribe((data) => {
          const trackers = JSON.parse(JSON.stringify(data.datos));
          this.trackers = trackers;
          console.log(`listado de trackers`, data);
        });
      await this.listados.getLastValue('trackers', query);
    }
  }

  public async listarChoferes() {
    if (this.form?.value?.idCliente) {
      const filter: IFilter<IUsuario> = {
        idCliente: this.form.value.idCliente,
        roles: 'Chofer',
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        sort: 'usuario',
        select: 'usuario idCliente',
        limit: 0,
      };
      this.choferes$?.unsubscribe();
      this.choferes$ = this.listados
        .subscribe<IListado<IUsuario>>('usuarios', query)
        .subscribe((data) => {
          this.choferes = data.datos;
          console.log(`listado de choferes`, data);
        });
      await this.listados.getLastValue('usuarios', query);
    }
  }

  /// HOOKS

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      await this.listar();
    }
    this.createForm();
    await Promise.all([
      this.listarTrackers(),
      this.listarRecorridos(),
      this.listarGrupos(),
      this.listarChoferes(),
    ]);
    if (!this.helper.esClienteFinal()) {
      await this.listarClientes();
    }
  }

  ngOnDestroy(): void {
    this.vehiculo$?.unsubscribe();
    this.recorridos$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.trackers$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
