import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  ICronograma,
  ICreateCronograma,
  IUpdateCronograma,
  TipoDeCronograma,
  IQueryParam,
  IListado,
  IUsuario,
  IFilter,
  IRecorrido,
  IUbicacion,
  IActivo,
  Dia,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { CronogramasService } from '../../cronogramas/cronogramas.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
    selector: 'app-crear-editar-cronograma',
    templateUrl: './crear-editar-cronograma.component.html',
    styleUrl: './crear-editar-cronograma.component.scss',
    standalone: false
})
export class CrearEditarCronogramaComponent implements OnInit, OnDestroy {
  public form?: FormGroup;

  public id?: string | null;
  public cronograma?: ICronograma;
  private cronograma$?: Subscription;
  // Selects
  public tipos: TipoDeCronograma[] = ['despacho', 'turnos'];
  public dias: Dia[] = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];
  public terminales: IUbicacion[] = [];
  private terminales$?: Subscription;
  public vehiculos: IActivo[] = [];
  private vehiculos$?: Subscription;
  public choferes: IUsuario[] = [];
  private choferes$?: Subscription;
  public recorridos: IRecorrido[] = [];
  private recorridos$?: Subscription;
  //
  public hoy = new Date();
  public hoyALas0 = new Date(
    this.hoy.getFullYear(),
    this.hoy.getMonth(),
    this.hoy.getDate(),
    0,
    0,
    0,
  );

  get configuracion() {
    return this.form?.get('configuracion') as FormGroup;
  }

  get periodos() {
    return this.form?.get('periodos') as FormArray;
  }

  public loading = true;
  constructor(
    private service: CronogramasService,
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.cronograma?.nombre, [Validators.required]),
      descripcion: new FormControl(this.cronograma?.descripcion),
      tipo: new FormControl(this.cronograma?.tipo || 'despacho', [
        Validators.required,
      ]),
      idUbicacion: new FormControl(this.cronograma?.idUbicacion, [
        Validators.required,
      ]),
      automatico: new FormControl(this.cronograma?.automatico),
      dias: new FormControl(this.cronograma?.dias),
      configuracion: new FormGroup({
        color: new FormControl(this.cronograma?.configuracion?.color),
        nombreParaMostrar: new FormControl(
          this.cronograma?.configuracion?.nombreParaMostrar,
        ),
      }),
      periodos: new FormArray(this.initPeriodos()),
    });
  }

  private initPeriodos() {
    const array: FormGroup[] = [];
    if (this.cronograma?.periodos) {
      for (const p of this.cronograma.periodos) {
        // Agrego los datos fijos de cada periodo
        const periodo = new FormGroup({});
        periodo.setControl('desde', new FormControl(p.desde));
        // Agrego los datos variables dentro de datos
        const datos = new FormGroup({});
        for (const key in p.datos) {
          if (Object.prototype.hasOwnProperty.call(p.datos, key)) {
            datos.setControl(key, new FormControl(p.datos[key]));
          }
        }
        // Agrego datos al periodo
        periodo.setControl('datos', datos);
        // Agrego el perido al grupo
        array.push(periodo);
      }
      // Devuelvo el array
      return array;
    } else {
      array.push(this.agregarPeriodoFormGroup());
      return array;
    }
  }

  public agregarPeriodoFormGroup() {
    return new FormGroup({
      desde: new FormControl(),
      datos: new FormGroup({
        idVehiculo: new FormControl([Validators.required]),
        idChofer: new FormControl(),
        idsRecorridos: new FormControl(null, [Validators.required]),
        idRecorridoActual: new FormControl(),
      }),
    });
  }

  public agregarPeriodo() {
    this.periodos.push(this.agregarPeriodoFormGroup());
  }

  public borrarPeriodo(i: number) {
    this.periodos.removeAt(i);
  }

  private async listar() {
    this.cronograma$?.unsubscribe();
    this.cronograma$ = this.listados
      .subscribe<ICronograma>('cronograma', this.id!)
      .subscribe((data) => {
        this.cronograma = data;
        console.log(`listado de cronograma`, data);
      });
    await this.listados.getLastValue('cronograma', this.id!);
  }

  private async listarTerminales() {
    const filtro: IFilter<IUbicacion> = {
      categoria: 'Terminal',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filtro),
      sort: 'nombre',
      limit: 0,
      select: 'nombre identificacion',
    };
    this.terminales$?.unsubscribe();
    this.terminales$ = this.listados
      .subscribe<IListado<IUbicacion>>('ubicacions', query)
      .subscribe((data) => {
        this.terminales = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listados.getLastValue('ubicacions', query);
  }

  // Listados para los períodos
  // Vehículos
  private async listarVehiculos() {
    const query: IQueryParam = {
      sort: 'identificacion',
      limit: 0,
      select: 'identificacion patente',
    };
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', query)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', query);
  }
  // Choferes
  private async listarChoferes() {
    const filter: IFilter<IUsuario> = {
      roles: 'Chofer',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'datosPersonales.nombre',
      limit: 0,
      select: 'datosPersonales.nombre identificacionInterna usuario roles',
    };
    this.choferes$?.unsubscribe();
    this.choferes$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.choferes = data.datos;
        console.log(`listado de choferes`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }
  // Recorridos
  private async listarRecorridos() {
    const query: IQueryParam = {
      sort: 'nombre',
      limit: 0,
      select: 'nombre nombreFlota destino por',
    };
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorridos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }

  ///
  public async onSubmit() {
    this.loading = true;
    try {
      if (this.id) {
        const res = await this.dialogService.confirm(
          'Actualización',
          `¿Está seguro de que desea actualizar el cronograma ${this.cronograma.nombre}?`,
        );
        if (!res) {
          this.loading = false;
          return;
        }
        // Update
        const data = this.getData();
        if (!data) {
          this.loading = false;
          return;
        }
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Cronograma actualizado');
        HelperService.volver();
      } else {
        const res = await this.dialogService.confirm(
          'Creación',
          `¿Está seguro de que desea crear un nuevo cronograma?`,
        );
        if (!res) {
          this.loading = false;
          return;
        }
        // Create
        const data = this.getData();
        if (!data) {
          this.loading = false;
          return;
        }
        await this.service.create(data);
        this.helper.notifSuccess('Cronograma creado');
        HelperService.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateCronograma | IUpdateCronograma {
    const data: ICreateCronograma | IUpdateCronograma = this.form?.value;
    return data;
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      await this.listar();
    }

    await Promise.all([
      this.listarTerminales(),
      this.listarVehiculos(),
      this.listarChoferes(),
      this.listarRecorridos(),
    ]);
    this.loading = false;
    this.createForm();
  }

  ngOnDestroy(): void {
    this.cronograma$?.unsubscribe();
    this.terminales$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.recorridos$?.unsubscribe();
  }
}
