import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
    selector: 'app-flotante-ol',
    templateUrl: './flotante-ol.component.html',
    styleUrl: './flotante-ol.component.scss',
    standalone: false
})
export class FlotanteOlComponent implements OnChanges {
  @Output() ocultar = new EventEmitter<string>();
  @Input() vehiculos?: number;
  @Input() activos?: number;
  @Input() alarmas?: number;
  public alarmasText: string = '';
  public alarmasOcultas: boolean = false;
  public vehiculosOcultos: boolean = false;
  public activosOcultos: boolean = false;

  constructor() {}

  public ocultarE(e: string) {
    if (e === 'Alarmas') {
      this.alarmasOcultas = !this.alarmasOcultas;
      this.ocultar.emit('Alarmas');
    }
    if (e === 'Vehiculos') {
      this.vehiculosOcultos = !this.vehiculosOcultos;
      this.ocultar.emit('Vehiculos');
    }
    if (e === 'Activos') {
      this.activosOcultos = !this.activosOcultos;
      this.ocultar.emit('Activos');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['alarmas']?.currentValue) {
      this.alarmasText = `Alarmas \n ${this.alarmas}`;
    }
  }
}
