import { Component, Inject, OnInit } from '@angular/core';

import {
  IListado,
  IFilter,
  ITratamientoEvento,
  IQueryParam,
  IPopulate,
  IGeoJSONPoint,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  MapaFacilData,
  MarkerMapa,
} from '../../../../auxiliares/mapa-facil/interfaces';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { MapaFacilComponent } from '../../../../auxiliares/mapa-facil/mapa-facil.component';

interface nota {
  usuario: string;
  nota: string;
}
interface estado {
  usuario: string;
  estado: string;
}

@Component({
    imports: [CommonModule, AuxiliaresModule, MapaFacilComponent],
    selector: 'app-eventos-detalles',
    templateUrl: './eventos-detalles.component.html',
    styleUrl: './eventos-detalles.component.scss'
})
export class EventosDetallesComponent implements OnInit {
  //DEMAS
  public title?: string;
  public idEvento?: string | null;
  public loading = true;

  public ubicacion: MapaFacilData;
  public tratamientoEvento: ITratamientoEvento;
  public tratamientosEvento: ITratamientoEvento[] = [];
  public tratamientosEvento$?: Subscription;

  public columnasEstados = ['usuarios', 'estados'];
  public columnasNotas = ['usuarios', 'notas'];
  public columnasNotasInternas = ['usuarios', 'notasInternas'];
  public estados: estado[] = [];
  public notas: nota[] = [];
  public notasInternas: nota[] = [];

  private populate: IPopulate[] = [
    {
      path: 'evento',
      populate: [
        { path: 'activo' },
        { path: 'alarma', populate: { path: 'domicilio' } },
        { path: 'cliente' },
      ],
    },
    {
      path: 'usuario',
    },
  ];

  constructor(
    public helper: HelperService,
    public dialog: MatDialog,
    private listados: ListadosService,
    private route: ActivatedRoute,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<ITratamientoEvento> = {
      idsEventos: [this.idEvento],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(this.populate),
    };
    this.tratamientosEvento$?.unsubscribe();
    this.tratamientosEvento$ = this.listados
      .subscribe<IListado<ITratamientoEvento>>('tratamientosEventos', query)
      .subscribe((data) => {
        this.tratamientosEvento = JSON.parse(JSON.stringify(data.datos));
        console.log(`listado de tratamientosEventos`, data);
        this.cargarInfo();
      });
    await this.listados.getLastValue('tratamientosEventos', query);
    this.loading = false;
  }

  private cargarInfo() {
    this.tratamientoEvento =
      this.tratamientosEvento[this.tratamientosEvento.length - 1];
    const estados: estado[] = [];
    const notas: nota[] = [];
    const notasInternas: nota[] = [];
    this.tratamientosEvento.map((t) => {
      if (t.usuario)
        estados.push({ usuario: t.usuario.usuario, estado: t.estado });
      else estados.push({ usuario: 'SISTEMA', estado: t.estado });
      if (t.nota) {
        notas.push({ usuario: t.usuario.usuario, nota: t.nota });
      }
      if (t.notaInterna) {
        notasInternas.push({
          usuario: t.usuario.usuario,
          nota: t.notaInterna,
        });
      }
    });
    this.estados = [...estados];
    this.notas = [...notas];
    this.notasInternas = [...notasInternas];
    // para que no se vea tan feito
    while (this.notas.length < 3) {
      this.notas.push({ nota: '', usuario: '' });
    }
    while (this.notasInternas.length < 3) {
      this.notasInternas.push({ nota: '', usuario: '' });
    }
    let c = null;
    if (this.tratamientoEvento?.eventos[0]?.valores['geojson']) {
      c = OpenLayersService.lonLatToCoordinate(
        this.tratamientoEvento?.eventos[0]?.valores['geojson']?.coordinates[0],
        this.tratamientoEvento?.eventos[0]?.valores['geojson']?.coordinates[1],
      );
    }
    if (this.tratamientoEvento?.eventos[0]?.alarma) {
      c = OpenLayersService.lonLatToCoordinate(
        (
          this.tratamientoEvento?.eventos[0]?.alarma?.domicilio
            ?.geojson as IGeoJSONPoint
        ).coordinates[0],
        (
          this.tratamientoEvento?.eventos[0]?.alarma?.domicilio
            ?.geojson as IGeoJSONPoint
        ).coordinates[1],
      );
    }
    const m: MarkerMapa[] = [
      {
        label: 'puntoSimple',
        coor: c,
        centrar: true,
      },
    ];
    this.ubicacion = {
      markers: m,
      center: c,
    };
  }

  ///

  async ngOnInit() {
    this.idEvento = this.data.idEvento;
    await this.listar();
  }
}
