import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IDespacho,
  ICreateDespacho,
  IUpdateDespacho,
} from 'modelos/src';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class DespachosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IDespacho>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/despachos`, { params });
  }

  public getById(id: string): Promise<IDespacho> {
    return this.http.get(`/despachos/${id}`);
  }

  public create(dato: ICreateDespacho): Promise<IDespacho> {
    return this.http.post(`/despachos`, dato);
  }

  public update(id: string, dato: IUpdateDespacho): Promise<IDespacho> {
    return this.http.put(`/despachos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/despachos/${id}`);
  }
}
