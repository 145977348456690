import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapaService {
  private _siguiendo?: string;
  public siguiendoChange = new EventEmitter<string>();

  public get siguiendo(): string {
    return this._siguiendo;
  }

  public set siguiendo(id: string) {
    this._siguiendo = id;
    this.siguiendoChange.emit(id);
  }

  constructor() {}
}
