<div class="flotante-contenedor">
  @if (vehiculos) {
    @if (!vehiculosOcultos) {
      <div class="flotante-elementos" (click)="ocultarE('Vehiculos')">
        <span>Vehiculos:</span>
        <span>{{ vehiculos }}</span>
      </div>
    } @else {
      <div class="flotante-elementos2" (click)="ocultarE('Vehiculos')">
        <span>Vehiculos:</span>
        <span>{{ vehiculos }}</span>
      </div>
    }
  }
  @if (activos) {
    @if (!activosOcultos) {
      <div class="flotante-elementos" (click)="ocultarE('Activos')">
        <span>Activos:</span>
        <span>{{ activos }}</span>
      </div>
    } @else {
      <div class="flotante-elementos2" (click)="ocultarE('Activos')">
        <span>Activos:</span>
        <span>{{ activos }}</span>
      </div>
    }
  }
  @if (alarmas) {
    @if (!alarmasOcultas) {
      <div class="flotante-elementos" (click)="ocultarE('Alarmas')">
        <span>Alarmas:</span>
        <span>{{ alarmas }}</span>
      </div>
    } @else {
      <div class="flotante-elementos2" (click)="ocultarE('Alarmas')">
        <span>Alarmas:</span>
        <span>{{ alarmas }}</span>
      </div>
    }
  }
</div>
