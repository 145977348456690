@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información del Vehiculo</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Grupo:</span>
      <span class="dato">{{ colectivo?.grupo?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Cliente:</span>
      <span class="dato">{{ colectivo?.cliente?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Categoria:</span>
      <span class="dato">{{ colectivo?.categoria }}</span>
    </div>
  </div>
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Tipo:</span>
      <span class="dato">{{ colectivo?.vehiculo?.tipo }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Patente:</span>
      <span class="dato">{{ colectivo?.vehiculo?.patente }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Estado:</span>
      <span class="dato">{{ colectivo?.vehiculo?.estado }}</span>
    </div>
  </div>

  @if (this.reporte) {
    <br />
    <div class="column-center">
      <span class="subtitle1">Ultima Ubicacion Reportada</span>
      <div style="width: 600px; height: 600px">
        <app-mapa-facil [data]="datoMapa"></app-mapa-facil>
      </div>
    </div>
  }
  <div class="column-center" style="padding: 0.5em">
    <!-- Recorrido -->
    @if (colectivo?.vehiculo.recorrido) {
      <div class="row-center">
        <div class="row-center" style="gap: 15px">
          <ngx-colors
            ngx-colors-trigger
            disabled
            [(ngModel)]="colectivo!.vehiculo.recorrido!.color"
          >
          </ngx-colors>
          Recorrido: {{ colectivo?.vehiculo.recorrido?.nombre }}
        </div>
        <div></div>
        <div class="row-center" style="gap: 15px">
          <span></span>
          <span>A: {{ colectivo?.vehiculo.recorrido?.destino }}</span>
          <span>Por: {{ colectivo?.vehiculo.recorrido?.por }}</span>
        </div>
      </div>
      <br />
      <section class="row-center" style="gap: 10px">
        @for (f of colectivo?.vehiculo.recorrido?.franjaHoraria; track $index) {
          <div class="column-center rango mat-elevation-z4">
            <div>
              {{ helper.diaATexto(f.dia!) }}
            </div>

            <div>
              Rango: {{ helper.getHora(f.desde!) }} -
              {{ helper.getHora(f.hasta!) }}
            </div>

            <div>Frecuencia: {{ f.frecuenciaMinutos }} min.</div>
          </div>
        }
      </section>
    }
  </div>
}
