import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventosComponent } from './eventos/eventos.component';
import { EventoDetallesComponent } from './listado-eventos-historico/eventos-detalles/evento-detalles.component';

const routes: Routes = [
  { path: '', component: EventosComponent, canActivate: [] },
  {
    path: 'detalles/:id',
    component: EventoDetallesComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventosRoutingModule {}
