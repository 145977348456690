@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content>
        <!-- Nombre -->
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="nombre" required />
        </mat-form-field>

        <!-- Color -->
        <mat-form-field>
          <mat-label>Color</mat-label>
          <input matInput readonly formControlName="color" />
          <ngx-colors
            class="suffix"
            matSuffix
            ngx-colors-trigger
            formControlName="color"
          >
          </ngx-colors>
        </mat-form-field>
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
