import { Injectable } from '@angular/core';
import {
  IConfigEventoUsuario,
  ICreateConfigEventoUsuario,
  IListado,
  IQueryParam,
  IUpdateConfigEventoUsuario,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigEventoUsuarioService {
  constructor(private http: HttpClientService) {}

  public async getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IConfigEventoUsuario>> {
    const params = HelperService.getQueryParams(queryParams);
    return await this.http.get(`/configeventousuarios`, { params });
  }

  public getById(id: string): Promise<IConfigEventoUsuario> {
    return this.http.get(`/configeventousuarios/${id}`);
  }

  public create(
    dato: ICreateConfigEventoUsuario,
  ): Promise<IConfigEventoUsuario> {
    return this.http.post(`/configeventousuarios`, dato);
  }

  public update(
    id: string,
    dato: IUpdateConfigEventoUsuario,
  ): Promise<IConfigEventoUsuario> {
    return this.http.put(`/configeventousuarios/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/configeventousuarios/${id}`);
  }
}
