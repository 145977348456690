import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { FlotanteOlComponent } from './mapa-ol/flotante-ol/flotante-ol.component';
import { IslaOlComponent } from './mapa-ol/isla-ol/isla-ol.component';
import { MapaOlComponent } from './mapa-ol/mapa-ol.component';
import { PopupOlComponent } from './mapa-ol/popup-ol/popup-ol.component';
import { MapaRoutingModule } from './mapa.routing.module';

@NgModule({
  declarations: [
    MapaOlComponent,
    PopupOlComponent,
    FlotanteOlComponent,
    IslaOlComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, MapaRoutingModule],
})
export class MapaModule {}
