<div class="column-center mat-elevation-z1" style="gap: 15px; padding: 0.5em">
  <div class="row-center" style="width: 100%">
    <span class="titulo">Datos del Documento</span>
  </div>
  <!-- Tipo -->
  <mat-card style="width: 100%; gap: 10px">
    <mat-card-header>
      <mat-card-title>Tipo</mat-card-title>
      <div class="row-center" style="width: 100%">
        <span>{{ documento?.tipo }}</span>
      </div>
    </mat-card-header>
    <mat-card-content> </mat-card-content>
  </mat-card>
  <!-- Emision y Vencimiento -->
  <mat-card style="width: 100%; gap: 10px">
    <mat-card-header>
      <div class="row" style="width: 100%">
        <mat-card-title>Fecha de Emision</mat-card-title>
        <span>{{ documento?.emision | date: "dd/MM/yyyy" }}</span>
      </div>
      <div class="center" style="width: 100%">
        <mat-card-title>Fecha de Vencimiento</mat-card-title>
        <span>{{ documento?.vencimiento | date: "dd/MM/yyyy" }}</span>
      </div>
    </mat-card-header>
    <mat-card-content> </mat-card-content>
  </mat-card>
  <!-- Descripcion -->
  <mat-card style="width: 100%; gap: 10px">
    <mat-card-header>
      <mat-card-title>Descripcion</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" style="width: 100%">
        <span>{{ documento?.descripcion }}</span>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- Imagenes -->
  <mat-card style="width: 100%; gap: 10px">
    <mat-card-header>
      <mat-card-title>Imagenes</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="column-center" style="width: 100%">
        <img
          *ngFor="let url of documento?.imagenes"
          [src]="url"
          alt="Imagen"
          style="
            max-width: 100%;
            width: auto;
            margin-top: 1em;
            margin-bottom: 1em;
          "
        />
      </div>
    </mat-card-content>
  </mat-card>
</div>
<br />
<!-- Botones  -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.helper.volver()"
    text="Volver"
    icon="arrow_back"
  ></app-button>
  <app-button
    matType="mat-fab extended"
    color="accent"
    (click)="editar()"
    text="Editar"
    icon="edit"
  ></app-button>
</div>
