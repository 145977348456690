@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Informacion del tracker</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Identificacion:</span>
      <span class="dato">{{ tracker?.identificacion }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Asignado a :</span>
      <span class="dato">{{ tracker?.activo?.identificacion }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Marca y Modelo:</span>
      <span class="dato"
        >{{ tracker?.modelo?.marca }} - {{ tracker?.modelo?.modelo }}</span
      >
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Cliente:</span>
      <span class="dato">{{ tracker?.cliente.nombre }}</span>
    </div>
  </div>
  <br />
  @if (tracker?.traccar) {
    <div class="column-center" style="gap: 10px">
      <span class="subtitle1">Datos Traccar</span>
      <div class="row-center" style="gap: 10px">
        <span class="datoName">ID:</span>
        <span class="dato">{{ tracker?.traccar?.id }}</span>

        <span class="datoName">Name:</span>
        <span class="dato">{{ tracker?.traccar?.name }}</span>

        <span class="datoName">Unique ID:</span>
        <span class="dato">{{ tracker?.traccar?.uniqueId }}</span>

        <span class="datoName">Status:</span>
        <span class="dato">{{ tracker?.traccar?.status }}</span>

        <span class="datoName">Disabled:</span>
        <span class="dato">
          @if (tracker?.traccar?.disabled) {
            ✔️
          } @else {
            ❌
          }
        </span>
      </div>
      <div class="row-center" style="gap: 10px">
        <span class="datoName">Last Update:</span>
        <span class="dato">{{
          tracker?.traccar?.lastUpdate | date: "short"
        }}</span>

        <span class="datoName">Position ID:</span>
        <span class="dato">{{ tracker?.traccar?.positionId }}</span>

        <span class="datoName">Group ID:</span>
        <span class="dato">{{ tracker?.traccar?.groupId }}</span>

        <span class="datoName">Model:</span>
        <span class="dato">{{ tracker?.traccar?.model }}</span>

        <span class="datoName">Contact:</span>
        <span class="dato">{{ tracker?.traccar?.contact }}</span>

        <span class="datoName">Category:</span>
        <span class="dato">{{ tracker?.traccar?.category }}</span>
      </div>
    </div>
  }
  @if (tracker?.qualcomm?.serialNumber) {
    <div class="column-center" style="gap: 10px">
      <span class="subtitle1">Datos Qualcomm</span>
      <span class="datoName">SerialNumber:</span>
      <span class="dato">{{ tracker?.qualcomm?.serialNumber }}</span>
    </div>
  }
  @if (reporte) {
    <br />
    <div class="column-center">
      <span class="subtitle1">Ultima Ubicacion</span>
      <div style="width: 600px; height: 600px">
        <app-mapa-facil [data]="datoMapa"></app-mapa-facil>
      </div>
    </div>
  }
}
