import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import {
  ICreateServicioContratado,
  IListado,
  IQueryParam,
  IServicioContratado,
  IUpdateServicioContratado,
} from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class ServiciosContratadosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IServicioContratado>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/serviciocontratados`, { params });
  }

  public getById(id: string): Promise<IServicioContratado> {
    return this.http.get(`/serviciocontratados/${id}`);
  }

  public create(dato: ICreateServicioContratado): Promise<IServicioContratado> {
    return this.http.post(`/serviciocontratados`, dato);
  }

  public update(
    id: string,
    dato: IUpdateServicioContratado,
  ): Promise<IServicioContratado> {
    return this.http.put(`/serviciocontratados/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/serviciocontratados/${id}`);
  }
}
