import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { NavigationRoutingModule } from './navigation.routing.module';
import { VersionComponent } from './version/version.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { NotificacionesModule } from '../entidades/notificaciones/module';
import { PushNotificationsService } from '../../auxiliares/servicios/push-notifications.service';

@NgModule({
  declarations: [NavigationComponent, VersionComponent, UsuarioComponent],
  imports: [
    CommonModule,
    AuxiliaresModule,
    NavigationRoutingModule,
    NotificacionesModule,
  ],
})
export class NavigationModule {
  constructor(private pushService: PushNotificationsService) {}
}
