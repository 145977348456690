import { Component, OnInit, signal } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IUsuario, ICreateUsuario, IUpdateUsuario } from 'modelos/src';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { UsuariosService } from '../../usuarios/usuarios.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import { LoginService } from '../../../../login/login.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-crear-tecnico-rapido',
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './crear-tecnico-rapido.component.html',
  styleUrl: './crear-tecnico-rapido.component.scss',
})
export class CrearTecnicoRapidoComponent implements OnInit {
  public loading = signal(false);
  public form?: FormGroup;
  // Sexos según Fer
  public sexos = [
    { label: 'Masculino', value: true },
    { label: 'Femenino', value: false },
  ];

  private usuario: IUsuario = LoginService.getUsuario();
  constructor(
    public helper: HelperService,
    private service: UsuariosService,
    private dialogService: DialogService,
    private dialogRef: DialogRef,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      usuario: new FormControl(null, [Validators.required]),
      hash: new FormControl(null, [Validators.required]),
      identificacionInterna: new FormControl(null),
      roles: new FormControl('Técnico'),
      idCliente: new FormControl(this.usuario?.idCliente),
    });
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `Crear Técnico`,
      `¿Desea crear el técnico?`,
    );
    if (!res) return;
    try {
      this.loading.set(true);
      const data = this.getData();
      // Create
      await this.service.create(data);
      this.helper.notifSuccess('Técnico creado');
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    } finally {
      this.loading.set(false);
    }
  }

  public getData(): ICreateUsuario | IUpdateUsuario {
    const data: ICreateUsuario | IUpdateUsuario = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.createForm();
  }
}
