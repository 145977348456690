import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import {
  ICreateEventoTecnico,
  IListado,
  IQueryParam,
  IEventoTecnico,
  IUpdateEventoTecnico,
} from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class EventosTecnicosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IEventoTecnico>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/eventotecnicos`, { params });
  }

  public getById(id: string): Promise<IEventoTecnico> {
    return this.http.get(`/eventotecnicos/${id}`);
  }

  public create(dato: ICreateEventoTecnico): Promise<IEventoTecnico> {
    return this.http.post(`/eventotecnicos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateEventoTecnico,
  ): Promise<IEventoTecnico> {
    return this.http.put(`/eventotecnicos/${id}`, dato);
  }

  public asignar(id: string, idTecnico: string): Promise<IEventoTecnico> {
    return this.http.put(`/eventotecnicos/asignar/${id}`, { idTecnico });
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/eventotecnicos/${id}`);
  }
}
