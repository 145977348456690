<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante">
    <app-flotante-activos
      (activosChange)="onActivosChange()"
      [activos]="reportesActivos?.length"
    ></app-flotante-activos>
  </div>
  @if (reporte) {
    <div id="isla" class="ol-isla">
      <app-isla-activos
        [(reporte)]="reporte"
        [(trackeo)]="trackeo"
        (reporteChange)="onClose()"
      ></app-isla-activos>
    </div>
  }
</div>
