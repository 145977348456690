import { Injectable } from '@angular/core';
import {
  IEvento,
  IListado,
  IQueryParam,
  IUpdateEvento,
  ICreateEvento,
} from 'modelos/src';
import { HttpClientService } from '../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class EventosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IEvento>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/eventos`, { params });
  }

  public getById(id: string): Promise<IEvento> {
    return this.http.get(`/eventos/${id}`);
  }

  public getVivo(queryParams?: IQueryParam): Promise<IEvento[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/eventos/vivo`, { params });
  }

  public create(dato: ICreateEvento): Promise<IEvento> {
    return this.http.post(`/eventos`, dato);
  }

  public update(id: string, dato: IUpdateEvento): Promise<IEvento> {
    return this.http.put(`/eventos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/eventos/${id}`);
  }
}
