<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Setear Odometro a {{ this.data?.identificacion }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="formulario"
      id="form"
      [formGroup]="formulario"
      (ngSubmit)="enviar()"
      fxLayout="column"
    >
      <mat-form-field>
        <mat-label>Horas</mat-label>
        <input matInput formControlName="hours" required type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Odometro</mat-label>
        <input
          matInput
          formControlName="totalDistance"
          required
          type="number"
        />
        <mat-hint>Distancia en km</mat-hint>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions
    style="margin: 0.5em; gap: 1em; display: flex; justify-content: flex-end"
  >
    <button mat-raised-button type="button" (click)="close()">Volver</button>

    <button
      class="botonSpinner"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!formulario?.valid || loading"
      form="form"
    >
      Guardar
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
