import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { LoginService } from '../../../login/login.service';
import { map, shareReplay } from 'rxjs/operators';
import { NotificacionesService } from '../service';

@Component({
    selector: 'app-notificaciones',
    templateUrl: './notificaciones.component.html',
    styleUrls: ['./notificaciones.component.scss'],
    standalone: false
})
export class NotificacionesComponent implements OnInit {
  public notificacionesSinLeer = 0;

  public notifConfiguradas: boolean = true;

  // Listado Continuo
  public datos$?: Subscription;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private listadosService: ListadosService,
    private helper: HelperService,
    private loginService: LoginService,
    private notificacionsService: NotificacionesService,
  ) {}

  private async cantidadSinLeer(): Promise<void> {
    try {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<{ totalCount: number }>('notificacionsSinLeer', '0')
        .subscribe((data) => {
          this.notificacionesSinLeer = data.totalCount;
          console.log(`listado de notificacionsSinLeer`, data);
        });
      await this.listadosService.getLastValue('notificacionsSinLeer', '0');
    } catch (error) {
      this.helper.notifError(error);
    }
  }

  private getUser() {
    //this.loginService.getUser.subscribe((user) => {
    //  this.notifConfiguradas = !!user?.notificaciones?.length;
    //});
  }

  async ngOnInit(): Promise<void> {
    this.getUser();
    await this.cantidadSinLeer();
    //this.notifConfiguradas =
    //  !!LoginService.getUsuario()?.notificaciones?.length;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
