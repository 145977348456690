@if (tecnicos) {
  <h2 mat-dialog-title>Asignar Servicio Técnico</h2>
  <mat-dialog-content class="mat-typography">
    <div class="row-center" style="width: 100%; gap: 8px">
      <ng-select
        [items]="tecnicos"
        bindValue="_id"
        [ngModel]="tecnicoSeleccionado"
        placeholder="Técnico"
        [notFoundText]="'No hay técnicos creados'"
        style="width: 90%"
        multiple="false"
        searchable="true"
        appendTo="body"
        (change)="onChange($event)"
        (clear)="onClear()"
      >
        <!-- Tag -->
        <ng-template ng-label-tmp let-item="item">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.5em;
            "
          >
            <!-- AVATAR - NOMBRE -->
            @if (item.datosPersonales?.foto) {
              <div class="preview-container">
                <div
                  class="preview-tag"
                  [style.background-image]="
                    'url(' + item.datosPersonales.foto + ')'
                  "
                ></div>
              </div>
            }
            <span>{{ item.datosPersonales?.nombre || item?.usuario }}</span>
          </div>
        </ng-template>
        <!-- Opción -->
        <ng-template ng-option-tmp let-item="item">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.5em;
            "
          >
            <!-- AVATAR - NOMBRE -->
            @if (item.datosPersonales?.foto) {
              <div class="preview-container">
                <div
                  class="preview"
                  [style.background-image]="
                    'url(' + item.datosPersonales.foto + ')'
                  "
                ></div>
              </div>
            }

            <span>{{ item.datosPersonales?.nombre || item?.usuario }}</span>
          </div>
        </ng-template>
      </ng-select>
      <app-button
        matType="mat-fab"
        [disabled]="!loading()"
        matTooltip="Crear técnico"
        style="cursor: pointer"
        color="accent"
        icon="add"
        type="button"
        (click)="crear()"
      ></app-button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="gap: 8px">
    <app-button
      matType="mat-fab extended"
      color="warn"
      mat-dialog-close
      text="Cerrar"
      icon="arrow_back"
    ></app-button>
    <app-button
      matType="mat-fab extended"
      [disabled]="!loading()"
      matTooltip="Asignar técnico"
      style="cursor: pointer"
      icon="engineering"
      color="accent"
      type="button"
      text="Asignar"
      (click)="asignar()"
    ></app-button>
  </mat-dialog-actions>
} @else {
  <div class="row-center">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
    ></mat-progress-spinner>
  </div>
}
