import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoAlarmasDomiciliariasComponent } from './alarmas-domiciliarias/listado-alarmas-domiciliarias/listado-alarmas-domiciliarias.component';
import { CrearEditarAlarmaDomiciliariaComponent } from './alarmas-domiciliarias/crear-editar-alarma-domiciliaria/crear-editar-alarma-domiciliaria.component';
import { MapaAlarmasComponent } from './mapa-alarmas/mapa-alarmas.component';
import { DetallesAlarmaComponent } from './detalles-alarma/detalles-alarma.component';
import { CrearEditarCodigosDispositivosComponent } from '../../standalone/codigos-dispositivos/crear-editar-codigos-dispositivos/crear-editar-codigos-dispositivos.component';
import { ListadoCodigosDispositivoComponent } from '../../standalone/codigos-dispositivos/listado-codigos-dispositivos/listado-codigos-dispositivos.component';
import { ListadoModelosDispositivosComponent } from '../../standalone/modelos-dispositivos/listado-modelos-dispositivos/listado-modelos-dispositivos.component';
import { CrearEditarModeloDispositivoComponent } from '../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';

const routes: Routes = [
  { path: '', redirectTo: 'mapa', pathMatch: 'full' },
  //Mapa
  {
    path: 'mapa',
    component: MapaAlarmasComponent,
    canActivate: [],
  },
  // AlarmasDomiciliarias
  {
    path: 'alarmasDomiciliarias',
    component: ListadoAlarmasDomiciliariasComponent,
    canActivate: [],
  },
  {
    path: 'alarmasDomiciliarias/crear',
    component: CrearEditarAlarmaDomiciliariaComponent,
    canActivate: [],
  },
  {
    path: 'alarmasDomiciliarias/editar/:id',
    component: CrearEditarAlarmaDomiciliariaComponent,
    canActivate: [],
  },
  {
    path: 'alarmasDomiciliarias/detallesAlarma/:id',
    component: DetallesAlarmaComponent,
    canActivate: [],
  },
  // Codigos Dispositivos
  {
    path: 'codigosDispositivos/:tipo',
    component: ListadoCodigosDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/crear',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/editar/:id',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },

  // Modelos Dispositivos
  {
    path: 'modelosDispositivos/:tipo',
    component: ListadoModelosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/crear',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/editar/:id',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlarmasRoutingModule {}
