@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ vehiculo?.identificacion }}</span>
      } @else {
        <span class="titulo">Crear Vehiculo</span>
      }
    </div>
    <br />
    <!-- identificacion - patente - estado -->
    <section class="layout">
      <div class="grow1">
        <mat-form-field>
          <mat-label>Identificación</mat-label>
          <input matInput formControlName="identificacion" />
        </mat-form-field>
      </div>
      <div class="grow1" [formGroup]="vehiculoForm">
        <mat-form-field>
          <mat-label>Patente</mat-label>
          <input matInput formControlName="patente" />
        </mat-form-field>
      </div>
      <div class="grow1" [formGroup]="vehiculoForm">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select formControlName="estado">
            @for (e of estados; track e) {
              <mat-option [value]="e">{{ e }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (!helper.esClienteFinal()) {
        <!-- Clientes -->
        <ng-select
          [items]="clientes"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idCliente"
          placeholder="Cliente"
          [notFoundText]="'No hay clientes creados'"
          style="width: 25%"
          multiple="false"
          searchable="true"
          appendTo="body"
          (change)="clienteElegido($event)"
        >
        </ng-select>
      }
    </section>
    <!-- Tracker - Grupo - Chofer -->
    <section class="layout">
      <div class="grow1">
        <ng-select
          [items]="trackers"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idTracker"
          placeholder="Tracker"
          [notFoundText]="'No hay trackers disponibles'"
          style="width: 100%"
        >
        </ng-select>
      </div>
      <div class="grow1">
        <ng-select
          [items]="grupos"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idGrupo"
          placeholder="Grupo"
          style="width: 100%"
        >
        </ng-select>
      </div>
      <div class="grow1" [formGroup]="vehiculoForm">
        <ng-select
          [items]="choferes"
          bindLabel="usuario"
          bindValue="_id"
          formControlName="idChofer"
          placeholder="Chofer"
          style="width: 100%"
        >
        </ng-select>
      </div>
    </section>

    <!-- Recorridos - recorrido actual -->
    <section class="layout" [formGroup]="vehiculoForm">
      <div class="grow1">
        <ng-select
          [items]="recorridos"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idsRecorridos"
          placeholder="Recorridos"
          style="width: 100%"
          multiple="true"
        >
        </ng-select>
      </div>
      <div class="grow1">
        <ng-select
          [items]="recorridos"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idRecorrido"
          placeholder="Recorrido Actual"
          style="width: 100%"
        >
        </ng-select>
      </div>
    </section>
    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
