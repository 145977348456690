import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, AuxiliaresModule, LoginRoutingModule],
})
export class LoginModule {}
