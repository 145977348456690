<div class="container fondo-login">
  <div class="login-container mat-elevation-z4 bg-85">
    <span class="titulo-login">Gestión de vehículos</span>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>Usuario</mat-label>
        <input matInput type="text" required formControlName="usuario" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input
          matInput
          [type]="inputType"
          required
          formControlName="password"
        />
        <mat-icon
          color="accent"
          matSuffix
          (click)="onClickShowPassword()"
          *ngIf="inputType === 'password'"
          >visibility</mat-icon
        >
        <mat-icon
          color="accent"
          matSuffix
          (click)="onClickShowPassword()"
          *ngIf="inputType === 'text'"
          >visibility_off</mat-icon
        >
      </mat-form-field>
      <div class="row-center">
        <app-button
          matType="mat-fab extended"
          color="accent"
          [disabled]="!form.valid || this.loading.getLoading()"
          type="submit"
          ngClass="boton-login"
          icon="login"
          text="Ingresar"
        ></app-button>
      </div>
    </form>

    <mat-progress-bar
      color="accent"
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    >
    </mat-progress-bar>

    <!-- <mat-progress-bar color="accent" [mode]="'indeterminate'">
    </mat-progress-bar>

    <mat-progress-bar color="warn" [mode]="'indeterminate'"> </mat-progress-bar>

    <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
  </div>
</div>
