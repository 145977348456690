import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  ICreateUsuario,
  IListado,
  IQueryParam,
  IUpdateUsuario,
  IUsuario,
  Rol,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { UsuariosService } from '../../../administracion/usuarios/usuarios.service';

@Component({
    selector: 'app-crear-editar-chofer',
    templateUrl: './crear-editar-chofer.component.html',
    styleUrl: './crear-editar-chofer.component.scss',
    standalone: false
})
export class CrearEditarChoferComponent implements OnInit, OnDestroy {
  public form?: FormGroup;
  public title?: string;
  public roles: Rol[] = ['Administrador', 'Operador', 'Chofer'];
  // Sexos según Fer
  public sexos = [
    { label: 'Masculino', value: true },
    { label: 'Femenino', value: false },
  ];
  public id?: string | null;
  public usuario?: IUsuario;

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  get datosPersonales() {
    return this.form?.get('datosPersonales') as FormGroup;
  }

  constructor(
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    private service: UsuariosService,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    const rolchofer: Rol[] = ['Chofer'];
    this.form = new FormGroup({
      usuario: new FormControl(this.usuario?.usuario, Validators.required),
      identificacionInterna: new FormControl(
        this.usuario?.identificacionInterna,
      ),
      hash: new FormControl(
        this.usuario?.hash,
        !this.id ? Validators.required : null,
      ),
      roles: new FormControl(!this.id ? rolchofer : this.usuario?.roles),
      idCliente: new FormControl(this.usuario?.idCliente),
      datosPersonales: new FormGroup({
        nombre: new FormControl(this.usuario?.datosPersonales?.nombre),
        dni: new FormControl(this.usuario?.datosPersonales?.dni),
        sexo: new FormControl(this.usuario?.datosPersonales?.sexo),
        email: new FormControl(this.usuario?.datosPersonales?.email),
        direccion: new FormControl(this.usuario?.datosPersonales?.direccion),
        pais: new FormControl(this.usuario?.datosPersonales?.pais),
        telefono: new FormControl(this.usuario?.datosPersonales?.telefono),
        fechaNacimiento: new FormControl(
          this.usuario?.datosPersonales?.fechaNacimiento,
        ),
        foto: new FormControl(this.usuario?.datosPersonales?.foto),
      }),
    });
  }

  private async getUsuario() {
    if (this.id) {
      this.usuario = await this.service.getById(this.id);
      console.log(`usuario`, this.usuario);
    }
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Chofer`,
      `¿Desea ${this.title.toLowerCase()} el chofer?`,
    );
    if (!res) return;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Chofer actualizado');
        HelperService.volver();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Chofer creado');
        HelperService.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public onUrlChange(url: string) {
    this.datosPersonales.setValue({ ...this.datosPersonales.value, foto: url });
  }

  public getFoto() {
    if (this.datosPersonales?.get('foto')?.value) {
      return `url("${this.datosPersonales?.get('foto')?.value}")`;
    } else {
      return `url("https://material.angular.io/assets/img/examples/shiba1.jpg"})`;
    }
  }

  public getData(): ICreateUsuario | IUpdateUsuario {
    const data: ICreateUsuario | IUpdateUsuario = this.form?.value;
    return data;
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';

    if (this.id) {
      await this.getUsuario();
    } else {
      await this.listarClientes();
    }
    this.createForm();
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
