import { Injectable } from '@angular/core';
import {
  IActivo,
  IListado,
  IQueryParam,
  IUpdateActivo,
  ICreateActivo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ActivosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IActivo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/activos`, { params });
  }

  public getById(id: string): Promise<IActivo> {
    return this.http.get(`/activos/${id}`);
  }

  public create(dato: ICreateActivo): Promise<IActivo> {
    return this.http.post(`/activos`, dato);
  }

  public update(id: string, dato: IUpdateActivo): Promise<IActivo> {
    return this.http.put(`/activos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/activos/${id}`);
  }
}
