<section style="display: flex; height: 100%">
  <div style="width: 390px; height: 100%; margin-right: 0.5em">
    @if (vehiculo) {
      <app-controles
        [vehiculo]="vehiculo"
        [viajeSelected]="viaje"
        (viajeSelectedChange)="onViajeSelected($event)"
        [paradaSelected]="parada"
        (paradaSelectedChange)="onParadaSelected($event)"
      ></app-controles>
    }
  </div>
  <div style="width: calc(100% - 390px); height: 100%">
    @if (mapaReady) {
      <app-mapa-facil [data]="datoMapa"></app-mapa-facil>
    } @else if (error) {
      <div class="column-center" style="width: 100%; height: 100%">
        <mat-card>
          <mat-card-content> {{ error }} </mat-card-content>
        </mat-card>
      </div>
    } @else {
      @if (loading) {
        <div class="column-center" style="width: 100%; height: 100%">
          <mat-spinner></mat-spinner>
        </div>
      } @else {
        <div class="column-center" style="width: 100%; height: 100%">
          <mat-card>
            <mat-card-content>
              No hay viaje o parada selecionada
            </mat-card-content>
          </mat-card>
        </div>
      }
    }
  </div>
</section>
