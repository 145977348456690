@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ servicio?.nombre }}</span>
      } @else {
        <span class="titulo">Crear Servicio</span>
      }
    </div>
    <br />
    <div class="row-center" style="gap: 8px">
      <!-- Nombre-->
      <mat-form-field style="width: 100%">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" />
      </mat-form-field>

      <!-- Costo -->
      <mat-form-field style="width: 100%">
        <mat-label>Costo</mat-label>
        <input matInput formControlName="costo" type="number" min="0" />
        <mat-icon matSuffix>attach_money</mat-icon>
      </mat-form-field>
    </div>

    <!-- Ícono -->
    <div class="row-space-around" style="width: 100%">
      <app-upload-file
        [(url)]="urlIcono"
        textoBoton="Subir Ícono"
        (urlChange)="onUrlChange($event)"
      ></app-upload-file>
      @if (urlIcono) {
        <img [src]="urlIcono" alt="ícono" width="50px" />
      } @else {
        <span class="no-info row-center">
          Sin imágen
          <mat-icon color="warn">close</mat-icon>
        </span>
      }
    </div>

    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
