/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  IListado,
  IQueryParam,
  ISocketMessage,
  IUsuario,
  ITracker,
  ICliente,
  IReporte,
  IRecorrido,
  IGrupo,
  ICoordenadas,
  ITrackeo,
  Stop,
  Trip,
  IQueryTraccar,
  IApikey,
  ILogTrackeo,
  IDocumentacion,
  IServicio,
  IProveedor,
  INotificacion,
  IRecordatorio,
  ICronograma,
  IDespacho,
  IActivo,
  ICodigosDispositivo,
  IUbicacion,
  IConfigEventoUsuario,
  IModeloDispositivo,
  IDispositivoAlarma,
  ITipoEvento,
  IEvento,
  ITratamientoEvento,
  IFilter,
  ICategoriaActivo,
  CategoriaGrupo,
  estadoEvento,
  IServicioContratado,
  INota,
  ICategoriaEvento,
  IEventoTecnico,
  IPopulate,
} from 'modelos/src';
import { WebSocketService } from './websocket.service';
import { TrackersService } from './http/trackers.service';
import { IFiltroLastReporte, ReportesService } from './http/reportes.service';
import { TrackeosService } from './http/trackeos.service';
import { TraccarService } from './http/traccar.service';
import { ApikeysService } from '../../modulos/entidades/administracion/apikeys/apikeys.service';
import { LogTrackeosService } from './http/logTrackeos.service';
import { NotificacionesService } from '../../modulos/entidades/notificaciones/service';
import { AlarmasDomiciliariasService } from '../../modulos/entidades/alarmas/alarmas-domiciliarias/alarmas-domiciliarias.service';
import { HelperService } from './helper.service';
import { UbicacionService } from './http/ubicacion.service';
import { UsuariosService } from '../../modulos/entidades/administracion/usuarios/usuarios.service';
import { CronogramasService } from '../../modulos/entidades/colectivos/cronogramas/cronogramas.service';
import { DespachosService } from '../../modulos/entidades/colectivos/despachos/despachos.service';
import { DocumentosService } from '../../modulos/entidades/colectivos/documentos/documentos.service';
import { ProveedoresService } from '../../modulos/entidades/colectivos/proveedores/proveedores.service';
import { RecordatoriosService } from '../../modulos/entidades/colectivos/recordatorios/recordatorios.service';
import { RecorridosService } from '../../modulos/entidades/colectivos/recorridos/recorridos.service';
import { ServiciosService } from '../../modulos/entidades/colectivos/servicios/servicios.service';
import { GruposService } from './http/grupos.service';
import { ClientesService } from './http/clientes.service';
import { ActivosService } from './http/activos.service';
import { ModelosDispositivosService } from './http/modelos-dispositivos.service';
import { CodigosDispositivosService } from './http/codigos-dispositivos.service';
import { ServiciosContratadosService } from '../../modulos/entidades/administracion/servicios-contratados/servicios-contratados.service';
import { NotasService } from './http/notas.service';
import { CategoriaEventosService } from './http/categoria-eventos.service';
import { ConfigEventoUsuarioService } from './http/config-evento-usuario.service';
import { TiposEventosService } from './http/tipos-eventos.service';
import { EventosService } from './http/eventos.service';
import { TratamientoEventosService } from './http/tratamientos-eventos.service';
import { EventosTecnicosService } from '../../modulos/entidades/administracion/eventos-tecnicos/eventos-tecnicos.service';
import { UltimoReportesService } from './http/ultimo-reporte.service';
import { UltimoTrackeosService } from './http/ultimo-trackeo.service';

type Tipo =
  | IUsuario
  | IListado<IUsuario>
  | ITracker
  | IListado<ITracker>
  | IReporte
  | IListado<IReporte>
  | IRecorrido
  | IListado<IRecorrido>
  | IGrupo
  | IListado<IGrupo>
  | ITrackeo
  | IListado<ITrackeo>
  | Trip[]
  | Stop[]
  | ICliente
  | IListado<ICliente>
  | IApikey
  | IListado<IApikey>
  | ITracker
  | IListado<ITracker>
  | ILogTrackeo
  | IListado<ILogTrackeo>
  | INotificacion
  | IListado<INotificacion>
  | ICronograma
  | IListado<ICronograma>
  | IDespacho
  | IListado<IDespacho>
  | IActivo
  | IListado<IActivo>
  | ICodigosDispositivo
  | IListado<ICodigosDispositivo>
  | IUbicacion
  | IListado<IUbicacion>
  | IConfigEventoUsuario
  | IListado<IConfigEventoUsuario>
  | IModeloDispositivo
  | IListado<IModeloDispositivo>
  | IDispositivoAlarma
  | IListado<IDispositivoAlarma>
  | ITipoEvento
  | IListado<ITipoEvento>
  | IEvento
  | IListado<IEvento>
  | ITratamientoEvento
  | IListado<ITratamientoEvento>
  | IServicioContratado
  | IListado<IServicioContratado>
  | IListado<INota>
  | INota
  | IListado<INota>
  | IListado<ITratamientoEvento>
  | ICategoriaEvento
  | IListado<ICategoriaEvento>
  | IEventoTecnico
  | IListado<IEventoTecnico>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryParam) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQueryTraccar {
  fn: (query: IQueryTraccar) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQueryReportes {
  fn: (query: IFiltroLastReporte) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  clientePropio: IRequestQuery;
  reportesLastFiltered: IRequestQueryReportes;
  tratamientosEnVivo: IRequestQuery;
  reportesSnappeados: IRequestQuery;
  trips: IRequestQueryTraccar;
  stops: IRequestQueryTraccar;
  //
  usuario: IRequestId;
  usuarios: IRequestQuery;
  tracker: IRequestId;
  trackers: IRequestQuery;
  reporte: IRequestId;
  reportes: IRequestQuery;
  recorrido: IRequestId;
  recorridos: IRequestQuery;
  trackeo: IRequestId;
  trackeos: IRequestQuery;
  cliente: IRequestId;
  clientes: IRequestQuery;
  apikey: IRequestId;
  apikeys: IRequestQuery;
  logTrackeo: IRequestId;
  logTrackeos: IRequestQuery;
  documento: IRequestId;
  documentos: IRequestQuery;
  servicio: IRequestId;
  servicios: IRequestQuery;
  proveedor: IRequestId;
  proveedores: IRequestQuery;
  notificacions: IRequestQuery;
  notificacionsSinLeer: IRequestId;
  recordatorio: IRequestId;
  recordatorios: IRequestQuery;
  cronograma: IRequestId;
  cronogramas: IRequestQuery;
  despacho: IRequestId;
  despachos: IRequestQuery;
  // Alarmas
  codigosDispositivo: IRequestId;
  codigosDispositivos: IRequestQuery;
  configEventoUsuario: IRequestId;
  configEventoUsuarios: IRequestQuery;
  modeloDispositivo: IRequestId;
  modeloDispositivos: IRequestQuery;
  dispositivoAlarma: IRequestId;
  dispositivosAlarmas: IRequestQuery;
  tipoEvento: IRequestId;
  tiposEventos: IRequestQuery;
  tratamientoEvento: IRequestId;
  tratamientosEventos: IRequestQuery;
  categoriaEvento: IRequestId;
  categoriaEventos: IRequestQuery;
  // Grupos
  grupo: IRequestId;
  grupos: IRequestQuery;
  lineaColectivo: IRequestId;
  lineasColectivos: IRequestQuery;
  grupoNormal: IRequestId;
  gruposNormales: IRequestQuery;
  // Ubicacion
  ubicacion: IRequestId;
  ubicacions: IRequestQuery;
  terminal: IRequestId;
  terminals: IRequestQuery;
  // Activos
  activo: IRequestId;
  activos: IRequestQuery;
  activoNormal: IRequestId;
  activoNormals: IRequestQuery;
  vehiculo: IRequestId;
  vehiculos: IRequestQuery;
  // Eventos
  evento: IRequestId;
  eventos: IRequestQuery;
  eventoHistorico: IRequestId;
  eventosHistorico: IRequestQuery;
  eventosEnVivo: IRequestQuery;
  //
  servicioContratado: IRequestId;
  servicioContratados: IRequestQuery;
  // Notas
  nota: IRequestId;
  notas: IRequestQuery;
  // Servicio Técnico
  eventoTecnico: IRequestId;
  eventoTecnicos: IRequestQuery;
  //
  ultimoReportes: IRequestQuery;
  ultimoTrackeos: IRequestQuery;
  ultimoTrackeosPorIdActivo: IRequestId;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private webSocketService: WebSocketService,
    private usuariosService: UsuariosService,
    private trackersService: TrackersService,
    private clientesService: ClientesService,
    private reportesService: ReportesService,
    private recorridosService: RecorridosService,
    private gruposService: GruposService,
    private trackeosService: TrackeosService,
    private traccarService: TraccarService,
    private apikeysService: ApikeysService,
    private logTrackeosService: LogTrackeosService,
    private documentosService: DocumentosService,
    private serviciosService: ServiciosService,
    private proveedoresService: ProveedoresService,
    private notificacionesService: NotificacionesService,
    private recordatoriosService: RecordatoriosService,
    private cronogramasService: CronogramasService,
    private despachoService: DespachosService,
    private activosService: ActivosService,
    private codigosDispositivosService: CodigosDispositivosService,
    private ubicacionService: UbicacionService,
    private configEventoUsuarioService: ConfigEventoUsuarioService,
    private modelosDispositivosService: ModelosDispositivosService,
    private dispositivosAlarmasService: AlarmasDomiciliariasService,
    private tiposEventosService: TiposEventosService,
    private eventosService: EventosService,
    private tratamientosEventosService: TratamientoEventosService,
    private serviciosContratadosService: ServiciosContratadosService,
    private notasService: NotasService,
    private codigoEventosService: CategoriaEventosService,
    private eventoTecnicoService: EventosTecnicosService,
    private ultimoReportesService: UltimoReportesService,
    private ultimoTrackeosService: UltimoTrackeosService,
  ) {
    this.subscribeWsUpdates();
  }

  //

  // Subscribe

  public subscribe<Tipo>(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados para subscribe directo
  public listarActivos$(queryParams?: IQueryParam): Observable<IActivo[]> {
    return new Observable((observer) => {
      this.activosService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarClientes$(queryParams?: IQueryParam): Observable<ICliente[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.clientesService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarMarcas$(
    queryParams?: IQueryParam,
  ): Observable<IModeloDispositivo[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.modelosDispositivosService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarTrackers$(queryParams?: IQueryParam): Observable<ITracker[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.trackersService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarUsuarios$(queryParams?: IQueryParam): Observable<IUsuario[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.usuariosService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarRecorridos$(
    queryParams?: IQueryParam,
  ): Observable<IRecorrido[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.recorridosService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarGrupos$(queryParams?: IQueryParam): Observable<IGrupo[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.gruposService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }
  public listarCronogramas$(
    queryParams?: IQueryParam,
  ): Observable<ICronograma[]> {
    queryParams.includeChildren = true;
    return new Observable((observer) => {
      this.cronogramasService.getFiltered(queryParams).then((res) => {
        observer.next(res.datos);
      });
    });
  }

  // Listados Entidades
  private async listarClientePropio(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query: IQueryParam,
  ): Promise<ICliente> {
    // No se usa query
    const response = await this.clientesService.getPropio();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarReportesLastFiltered(
    query: IFiltroLastReporte,
  ): Promise<IReporte[]> {
    // No se usa query
    const response = await this.reportesService.getLastFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventosEnVivo(query: IQueryParam): Promise<IEvento[]> {
    // No se usa query
    const response = await this.eventosService.getVivo(query);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarTratamientosEnVivo(
    query: IQueryParam,
  ): Promise<ITratamientoEvento[]> {
    const response = await this.tratamientosEventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarReportesSnappeados(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query: IQueryParam,
  ): Promise<ICoordenadas[]> {
    // No se usa query
    const response = await this.reportesService.getReportesSnappeados(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await this.usuariosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuarios(
    query: IQueryParam,
  ): Promise<IListado<IUsuario>> {
    const response = await this.usuariosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTracker(id: string): Promise<ITracker> {
    const response = await this.trackersService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTrackers(
    query: IQueryParam,
  ): Promise<IListado<ITracker>> {
    const response = await this.trackersService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVehiculo(id: string): Promise<IActivo> {
    const response = await this.activosService.getById(id);
    if (response.categoria !== 'Vehículo') {
      throw new Error('El activo no es un vehículo');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarVehiculos(
    query: IQueryParam,
  ): Promise<IListado<IActivo>> {
    const filter: IFilter<IActivo> = HelperService.stringToObject(query.filter);
    const categoria: ICategoriaActivo = 'Vehículo';
    filter.categoria = categoria;
    query.filter = JSON.stringify(filter);
    const response = await this.activosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarActivosNormales(id: string): Promise<IActivo> {
    const response = await this.activosService.getById(id);
    if (response.categoria !== 'Normal') {
      throw new Error('No es un activo normal');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarActivosNormaless(
    query: IQueryParam,
  ): Promise<IListado<IActivo>> {
    const filter: IFilter<IActivo> = HelperService.stringToObject(query.filter);
    const categoria: ICategoriaActivo = 'Normal';
    filter.categoria = categoria;
    query.filter = JSON.stringify(filter);
    const response = await this.activosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLineaColectivo(id: string): Promise<IGrupo> {
    const response = await this.gruposService.getById(id);
    if (response.categoria !== 'Línea de colectivo') {
      throw new Error('No es una linea de colectivo');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLineasColectivos(
    query: IQueryParam,
  ): Promise<IListado<IGrupo>> {
    const filter: IFilter<IGrupo> = HelperService.stringToObject(query.filter);
    const categoria: CategoriaGrupo = 'Línea de colectivo';
    filter.categoria = categoria;
    query.filter = JSON.stringify(filter);
    const response = await this.gruposService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupoNormal(id: string): Promise<IGrupo> {
    const response = await this.gruposService.getById(id);
    if (response.categoria !== 'Normal') {
      throw new Error('No es una linea de colectivo');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGruposNormales(
    query: IQueryParam,
  ): Promise<IListado<IGrupo>> {
    const filter: IFilter<IGrupo> = HelperService.stringToObject(query.filter);
    const categoria: CategoriaGrupo = 'Normal';
    filter.categoria = categoria;
    query.filter = JSON.stringify(filter);
    const response = await this.gruposService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarReporte(id: string): Promise<IReporte> {
    const response = await this.reportesService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarReportes(
    query: IQueryParam,
  ): Promise<IListado<IReporte>> {
    const response = await this.reportesService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRecorrido(id: string): Promise<IRecorrido> {
    const response = await this.recorridosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRecorridos(
    query: IQueryParam,
  ): Promise<IListado<IRecorrido>> {
    const response = await this.recorridosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupo(id: string): Promise<IGrupo> {
    const response = await this.gruposService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupos(query: IQueryParam): Promise<IListado<IGrupo>> {
    const response = await this.gruposService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTrackeo(id: string): Promise<ITrackeo> {
    const response = await this.trackeosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTrackeos(
    query: IQueryParam,
  ): Promise<IListado<ITrackeo>> {
    const response = await this.trackeosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTrips(query: IQueryTraccar): Promise<Trip[]> {
    const response = await this.traccarService.getTrips(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarStops(query: IQueryTraccar): Promise<Stop[]> {
    const response = await this.traccarService.getStops(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCliente(id: string): Promise<ICliente> {
    const response = await this.clientesService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarClientes(
    query: IQueryParam,
  ): Promise<IListado<ICliente>> {
    const response = await this.clientesService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarApikey(id: string): Promise<ICliente> {
    const response = await this.apikeysService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarApikeys(query: IQueryParam): Promise<IListado<ICliente>> {
    const response = await this.apikeysService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogTrackeo(id: string): Promise<ILogTrackeo> {
    const response = await this.logTrackeosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogTrackeos(
    query: IQueryParam,
  ): Promise<IListado<ILogTrackeo>> {
    const response = await this.logTrackeosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDocumento(id: string): Promise<IDocumentacion> {
    const response = await this.documentosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDocumentos(
    query: IQueryParam,
  ): Promise<IListado<IDocumentacion>> {
    const response = await this.documentosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarServicio(id: string): Promise<IServicio> {
    const response = await this.serviciosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarServicios(
    query: IQueryParam,
  ): Promise<IListado<IServicio>> {
    const response = await this.serviciosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProveedor(id: string): Promise<IProveedor> {
    const response = await this.proveedoresService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProveedores(
    query: IQueryParam,
  ): Promise<IListado<IProveedor>> {
    const response = await this.proveedoresService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRecordatorio(id: string): Promise<IRecordatorio> {
    const response = await this.recordatoriosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRecordatorios(
    query: IQueryParam,
  ): Promise<IListado<IRecordatorio>> {
    const response = await this.recordatoriosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarnotificaciones(
    query: IQueryParam,
  ): Promise<IListado<INotificacion>> {
    const response = await this.notificacionesService.listar(query);
    return JSON.parse(JSON.stringify(response));
  }
  private async notificacionsSinLeer(): Promise<{
    cantidadSinLeer: number;
  }> {
    const response = await this.notificacionesService.cantidadSinLeer();
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCronograma(id: string): Promise<ICronograma> {
    const response = await this.cronogramasService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCronogramas(
    query: IQueryParam,
  ): Promise<IListado<ICronograma>> {
    const response = await this.cronogramasService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDespacho(id: string): Promise<IDespacho> {
    const response = await this.despachoService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDespachos(
    query: IQueryParam,
  ): Promise<IListado<IDespacho>> {
    const response = await this.despachoService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarActivo(id: string): Promise<IActivo> {
    const response = await this.activosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarActivos(query: IQueryParam): Promise<IListado<IActivo>> {
    const response = await this.activosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCodigosDispositivo(
    id: string,
  ): Promise<ICodigosDispositivo> {
    const response = await this.codigosDispositivosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCodigosDispositivos(
    query: IQueryParam,
  ): Promise<IListado<ICodigosDispositivo>> {
    const response = await this.codigosDispositivosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUbicacion(id: string): Promise<IUbicacion> {
    const response = await this.ubicacionService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUbicacions(
    query: IQueryParam,
  ): Promise<IListado<IUbicacion>> {
    const response = await this.ubicacionService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigEventoUsuario(
    id: string,
  ): Promise<IConfigEventoUsuario> {
    const response = await this.configEventoUsuarioService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigEventoUsuarios(
    query: IQueryParam,
  ): Promise<IListado<IConfigEventoUsuario>> {
    const response = await this.configEventoUsuarioService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarModeloDispositivo(
    id: string,
  ): Promise<IModeloDispositivo> {
    const response = await this.modelosDispositivosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarModeloDispositivos(
    query: IQueryParam,
  ): Promise<IListado<IModeloDispositivo>> {
    const response = await this.modelosDispositivosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDispositivoAlarma(
    id: string,
  ): Promise<IDispositivoAlarma> {
    const response = await this.dispositivosAlarmasService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDispositivosAlarmas(
    query: IQueryParam,
  ): Promise<IListado<IDispositivoAlarma>> {
    const response = await this.dispositivosAlarmasService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTipoEvento(id: string): Promise<ITipoEvento> {
    const response = await this.tiposEventosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTiposEventos(
    query: IQueryParam,
  ): Promise<IListado<ITipoEvento>> {
    const response = await this.tiposEventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarNota(id: string): Promise<INota> {
    const response = await this.notasService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarNotas(query: IQueryParam): Promise<IListado<INota>> {
    const response = await this.notasService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEvento(id: string): Promise<IEvento> {
    const response = await this.eventosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventos(query: IQueryParam): Promise<IListado<IEvento>> {
    const response = await this.eventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventoHistorico(id: string): Promise<IEvento> {
    const response = await this.eventosService.getById(id);
    const estado: estadoEvento = 'Finalizada';
    if (response.estado !== estado) {
      throw new Error('No es un evento finalizado');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventosHistorico(
    query: IQueryParam,
  ): Promise<IListado<IEvento>> {
    const filter: IFilter<IEvento> = HelperService.stringToObject(query.filter);
    const estado: estadoEvento = 'Finalizada';
    filter.estado = estado;
    query.filter = JSON.stringify(filter);
    const response = await this.eventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTratamientoEvento(
    id: string,
  ): Promise<ITratamientoEvento> {
    const response = await this.tratamientosEventosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTratamientosEventos(
    query: IQueryParam,
  ): Promise<IListado<ITratamientoEvento>> {
    const response = await this.tratamientosEventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTerminal(id: string): Promise<IUbicacion> {
    const response = await this.ubicacionService.getById(id);
    if (response.categoria !== 'Terminal') {
      throw new Error('La ubicación no es una terminal');
    }
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTerminals(
    query: IQueryParam,
  ): Promise<IListado<IUbicacion>> {
    const filter: IFilter<IUbicacion> = HelperService.stringToObject(
      query.filter,
    );
    filter.categoria = 'Terminal';
    query.filter = JSON.stringify(filter);
    const response = await this.ubicacionService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarServiciosContratado(
    id: string,
  ): Promise<IServicioContratado> {
    const response = await this.serviciosContratadosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarServiciosContratados(
    query: IQueryParam,
  ): Promise<IListado<IServicioContratado>> {
    const response = await this.serviciosContratadosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCodigoEvento(id: string): Promise<ICategoriaEvento> {
    const response = await this.codigoEventosService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCodigosEventos(
    query: IQueryParam,
  ): Promise<IListado<ICategoriaEvento>> {
    const response = await this.codigoEventosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventoTecnico(id: string): Promise<IEventoTecnico> {
    const response = await this.eventoTecnicoService.getById(id);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEventosTecnicos(
    query: IQueryParam,
  ): Promise<IListado<IEventoTecnico>> {
    const response = await this.eventoTecnicoService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUltimoReportes(
    query: IQueryParam,
  ): Promise<IListado<IReporte>> {
    const response = await this.ultimoReportesService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUltimoTrackeos(
    query: IQueryParam,
  ): Promise<IListado<ITrackeo>> {
    const response = await this.ultimoTrackeosService.getFiltered(query);
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUltimoTrackeosPorIdActivo(
    idActivo: string,
  ): Promise<ITrackeo> {
    const filter: IFilter<ITrackeo> = { idActivo };
    const populate: IPopulate = { path: 'recorrido', select: 'paradas' };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };
    const response = (await this.ultimoTrackeosService.getFiltered(query))
      .datos[0];
    if (response) {
      return JSON.parse(JSON.stringify(response));
    }
    return null;
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      clientePropio: { fn: this.listarClientePropio.bind(this), keys: {} },
      eventosEnVivo: {
        fn: this.listarEventosEnVivo.bind(this),
        keys: {},
      },
      tratamientosEnVivo: {
        fn: this.listarTratamientosEnVivo.bind(this),
        keys: {},
      },
      reportesSnappeados: {
        fn: this.listarReportesSnappeados.bind(this),
        keys: {},
      },
      reportesLastFiltered: {
        fn: this.listarReportesLastFiltered.bind(this),
        keys: {},
      },
      trips: { fn: this.listarTrips.bind(this), keys: {} },
      stops: { fn: this.listarStops.bind(this), keys: {} },
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      tracker: { fn: this.listarTracker.bind(this), keys: {} },
      trackers: { fn: this.listarTrackers.bind(this), keys: {} },
      vehiculo: { fn: this.listarVehiculo.bind(this), keys: {} },
      vehiculos: { fn: this.listarVehiculos.bind(this), keys: {} },
      reporte: { fn: this.listarReporte.bind(this), keys: {} },
      reportes: { fn: this.listarReportes.bind(this), keys: {} },
      recorrido: { fn: this.listarRecorrido.bind(this), keys: {} },
      recorridos: { fn: this.listarRecorridos.bind(this), keys: {} },
      grupo: { fn: this.listarGrupo.bind(this), keys: {} },
      grupos: { fn: this.listarGrupos.bind(this), keys: {} },
      trackeo: { fn: this.listarTrackeo.bind(this), keys: {} },
      trackeos: { fn: this.listarTrackeos.bind(this), keys: {} },
      cliente: { fn: this.listarCliente.bind(this), keys: {} },
      clientes: { fn: this.listarClientes.bind(this), keys: {} },
      apikey: { fn: this.listarApikey.bind(this), keys: {} },
      apikeys: { fn: this.listarApikeys.bind(this), keys: {} },
      logTrackeo: { fn: this.listarLogTrackeo.bind(this), keys: {} },
      logTrackeos: { fn: this.listarLogTrackeos.bind(this), keys: {} },
      documento: { fn: this.listarDocumento.bind(this), keys: {} },
      documentos: { fn: this.listarDocumentos.bind(this), keys: {} },
      servicio: { fn: this.listarServicio.bind(this), keys: {} },
      servicios: { fn: this.listarServicios.bind(this), keys: {} },
      proveedor: { fn: this.listarProveedor.bind(this), keys: {} },
      proveedores: { fn: this.listarProveedores.bind(this), keys: {} },
      notificacions: { fn: this.listarnotificaciones.bind(this), keys: {} },
      notificacionsSinLeer: {
        fn: this.notificacionsSinLeer.bind(this),
        keys: {},
      },
      recordatorio: { fn: this.listarRecordatorio.bind(this), keys: {} },
      recordatorios: { fn: this.listarRecordatorios.bind(this), keys: {} },
      cronograma: { fn: this.listarCronograma.bind(this), keys: {} },
      cronogramas: { fn: this.listarCronogramas.bind(this), keys: {} },
      despacho: { fn: this.listarDespacho.bind(this), keys: {} },
      despachos: { fn: this.listarDespachos.bind(this), keys: {} },
      activo: { fn: this.listarActivo.bind(this), keys: {} },
      activos: { fn: this.listarActivos.bind(this), keys: {} },
      codigosDispositivo: {
        fn: this.listarCodigosDispositivo.bind(this),
        keys: {},
      },
      codigosDispositivos: {
        fn: this.listarCodigosDispositivos.bind(this),
        keys: {},
      },
      ubicacion: { fn: this.listarUbicacion.bind(this), keys: {} },
      ubicacions: { fn: this.listarUbicacions.bind(this), keys: {} },
      configEventoUsuario: {
        fn: this.listarConfigEventoUsuario.bind(this),
        keys: {},
      },
      configEventoUsuarios: {
        fn: this.listarConfigEventoUsuarios.bind(this),
        keys: {},
      },
      modeloDispositivo: {
        fn: this.listarModeloDispositivo.bind(this),
        keys: {},
      },
      modeloDispositivos: {
        fn: this.listarModeloDispositivos.bind(this),
        keys: {},
      },
      dispositivoAlarma: {
        fn: this.listarDispositivoAlarma.bind(this),
        keys: {},
      },
      dispositivosAlarmas: {
        fn: this.listarDispositivosAlarmas.bind(this),
        keys: {},
      },
      tipoEvento: { fn: this.listarTipoEvento.bind(this), keys: {} },
      tiposEventos: { fn: this.listarTiposEventos.bind(this), keys: {} },
      evento: { fn: this.listarEvento.bind(this), keys: {} },
      eventos: { fn: this.listarEventos.bind(this), keys: {} },
      tratamientoEvento: {
        fn: this.listarTratamientoEvento.bind(this),
        keys: {},
      },
      tratamientosEventos: {
        fn: this.listarTratamientosEventos.bind(this),
        keys: {},
      },
      terminal: { fn: this.listarTerminal.bind(this), keys: {} },
      terminals: { fn: this.listarTerminals.bind(this), keys: {} },
      activoNormal: { fn: this.listarActivosNormales.bind(this), keys: {} },
      activoNormals: { fn: this.listarActivosNormaless.bind(this), keys: {} },
      lineaColectivo: { fn: this.listarLineaColectivo.bind(this), keys: {} },
      lineasColectivos: {
        fn: this.listarLineasColectivos.bind(this),
        keys: {},
      },
      grupoNormal: { fn: this.listarGrupoNormal.bind(this), keys: {} },
      gruposNormales: { fn: this.listarGruposNormales.bind(this), keys: {} },
      eventoHistorico: { fn: this.listarEventoHistorico.bind(this), keys: {} },
      eventosHistorico: {
        fn: this.listarEventosHistorico.bind(this),
        keys: {},
      },
      servicioContratado: {
        fn: this.listarServiciosContratado.bind(this),
        keys: {},
      },
      servicioContratados: {
        fn: this.listarServiciosContratados.bind(this),
        keys: {},
      },
      nota: { fn: this.listarNota.bind(this), keys: {} },
      notas: { fn: this.listarNotas.bind(this), keys: {} },
      categoriaEvento: { fn: this.listarCodigoEvento.bind(this), keys: {} },
      categoriaEventos: { fn: this.listarCodigosEventos.bind(this), keys: {} },
      eventoTecnico: { fn: this.listarEventoTecnico.bind(this), keys: {} },
      eventoTecnicos: { fn: this.listarEventosTecnicos.bind(this), keys: {} },
      ultimoReportes: { fn: this.listarUltimoReportes.bind(this), keys: {} },
      ultimoTrackeos: { fn: this.listarUltimoTrackeos.bind(this), keys: {} },
      ultimoTrackeosPorIdActivo: {
        fn: this.listarUltimoTrackeosPorIdActivo.bind(this),
        keys: {},
      },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Actualizar Entidades
  private async actualizarQuery(entidad: keyof IEntidades): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (Object.prototype.hasOwnProperty.call(ent.keys, key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          ent.keys[key].requests++;
          while (
            ent.keys[key].requests &&
            ent.keys[key].subscribe.observers.length
          ) {
            ent.keys[key].cache = undefined;
            await this.listarQuery(entidad, query, ent.fn);
            const entidadesAEsperar: (keyof IEntidades)[] = [
              'reporte',
              'reportes',
              'reportesSnappeados',
              'reportesLastFiltered',
              'ultimoReportes',
            ];
            if (entidadesAEsperar.includes(entidad)) await this.wait(10000);
            ent.keys[key].requests--;
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private async actualizarId(
    entidad: keyof IEntidades,
    id?: string,
  ): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          ent.keys[id].requests++;
          while (
            ent.keys[id].requests &&
            ent.keys[id].subscribe.observers.length
          ) {
            ent.keys[id].cache = undefined;
            await this.listarId(entidad, id, ent.fn);
            ent.keys[id].requests--;
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: keyof IEntidades,
    query: IQueryParam,
    fn: (query: IQueryParam) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key].cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
      ent.keys[key].subscribe.next(response);
    } else {
      ent.keys[key].subscribe.next(ent.keys[key].cache!);
    }
  }

  private async listarId(
    entidad: keyof IEntidades,
    id: string,
    fn: (id: string) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
      ent.keys[id].subscribe.next(response);
    } else {
      ent.keys[id].subscribe.next(ent.keys[id].cache!);
    }
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('clientes')) {
      this.actualizarQuery('clientePropio');
    }

    ///
    if (message.paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios');
      this.actualizarId('usuario', message.body?.['_id']);
    }

    if (message.paths?.includes('trackers')) {
      this.actualizarQuery('trackers');
      this.actualizarId('tracker', message.body?.['_id']);
    }

    if (message.paths?.includes('clientes')) {
      this.actualizarQuery('clientes');
      this.actualizarId('cliente', message.body?.['_id']);
    }

    if (message.paths?.includes('apikeys')) {
      this.actualizarQuery('apikeys');
      this.actualizarId('apikey', message.body?.['_id']);
    }

    if (message.paths?.includes('reportes')) {
      // const reporte = message.body as IReporte;
      this.actualizarQuery('reportes');
      this.actualizarId('reporte', message.body?.['_id']);
      this.actualizarQuery('reportesSnappeados');
      this.actualizarQuery('reportesLastFiltered');
    }

    if (message.paths?.includes('recorridos')) {
      this.actualizarQuery('recorridos');
      this.actualizarId('recorrido', message.body?.['_id']);
    }

    // Grupos
    if (message.paths?.includes('grupos')) {
      const body = message.body as IGrupo;
      this.actualizarQuery('grupos');
      this.actualizarId('grupo', message.body?.['_id']);
      if (body?.categoria === 'Línea de colectivo') {
        this.actualizarQuery('lineasColectivos');
        this.actualizarId('lineaColectivo', message.body?.['_id']);
      }
      if (body?.categoria === 'Normal') {
        this.actualizarQuery('gruposNormales');
        this.actualizarId('grupoNormal', message.body?.['_id']);
      }
    }

    if (message.paths?.includes('trackeos')) {
      this.actualizarQuery('trackeos');
      this.actualizarId('trackeo', message.body?.['_id']);
    }

    if (message.paths?.includes('logTrackeos')) {
      this.actualizarQuery('logTrackeos');
      this.actualizarId('logTrackeo', message.body?.['_id']);
    }

    if (message.paths?.includes('servicios')) {
      this.actualizarQuery('servicios');
      this.actualizarId('servicio', message.body?.['_id']);
    }

    if (message.paths?.includes('proveedores')) {
      this.actualizarQuery('proveedores');
      this.actualizarId('proveedor', message.body?.['_id']);
    }
    if (message.paths?.includes('notificaciones')) {
      this.actualizarQuery('notificacions');
      this.actualizarId('notificacionsSinLeer', '0');
    }
    if (message.paths?.includes('recordatorios')) {
      this.actualizarQuery('recordatorios');
      this.actualizarId('recordatorio', message.body?.['_id']);
    }
    if (message.paths?.includes('cronogramas')) {
      this.actualizarQuery('cronogramas');
      this.actualizarId('cronograma', message.body?.['_id']);
    }
    if (message.paths?.includes('despachos')) {
      this.actualizarQuery('despachos');
      this.actualizarId('despacho', message.body?.['_id']);
    }
    if (message.paths?.includes('activos')) {
      const body = message.body as IActivo;
      this.actualizarQuery('activos');
      this.actualizarQuery('reportesLastFiltered');
      this.actualizarQuery('reportes');
      this.actualizarId('reporte', message.body?.['_id']);
      this.actualizarQuery('reportesSnappeados');
      this.actualizarId('activo', message.body?.['_id']);

      if (body.categoria === 'Vehículo') {
        this.actualizarQuery('vehiculos');
        this.actualizarId('vehiculo', message.body?.['_id']);
      }
      if (body.categoria === 'Normal') {
        this.actualizarQuery('activoNormals');
        this.actualizarId('activoNormal', message.body?.['_id']);
      }
    }
    if (message.paths?.includes('codigosdispositivos')) {
      this.actualizarQuery('codigosDispositivos');
      this.actualizarId('codigosDispositivo', message.body?.['_id']);
    }
    if (message.paths?.includes('ubicacions')) {
      const body = message.body as IUbicacion;
      this.actualizarQuery('ubicacions');
      this.actualizarId('ubicacion', message.body?.['_id']);

      if (body.categoria === 'Terminal') {
        this.actualizarQuery('terminals');
        this.actualizarId('terminal', message.body?.['_id']);
      }
    }
    if (message.paths?.includes('configeventousuarios')) {
      this.actualizarQuery('configEventoUsuarios');
      this.actualizarId('configEventoUsuario', message.body?.['_id']);
    }
    if (message.paths?.includes('modelodispositivos')) {
      this.actualizarQuery('modeloDispositivos');
      this.actualizarId('modeloDispositivo', message.body?.['_id']);
    }
    if (message.paths?.includes('dispositivoalarmas')) {
      this.actualizarQuery('dispositivosAlarmas');
      this.actualizarId('dispositivoAlarma', message.body?.['_id']);
    }
    if (message.paths?.includes('tiposeventos')) {
      this.actualizarQuery('tiposEventos');
      this.actualizarId('tipoEvento', message.body?.['_id']);
    }
    // Eventos
    if (message.paths?.includes('eventos')) {
      const body = message.body as IEvento;
      this.actualizarQuery('eventosEnVivo');
      this.actualizarQuery('eventos');
      this.actualizarId('evento', message.body?.['_id']);
      if (body.estado === 'Finalizada') {
        this.actualizarQuery('eventosHistorico');
        this.actualizarId('eventoHistorico', message.body?.['_id']);
      }
    }
    if (message.paths?.includes('tratamientoeventos')) {
      this.actualizarQuery('tratamientosEnVivo');
      this.actualizarQuery('tratamientosEventos');
      this.actualizarId('tratamientoEvento', message.body?.['_id']);
    }
    if (message.paths?.includes('servicioContratados')) {
      this.actualizarQuery('servicioContratados');
      this.actualizarId('servicioContratado', message.body?.['_id']);
    }
    if (message.paths?.includes('notas')) {
      this.actualizarQuery('notas');
      this.actualizarId('nota', message.body?.['_id']);
    }
    if (message.paths?.includes('categoriaeventos')) {
      this.actualizarQuery('categoriaEventos');
      this.actualizarId('categoriaEvento', message.body?.['_id']);
    }
    if (message.paths?.includes('documentos')) {
      this.actualizarQuery('documentos');
      this.actualizarId('documento', message.body?.['_id']);
    }
    if (message.paths?.includes('eventotecnicos')) {
      this.actualizarQuery('eventoTecnicos');
      this.actualizarId('eventoTecnico', message.body?.['_id']);
    }
    if (message.paths?.includes('ultimoreportes')) {
      this.actualizarQuery('ultimoReportes');
    }
    if (message.paths?.includes('ultimotrackeos')) {
      const body = message.body as ITrackeo;
      this.actualizarQuery('ultimoTrackeos');
      if (body.idActivo) {
        this.actualizarId('ultimoTrackeosPorIdActivo', body.idActivo);
      }
    }
  }
}
