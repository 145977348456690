import { Injectable } from '@angular/core';
import {
  ICreateUsuario,
  IProveedor,
  IListado,
  IQueryParam,
  IUpdateUsuario,
} from 'modelos/src';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ProveedoresService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IProveedor>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/proveedores`, { params });
  }

  public getById(id: string): Promise<IProveedor> {
    return this.http.get(`/proveedores/${id}`);
  }

  public create(dato: ICreateUsuario): Promise<IProveedor> {
    return this.http.post(`/proveedores`, dato);
  }

  public update(id: string, dato: IUpdateUsuario): Promise<IProveedor> {
    return this.http.put(`/proveedores/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/proveedores/${id}`);
  }
}
