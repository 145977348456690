import { v } from './version';

export const environment = {
  production: true,
};
export const ENV = 'test';
export const VERSION = v;
export const NOMBRE_APP = 'Gestión de vehículos';
export const PAIS = 'ar';
/// FIREBASE
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBpLrX1jvf3ChZUc-wpJA1i6u8X0D9RmJw',
  authDomain: 'gestion-8b298.firebaseapp.com',
  projectId: 'gestion-8b298',
  storageBucket: 'gestion-8b298.appspot.com',
  messagingSenderId: '784969237391',
  appId: '1:784969237391:web:acd67cac48b5e889ca01fa',
  measurementId: 'G-TBBGWJPV9T',
};
export const VAPID_KEY =
  'BBSjvXxNMzMhFwlr0GI9-AorxiURX9DZprE3aTyIEZPfd9UK_qb53OAHu-JJ3AolbZJ415jwmo7KJtyQo-QcqHM';
// Apis
// Test
export const API =
  'https://apis2.novit.gpesistemas.ar/gestion-api-gestion-test';
export const websocketServer =
  'wss://apis2.novit.gpesistemas.ar/gestion-api-websocket-test';
