import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IGrupo,
  ICreateGrupo,
  IUpdateGrupo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class GruposService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IGrupo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/grupos`, { params });
  }

  public getById(id: string): Promise<IGrupo> {
    return this.http.get(`/grupos/${id}`);
  }

  public create(dato: ICreateGrupo): Promise<IGrupo> {
    return this.http.post(`/grupos`, dato);
  }

  public update(id: string, dato: IUpdateGrupo): Promise<IGrupo> {
    return this.http.put(`/grupos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/grupos/${id}`);
  }
}
