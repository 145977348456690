<app-tabla
  [totalCount]="totalCount"
  [datos]="alarmasDomiciliarias"
  [columnas]="columnas"
  [name]="name"
  [pageSizeOptions]="helper.pageSizeOptions"
  [search]="search"
  [(query)]="query"
  (queryChange)="listarTodo()"
  [paginatorArriba]="false"
  [crear]="botonCrear"
  [filtros]="filtros"
></app-tabla>
