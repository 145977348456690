@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>
          <span class="titulo">Crear Técnico</span>
        </h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content>
        <div class="row-center" style="width: 100%; gap: 8px">
          <!-- Usuario -->
          <mat-form-field style="width: 100%">
            <mat-label>Usuario</mat-label>
            <input matInput formControlName="usuario" />
          </mat-form-field>
          <!-- Contraseña -->
          <mat-form-field style="width: 100%">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="hash" />
          </mat-form-field>
        </div>
        <div class="row-center" style="width: 100%; gap: 8px">
          <!-- Identificación -->
          <mat-form-field style="width: 100%">
            <mat-label>Identificación</mat-label>
            <input matInput formControlName="identificacionInterna" />
          </mat-form-field>
        </div>
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          mat-dialog-close
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading()"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
