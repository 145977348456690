import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  INota,
  IPopulate,
  IQueryParam,
  IFilter,
  IListado,
  TipoNota,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { NotasService } from '../../../../auxiliares/servicios/http/notas.service';
import { IRegExpSearch } from '../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../auxiliares/tabla/tabla.component';
import { CrearEditarNotaComponent } from '../crear-editar-nota/crear-editar-nota.component';
import { DetallesNotaComponent } from '../detalles-nota/detalles-nota.component';

@Component({
    selector: 'app-listado-notas',
    templateUrl: './listado-notas.component.html',
    styleUrl: './listado-notas.component.scss',
    imports: [CommonModule, AuxiliaresModule]
})
export class ListadoNotasComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  @Input() public idAsignado?: string;
  @Input() public tipo?: TipoNota;
  @Input() public tipoDispositivo?: string;
  public name = ListadoNotasComponent.name;
  public notas: INota[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<INota>[];
  public botonCrear: ICrearBoton = {
    mostrar: !this.helper.esClienteFinal(),
    tooltip: 'Crear Nota',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['tipo'],
  };

  //Subs
  public notas$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //

  // FILTROS

  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'alarma',
    },
    {
      path: 'activo',
    },
  ];
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
    public dialog: MatDialog,
    private service: NotasService,
    private dialogService: DialogService,
  ) {}

  private puedeMostrarBotonCrear(): boolean {
    if (this.helper.puedeEditar()) {
      if (
        this.tipoDispositivo === 'Activo' &&
        HelperService.getConfigCliente().moduloActivos?.crearDispositivos
      ) {
        return true;
      }
      if (
        this.tipoDispositivo === 'Alarma' &&
        HelperService.getConfigCliente().moduloAlarmasDomiciliarias
          ?.crearDispositivos
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar()]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    let filter: IFilter<INota>;
    if (this.query.filter) {
      filter = JSON.parse(this.query.filter);
    } else {
      filter = {};
    }
    filter.idAsignado = this.idAsignado;
    filter.tipo = this.tipo;
    this.query.filter = JSON.stringify(filter);
    this.notas$?.unsubscribe();
    this.notas$ = this.listados
      .subscribe<IListado<INota>>('notas', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.notas = data.datos;
        console.log(`listado de notas`, data);
      });
    await this.listados.getLastValue('notas', this.query);
  }
  // Acciones

  public crear() {
    const config: MatDialogConfig = {
      data: {
        idEntidad: this.idAsignado,
        tipoDispositivo: this.tipoDispositivo,
        tipo: this.tipo,
      },
      minWidth: '600px',
      disableClose: true,
    };

    this.dialog.open(CrearEditarNotaComponent, config);
  }

  public async editar(nota: INota) {
    const config: MatDialogConfig = {
      data: {
        nota: nota,
        idEntidad: this.idAsignado,
        tipo: this.tipo,
        tipoDispositivo: this.tipoDispositivo,
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarNotaComponent, config);
  }
  public async detalles(nota: INota) {
    this.dialog.open(DetallesNotaComponent, {
      data: nota.informacion,
      minWidth: '600px',
    });
  }

  public colorVigencia(desde: string, hasta: string): string {
    const start = new Date(desde);
    const end = new Date(hasta);
    const today = new Date();
    if (today >= start && today <= end) return 'green';
    else return 'red';
  }

  public async eliminar(nota: INota) {
    const res = await this.dialogService.confirm(
      'Eliminar Nota',
      `¿Desea eliminar el nota?`,
    );
    if (!res) return;
    try {
      await this.service.delete(nota._id!);
      this.helper.notifSuccess('Nota eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<INota>[] = [
      // Permanente
      {
        header: {
          label: 'Permanente',
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.permanente ? iconCheck : iconClose;
          },
        },
      },
      // Vigencia
      {
        header: {
          label: 'Vigencia',
        },
        row: {
          html(dato) {
            if (dato.vigenciaHasta) {
              const start = new Date(dato.vigenciaDesde);
              const end = new Date(dato.vigenciaHasta);
              const today = new Date();
              let color;
              today >= start && today <= end
                ? (color = 'green')
                : (color = 'red');
              return `<span style="color:${color}"> ${new Date(
                dato.vigenciaDesde,
              ).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })} --- ${new Date(dato.vigenciaHasta).toLocaleDateString(
                'es-ES',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },
              )} </span>`;
            }
            return '';
          },
          noData: 'Sin Vigencia',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }
    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    if (this.tipo === 'Contacto') {
      const columnaContacto: IColumnas<INota>[] = [
        {
          header: {
            label: 'Contacto',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato?.informacion?.contacto;
            },
            noData: 'Sin Contacto',
          },
        },
      ];
      this.columnas = columnaContacto.concat(this.columnas);
    }
    if (!this.tipo) {
      const columnaTipo: IColumnas<INota>[] = [
        {
          header: {
            label: 'Tipo',
            sortable: true,
          },
          row: {
            field: 'tipo',
          },
        },
      ];
      this.columnas = columnaTipo.concat(this.columnas);
    }
    await this.listarTodo();
  }

  async ngOnDestroy() {
    this.notas$?.unsubscribe();
  }
}
