import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  ILogTrackeo,
  ICreateLogTrackeo,
  IUpdateLogTrackeo,
  ICoordenadas,
} from 'modelos/src';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class LogTrackeosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ILogTrackeo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logtrackeos`, { params });
  }

  public getLogTrackeosSnappeados(
    queryParams?: IQueryParam,
  ): Promise<ICoordenadas[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logtrackeos/snapped`, { params });
  }

  public getLastByVehiculo(queryParams: IQueryParam): Promise<ILogTrackeo[]> {
    const params = HelperService.getQueryParams({
      queryParams,
    });
    return this.http.get(`/logtrackeos/getLastByVehiculo`, {
      params,
    });
  }

  public getById(id: string): Promise<ILogTrackeo> {
    return this.http.get(`/logtrackeos/${id}`);
  }

  public create(dato: ICreateLogTrackeo): Promise<ILogTrackeo> {
    return this.http.post(`/logtrackeos`, dato);
  }

  public update(id: string, dato: IUpdateLogTrackeo): Promise<ILogTrackeo> {
    return this.http.put(`/logtrackeos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/logtrackeos/${id}`);
  }
}
