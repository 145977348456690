@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información del cliente</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Nombre:</span>
      <span class="dato">{{ cliente?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px"
        >Numero de Cliente:</span
      >
      <span class="dato">{{ cliente?.numeroCliente }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Fecha de Alta:</span>
      <span class="dato">{{ cliente?.fechaCreacion | date: "short" }}</span>
    </div>
  </div>
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Tipo de Cliente:</span>
      <span class="dato">{{ cliente?.tipoCliente }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Servicios:</span>
    </div>
    <div align="center">
      <app-mostrar-servicios
        [cliente]="cliente"
        [size]="20"
        [gap]="0"
        [linea]="true"
      ></app-mostrar-servicios>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Estado de Cuenta:</span>
      <span class="dato">{{ cliente?.estadoDeCuenta }}</span>
    </div>
  </div>
}
