import { Injectable } from '@angular/core';
import {
  ICreateUsuario,
  IServicio,
  IListado,
  IQueryParam,
  IUpdateUsuario,
} from 'modelos/src';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ServiciosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IServicio>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/servicios`, { params });
  }

  public getById(id: string): Promise<IServicio> {
    return this.http.get(`/servicios/${id}`);
  }

  public create(dato: ICreateUsuario): Promise<IServicio> {
    return this.http.post(`/servicios`, dato);
  }

  public update(id: string, dato: IUpdateUsuario): Promise<IServicio> {
    return this.http.put(`/servicios/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/servicios/${id}`);
  }
}
