import { Injectable } from '@angular/core';
import { HttpClientService } from '../http.service';
import { IQueryTraccar, Stop, Trip } from 'modelos/src';
import { HelperService } from '../helper.service';

@Injectable({ providedIn: 'root' })
export class TraccarService {
  constructor(private http: HttpClientService) {}

  public getTrips(query?: IQueryTraccar): Promise<Trip[]> {
    const params = HelperService.getQueryGenerico(query);
    return this.http.get(`/traccar/trips`, { params });
  }

  public getStops(query?: IQueryTraccar): Promise<Stop[]> {
    const params = HelperService.getQueryGenerico(query);
    return this.http.get(`/traccar/stops`, { params });
  }

  public updateTotalDistanceAndHoursOfTheDevice(body: {
    deviceId: number;
    totalDistance: number;
    hours: number;
  }): Promise<void> {
    return this.http.put(
      `/traccar/updateTotalDistanceAndHoursOfTheDevice`,
      body,
    );
  }
}
