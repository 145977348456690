import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ListadoNotificacionesComponent } from './listado-notificaciones/listado-notificaciones.component';
import { ConfigurarNotificacionesComponent } from './configurar-notificaciones/configurar-notificaciones.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [
    NotificacionesComponent,
    ListadoNotificacionesComponent,
    ConfigurarNotificacionesComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    MatMenuModule,
    MatPaginatorModule,
    MatBadgeModule,
  ],
  exports: [NotificacionesComponent, ListadoNotificacionesComponent],
})
export class NotificacionesModule {}
