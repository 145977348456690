import {
  IParamsService,
  ParamsService,
} from './../../../../auxiliares/servicios/params.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  IConfigEventoUsuario,
  IDispositivoAlarma,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  ITracker,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { CrearEditarConfigEventoComponent } from '../crear-editar-config-evento/crear-editar-config-evento.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../auxiliares/tabla/tabla.component';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { IRegExpSearch } from '../../../../auxiliares/tabla/filtro/filtro.component';
import { LoginService } from '../../../login/login.service';
import { ConfigEventoUsuarioService } from '../../../../auxiliares/servicios/http/config-evento-usuario.service';

@Component({
    imports: [CommonModule, AuxiliaresModule],
    selector: 'app-listado-configs-eventos',
    templateUrl: './listado-configs-eventos.component.html'
})
export class ListadoConfigsEventosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  @Input() public dispositivo: IDispositivoAlarma | IActivo | ITracker;
  @Input() public tipo?: 'Alarma' | 'Activo' | 'Tracker';
  @Input() public botonCrearMostrar?: boolean;
  public name = ListadoConfigsEventosComponent.name;
  public configsEventos: IConfigEventoUsuario[] = [];
  public configsEventos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IConfigEventoUsuario>[];
  public botonCrear: ICrearBoton = {
    mostrar: false,
    tooltip: 'Crear Tipo de Evento',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crearConfigEvento(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS

  public filter: IFilter<IConfigEventoUsuario> = {
    codigoReportado: { $exists: true },
  };
  public populate: IPopulate[] = [
    {
      path: 'tracker',
      select: 'nombre',
    },
    {
      path: 'tipoEvento',
      select: 'nombre',
    },
    {
      path: 'categoriaEvento',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ConfigEventoUsuarioService,
    public dialog: MatDialog,
    private paramsService: ParamsService,
  ) {}

  public async listar(): Promise<void> {
    let filter: IFilter<IConfigEventoUsuario> = {};
    if (this.query.filter) {
      filter = JSON.parse(this.query.filter);
    }
    if (this.tipo === 'Alarma') {
      filter.idEntidad = (this.dispositivo as IDispositivoAlarma)._id;
    } else if (this.tipo === 'Activo') {
      filter.idEntidad = (this.dispositivo as IActivo)?.idTracker;
    } else {
      filter.idEntidad = (this.dispositivo as ITracker)?._id;
    }
    this.query.filter = JSON.stringify(filter);
    //
    this.configsEventos$?.unsubscribe();
    this.configsEventos$ = this.listados
      .subscribe<
        IListado<IConfigEventoUsuario>
      >('configEventoUsuarios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.configsEventos = data.datos;
        console.log(`listado de configEventoUsuarios`, data);
      });
    await this.listados.getLastValue('configEventoUsuarios', this.query);
  }
  // Acciones
  public crearConfigEvento() {
    const params: IParamsService = {
      dispositivo: this.dispositivo,
    };
    this.paramsService.setParams(params);
    const config: MatDialogConfig = {
      disableClose: true,
      width: '700px',
    };
    if (this.tipo === 'Alarma') {
      config.data = { alarma: this.dispositivo };
      this.dialog.open(CrearEditarConfigEventoComponent, config);
    } else {
      config.data = { activo: this.dispositivo };
      this.dialog.open(CrearEditarConfigEventoComponent, config);
    }
  }

  public async editar(configEvento: IConfigEventoUsuario) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '700px',
    };
    if (this.tipo === 'Alarma') {
      config.data = {
        configEvento: configEvento,
        alarma: this.dispositivo,
      };
      this.dialog.open(CrearEditarConfigEventoComponent, config);
    } else {
      config.data = {
        configEvento: configEvento,
        activo: this.dispositivo,
      };
      this.dialog.open(CrearEditarConfigEventoComponent, config);
    }
  }

  public async eliminar(configevento: IConfigEventoUsuario) {
    const res = await this.dialogService.confirm(
      'Confirme la Acción',
      `¿Desea eliminar el codigo personalizado ${configevento?.tipoEvento?.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(configevento._id!);
      this.helper.notifSuccess('codigo personalizado eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Tipo Evento',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato?.tipoEvento?.nombre;
          },
        },
      },
      {
        header: {
          label: 'Categoría',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato?.categoriaEvento?.nombre;
          },
          noData: 'Sin Categoría',
        },
      },
      {
        header: {
          label: 'Área',
          sortable: true,
        },
        row: {
          parse(dato) {
            let text = '';
            if (dato?.configZona?.zona)
              text += `Partición: ${dato?.configZona?.zona} `;
            if (dato?.configZona?.particion)
              text += `Zona: ${dato?.configZona?.particion}`;
            return text;
          },
          noData: 'Sin Zona',
        },
      },
      {
        header: {
          label: 'Notificación Push',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.notificar ? iconCheck : iconClose;
          },
        },
      },
      {
        header: {
          label: 'No Derivar',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.noDerivar ? iconCheck : iconClose;
          },
        },
      },

      {
        header: {
          label: 'Requiere Atención',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.atender ? iconCheck : iconClose;
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled() {
                return LoginService.getUsuario()?._id === 'admin';
              },
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (this.botonCrearMostrar === false) this.botonCrear.mostrar = false;
    else this.botonCrear.mostrar = true;
    this.setColumnas();
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.configsEventos$?.unsubscribe();
  }
}
