import { Injectable } from '@angular/core';
import {
  ITipoEvento,
  IListado,
  IQueryParam,
  IUpdateTipoEvento,
  ICreateTipoEvento,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class TiposEventosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ITipoEvento>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/tiposeventos`, { params });
  }

  public getById(id: string): Promise<ITipoEvento> {
    return this.http.get(`/tiposeventos/${id}`);
  }

  public create(dato: ICreateTipoEvento): Promise<ITipoEvento> {
    return this.http.post(`/tiposeventos`, dato);
  }

  public update(id: string, dato: IUpdateTipoEvento): Promise<ITipoEvento> {
    return this.http.put(`/tiposeventos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/tiposeventos/${id}`);
  }
}
