import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, inject, provideAppInitializer } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuxiliaresModule } from './auxiliares/auxiliares.module';


// Locale

import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ThemesService } from './auxiliares/servicios/themes.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './auxiliares/interceptors/loading.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { API, VERSION, environment } from '../environments/environment';
import { PwaService } from './auxiliares/servicios/pwa.service';
registerLocaleData(localeEs, 'es-AR');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //
    AuxiliaresModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideAnimationsAsync(),
    ThemesService,
    provideAppInitializer(() => {
        const initializerFn = ((themeService: ThemesService) => () => themeService.init())(inject(ThemesService));
        return initializerFn();
      }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public pwa: PwaService) {
    console.log(`Versión: ${VERSION}`);
    console.log(`API: ${API}`);
  }
}
