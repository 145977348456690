import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  ICreateReporte,
  IUpdateReporte,
  ITrackeo,
} from 'modelos/src';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class TrackeosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<ITrackeo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/trackeos`, { params });
  }

  public getById(id: string): Promise<ITrackeo> {
    return this.http.get(`/trackeos/${id}`);
  }

  public create(dato: ICreateReporte): Promise<ITrackeo> {
    return this.http.post(`/trackeos`, dato);
  }

  public update(id: string, dato: IUpdateReporte): Promise<ITrackeo> {
    return this.http.put(`/trackeos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/trackeos/${id}`);
  }
}
