import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoActivosComponent } from './activos/listado-activos/listado-activos.component';
import { CrearEditarActivoComponent } from './activos/crear-editar-activo/crear-editar-activo.component';
import { MapaActivosComponent } from './mapa-activos/mapa-activos.component';
import { DetallesActivoComponent } from './activos/detalles-activo/detalles-activo.component';
import { ListadoGruposComponent } from '../../standalone/grupos/listado-grupos/listado-grupos.component';
import { ListadoUbicacionesComponent } from '../../standalone/ubicaciones/listado-ubicaciones/listado-ubicaciones.component';
import { CrearEditarUbicacionComponent } from '../../standalone/ubicaciones/crear-editar-ubicacion/crear-editar-ubicacion.component';

const routes: Routes = [
  { path: '', redirectTo: 'mapa', pathMatch: 'full' },
  { path: 'activos', component: ListadoActivosComponent, canActivate: [] },
  // Activos
  {
    path: 'activos/crear',
    component: CrearEditarActivoComponent,
    canActivate: [],
  },
  {
    path: 'activos/editar/:id',
    component: CrearEditarActivoComponent,
    canActivate: [],
  },
  {
    path: 'activos/detalles/:id',
    component: DetallesActivoComponent,
    canActivate: [],
  },
  // Mapa
  {
    path: 'mapa',
    component: MapaActivosComponent,
    canActivate: [],
  },
  // Ubicaciones
  {
    path: 'ubicaciones/:categoria',
    component: ListadoUbicacionesComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/crear',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/editar/:id',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  // Agrupaciones
  {
    path: 'grupos/:categoria',
    component: ListadoGruposComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivosRoutingModule {}
