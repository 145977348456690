import { Component, computed, inject, Signal, signal } from '@angular/core';
import {
  AlarmasDomiciliariasService,
  DatosConfigNanocomm,
} from '../alarmas-domiciliarias/alarmas-domiciliarias.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDispositivoAlarma, ISim } from 'modelos/src';
import { asapScheduler } from 'rxjs';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Component({
    selector: 'app-configurar-comunicador',
    templateUrl: './configurar-comunicador.component.html',
    styleUrl: './configurar-comunicador.component.scss',
    standalone: false
})
export class ConfigurarComunicadorComponent {
  public loading = signal(false);
  public valid = signal(false);
  private regex = /^(?!0|15)\d{10}$/;

  public data = inject(MAT_DIALOG_DATA) as IDispositivoAlarma;
  public dialogRef = inject(MatDialogRef);
  public datos?: DatosConfigNanocomm;

  public selected = signal<ISim | undefined>(undefined);

  public sims: ISim[] = [];

  public mensajePreview: Signal<string | undefined> = computed(() => {
    this.datos.datos.apn = this.selected()?.apn;
    this.datos.datos.pwd = this.selected()?.password;
    this.datos.datos.usr = this.selected()?.usuario;
    this.datos.telefono = this.selected()?.numero;
    if (this.data.comunicador.formatoMensaje === 'Nanocomm ED5200') {
      this.datos.datos.modelo = 'ED5200';
    }

    asapScheduler.schedule(() => this.valid.set(this.validar()));

    return this.armarString(this.selected());
  });

  public error = signal<string | undefined>(undefined);

  constructor(
    private service: AlarmasDomiciliariasService,
    private dialog: DialogService,
    private helper: HelperService,
  ) {
    this.sims.push(this.data.sim1);
    this.sims.push(this.data.sim2);

    this.datos = {
      datos: {
        idComunicador: this.data.idUnicoComunicador,
        passComunicador: this.data.passwordComunicador,
        apn: null,
        pwd: null,
        usr: null,
        modelo: null,
      },
      telefono: null,
    };
  }

  private armarString(sim: ISim): string | undefined {
    if (!sim) return undefined;
    if (this.data.comunicador.formatoMensaje === 'Nanocomm ED5200') {
      return `ED5200*${this.data.passwordComunicador},ID=${this.data.idUnicoComunicador},${sim.apn}=${sim.usuario},PWD=${sim.password},IP=123.456.789.000,PORT=1337,,,`;
    } else {
      return 'Formato no soportado';
    }
  }

  private validar() {
    if (!this.selected()) {
      this.error.set('Debe seleccionar un SIM');
      return false;
    }
    if (
      !this.datos.datos.apn ||
      !this.datos.datos.pwd ||
      !this.datos.datos.usr
    ) {
      this.error.set('Debe completar los campos de APN, usuario y contraseña');
      return false;
    }
    if (!this.datos.telefono) {
      this.error.set('Debe completar el campo de teléfono');
      return false;
    }
    if (!this.regex.test(this.datos.telefono)) {
      this.error.set('El teléfono es inválido');
      return false;
    }
    if (!this.datos.datos.idComunicador) {
      this.error.set('Debe completar el campo de ID del comunicador');
      return false;
    }
    if (!this.datos.datos.passComunicador) {
      this.error.set('Se requiere la contraseña del comunicador');
      return false;
    }
    this.error.set(undefined);
    return true;
  }

  public async enviarConfig() {
    const res = await this.dialog.confirm(
      'Confirmar envío',
      '¿Está seguro de enviar la configuración?',
    );
    if (!res) return;
    this.loading.set(true);
    try {
      await this.service.enviarSMSNanocomm(this.datos);
      this.helper.notifSuccess('Configuración enviada');
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    } finally {
      this.loading.set(false);
    }
  }
}
