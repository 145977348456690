@if (servicios.length > 0) {
  <div style="width: 100%; height: 100%">
    @if (linea) {
      <section [ngStyle]="getStyleLinea()">
        @for (s of servicios; track $index) {
          <div [matTooltip]="s.nombre">
            <img [src]="s.icono" alt="Icono" height="100%" width="100%" />
          </div>
        }
      </section>
    } @else {
      <section [ngStyle]="getStyleGrid()">
        @for (s of servicios; track $index) {
          <div [matTooltip]="s.nombre">
            <img [src]="s.icono" alt="Icono" height="100%" width="100%" />
          </div>
        }
      </section>
    }
  </div>
} @else {
  <div>No hay servicios asignados a este cliente</div>
}
