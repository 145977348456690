import { Injectable } from '@angular/core';
import {
  ICodigosDispositivo,
  ICreateCodigosDispositivo,
  IListado,
  IQueryParam,
  IUpdateCodigosDispositivo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class CodigosDispositivosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ICodigosDispositivo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/codigosdispositivos`, { params });
  }

  public getById(id: string): Promise<ICodigosDispositivo> {
    return this.http.get(`/codigosdispositivos/${id}`);
  }

  public create(dato: ICreateCodigosDispositivo): Promise<ICodigosDispositivo> {
    return this.http.post(`/codigosdispositivos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateCodigosDispositivo,
  ): Promise<ICodigosDispositivo> {
    return this.http.put(`/codigosdispositivos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/codigosdispositivos/${id}`);
  }
}
