import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoriaGrupo, IGrupo } from 'modelos/src';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { GruposService } from '../../../../auxiliares/servicios/http/grupos.service';

@Component({
    imports: [CommonModule, AuxiliaresModule],
    selector: 'app-crear-editar-grupos',
    templateUrl: './crear-editar-grupos.component.html',
    styleUrl: './crear-editar-grupos.component.scss'
})
export class CrearEditarAgrupacionesComponent implements OnInit {
  public loading = false;
  public form: FormGroup;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { edit: IGrupo; categoria: CategoriaGrupo },
    public dialogRef: MatDialogRef<CrearEditarAgrupacionesComponent>,
    private helper: HelperService,
    private service: GruposService,
  ) {}

  private crearForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.data?.edit?.nombre, [Validators.required]),
      color: new FormControl(this.data?.edit?.color),
      categoria: new FormControl(
        this.data?.edit?.categoria || this.data?.categoria,
        [Validators.required],
      ),
    });
  }

  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.form.value;
      if (this.data?.edit?._id) {
        await this.service.update(this.data.edit._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  async ngOnInit() {
    this.title = this.data.edit?._id
      ? `Editar grupo ${this.data.categoria} `
      : `Crear grupo ${this.data.categoria}`;

    this.crearForm();
  }
}
