import { Component, Input, OnDestroy } from '@angular/core';
import {
  ICoordenadas,
  IFilter,
  IQueryParam,
  IReporte,
  Stop,
  Trip,
  IActivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Coordinate } from 'ol/coordinate';
import {
  LineStringMapa,
  MapaFacilData,
  MarkerMapa,
} from '../../../../../../auxiliares/mapa-facil/interfaces';
import { ListadosService } from '../../../../../../auxiliares/servicios/listados.service';
import { ReportesService } from '../../../../../../auxiliares/servicios/http/reportes.service';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { OpenLayersService } from '../../../../../../auxiliares/servicios/openLayers.service';

@Component({
    selector: 'app-viajes-paradas',
    templateUrl: './viajes-paradas.component.html',
    styleUrl: './viajes-paradas.component.scss',
    standalone: false
})
export class ViajesParadasComponent implements OnDestroy {
  public loading = false;

  @Input() vehiculo?: IActivo;
  public viaje?: Trip;
  public parada?: Stop;

  public reportesSnappeados: ICoordenadas[] = [];
  public reportes$?: Subscription;

  public datoMapa?: MapaFacilData;
  public mapaReady = false;
  public error?: string;

  constructor(
    private listados: ListadosService,
    private reportesService: ReportesService,
    private helper: HelperService,
  ) {}

  public async listarViaje() {
    this.loading = true;
    if (!this.vehiculo) return;
    const desde = this.viaje?.startTime;
    const hasta = this.viaje?.endTime;
    if (!desde || !hasta) return;
    const filter: IFilter<IReporte> = {
      idActivo: this.vehiculo._id,
      fechaCreacion: { $gte: desde, $lte: hasta },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: JSON.stringify({ fechaCreacion: -1 }),
      limit: 0,
    };

    try {
      const data = await this.reportesService.getReportesSnappeados(query);
      this.reportesSnappeados = data;
      this.datoMapa = this.armarViaje();
      console.log(`listado de reportesSnappeados`, data);
      this.loading = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.helper.notifError(
        `Error al listar reportes del viaje: ${error?.error?.message}`,
      );
      console.error(error);
    }

    // Con listados service
    // this.reportes$?.unsubscribe();
    // this.reportes$ = this.listados
    //   .subscribe<ICoordenadas[]>('reportesSnappeados', query)
    //   .subscribe(async (data) => {
    //     this.reportesSnappeados = data;
    //     this.datoMapa = this.armarViaje();
    //     console.log(`listado de reportesSnappeados`, data);
    //     this.loading = false;
    //   });
    // await this.listados.getLastValue('reportesSnappeados', query);
  }

  private armarViaje() {
    if (!this.reportesSnappeados.length)
      throw new Error('No hay reportes para armar el viaje');
    const center = OpenLayersService.lonLatToCoordinate(
      this.reportesSnappeados[0].lng,
      this.reportesSnappeados[0].lat,
    );
    const polylines: LineStringMapa[] = this.armarRecorridos();

    const viaje: MapaFacilData = {
      center,
      polylines,
      zoom: 14,
    };
    return viaje;
  }

  private armarRecorridos() {
    const recorridos: LineStringMapa[] = [];
    const recorridoAsignado: LineStringMapa = this.armarRecorridoAsignado();
    const coords = this.reportesSnappeados.map((reporte) => {
      return OpenLayersService.lonLatToCoordinate(reporte.lng, reporte.lat);
    });
    const recorrido: LineStringMapa = {
      points: coords,
      color: 'blue',
      width: 2,
      dash: true,
      // arrow: true,
      trip: true,
    };
    recorridos.push(recorrido);
    recorridos.push(recorridoAsignado);
    return recorridos;
  }

  private armarRecorridoAsignado() {
    const coords = this.vehiculo?.vehiculo.recorrido?.recorridoOl;
    const recorrido = {
      points: coords,
      color: this.vehiculo?.vehiculo.recorrido?.color,
      width: 4,
    };
    return recorrido;
  }

  public async onViajeSelected(viaje: Trip) {
    try {
      this.viaje = viaje;
      // Uno solo al mismo tiempo
      this.parada = undefined;
      //
      this.error = undefined;
      this.mapaReady = false;
      await this.listarViaje();
      this.mapaReady = true;
    } catch (error) {
      const e = error as HttpErrorResponse;
      console.error(e);
      const mensaje = e.error.message;
      console.log(mensaje);
      if (mensaje === 'Number of coordinates needs to be at least two.') {
        this.error = 'No hay suficientes reportes para armar el viaje';
      }
      if (mensaje === 'Could not find a matching segment for any coordinate.') {
        this.error =
          'No se encontró un segmento para niguna de las coordenadas';
      }
      this.viaje = undefined;
      this.mapaReady = false;
    }
  }

  ////// PARADA
  private armarParada() {
    if (!this.parada?.latitude || !this.parada?.longitude) {
      throw new Error('No hay coordenadas para armar la parada');
    }
    const coor: Coordinate = OpenLayersService.lonLatToCoordinate(
      this.parada?.longitude,
      this.parada?.latitude,
    );
    const stop: MarkerMapa = {
      coor,
      label: 'parada',
      centrar: true,
    };
    const recorridoAsignado: LineStringMapa = this.armarRecorridoAsignado();
    const parada: MapaFacilData = {
      markers: [stop],
      polylines: [recorridoAsignado],
      zoom: 14,
    };
    this.datoMapa = parada;
  }

  public async onParadaSelected(parada: Stop) {
    try {
      this.parada = parada;
      // Uno solo al mismo tiempo
      this.viaje = undefined;
      //
      this.error = undefined;
      this.mapaReady = false;
      this.armarParada();
      this.mapaReady = true;
    } catch (error) {
      const e = error as HttpErrorResponse;
      console.error(e);
      const mensaje = e.error.message;
      console.log(mensaje);
      if (mensaje === 'Number of coordinates needs to be at least two.') {
        this.error = 'No hay suficientes reportes para armar el viaje';
      }
      if (mensaje === 'Could not find a matching segment for any coordinate.') {
        this.error =
          'No se encontró un segmento para niguna de las coordenadas';
      }
      this.parada = undefined;
      this.mapaReady = false;
    }
  }

  ngOnDestroy(): void {
    this.reportes$?.unsubscribe();
  }
}
