@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      <span class="titulo">{{ title }}</span>
    </div>
    <br />
    <div class="row-center" style="gap: 8px">
      <!-- Identificación-->
      <mat-form-field style="width: 100%">
        <mat-label>Identificación</mat-label>
        <input matInput formControlName="identificacion" />
      </mat-form-field>
    </div>

    <!-- Dirección -->
    <app-autocomplete-direccion
      [form]="form"
      formControlDireccion="direccion"
      formControlGeoJSON="circleGeoJSON"
      [direccion]="ubicacion?.direccion"
      (geoJSONChange)="centrarA = $event"
    ></app-autocomplete-direccion>

    <!-- Formulario para dibujar polígonos -->
    <app-map-draw
      [form]="geojson"
      tipo="Polygon"
      [centrarA]="centrarA"
    ></app-map-draw>

    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>

    <!-- DEBUG -->
    <!-- <pre>{{ form.value | json }}</pre> -->
  </form>
}
