@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Informacion de la alarma</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Nombre:</span>
      <span class="dato">{{ alarma?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Numero abonado:</span>
      <span class="dato">{{ alarma?.numeroAbonado }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Marca y modelo:</span>
      <span class="dato"
        >{{ alarma?.modelo?.marca }} - {{ alarma?.modelo?.modelo }}</span
      >
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Cliente:</span>
      <span class="dato">{{ alarma?.cliente?.nombre }}</span>
    </div>
  </div>
  @if (alarma?.comunicador) {
    <div class="row-center" style="gap: 15px">
      <div>
        <span class="datoName" style="padding-right: 5px">Comunicador:</span>
        <span class="dato"
          >{{ alarma?.modelo?.marca }} - {{ alarma?.modelo?.modelo }}</span
        >
      </div>
      <div>
        <span class="datoName" style="padding-right: 5px"
          >ID Unico comunicador:</span
        >
        <span class="dato">{{ alarma?.idUnicoComunicador }}</span>
      </div>
    </div>
  }
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Sim1:</span>
      <span class="dato"
        >{{ alarma?.sim1?.numero || "Sin Sim2 Info" }} -
        {{ alarma?.sim1?.operador || "Sin Operador Info" }} -
        {{ alarma?.sim1?.iccid || "Sin ICCID info" }}</span
      >
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Sim2:</span>
      <span class="dato"
        >{{ alarma?.sim2?.numero || "Sin Sim2 Info" }} -
        {{ alarma?.sim2?.operador || "Sin Operador Info" }} -
        {{ alarma?.sim2?.iccid || "Sin ICCID info" }}</span
      >
    </div>
  </div>

  <br />
  @if (alarma?.domicilio) {
    <div class="column-center">
      <span class="subtitle1">{{ alarma?.domicilio?.direccion }}</span>
      <div style="width: 600px; height: 600px">
        <app-mapa-facil [data]="datoMapa"></app-mapa-facil>
      </div>
    </div>
  }
}
