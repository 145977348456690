import { NgModule } from '@angular/core';
import { LoginGuard } from '../login/login.guard';
import { Rol } from 'modelos/src';
import { Route, RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation/navigation.component';
import { EventosModule } from '../entidades/eventos/eventos.module';
import { AlarmasModule } from '../entidades/alarmas/module';
import { ColectivosModule } from '../entidades/colectivos/module';
import { ActivosModule } from '../entidades/activos/module';
import { AdministracionModule } from '../entidades/administracion/module';
import { MapaModule } from '../entidades/mapa/mapa.module';
import { ConfiguracionModule } from '../entidades/configuracion/module';

type MyRoutes = Route & { children?: MyRoutes[]; data?: { roles?: Rol[] } };

const routes: MyRoutes[] = [
  {
    path: '',
    component: NavigationComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard],
    children: [
      { path: '', redirectTo: 'mapa', pathMatch: 'full' },
      {
        path: 'eventos',
        loadChildren: () => EventosModule,
      },
      {
        path: 'mapa',
        loadChildren: () => MapaModule,
      },
      // Activos
      {
        path: 'activos',
        loadChildren: () => ActivosModule,
      },
      //Alarmas
      {
        path: 'alarmas',
        loadChildren: () => AlarmasModule,
      },
      //Colectivos
      {
        path: 'colectivos',
        loadChildren: () => ColectivosModule,
      },
      // configuracion
      {
        path: 'configuracion',
        loadChildren: () => ConfiguracionModule,
      },
      //Administracion
      {
        path: 'administracion',
        loadChildren: () => AdministracionModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
