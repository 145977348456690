@if (form) {
  <mat-card>
    <!-- Headers -->
    <mat-card-header>
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    </mat-card-header>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content
        style="margin: 2em 0; overflow: auto"
        class="cardContentDialog"
      >
        <h3>Datos del Documento</h3>
        <!-- Tipo -->
        <section class="column-center" style="width: 100%; gap: 16px">
          <ng-select
            [items]="tipos"
            formControlName="tipo"
            [multiple]="false"
            placeholder="Tipo"
            style="width: 100%"
            [hideSelected]="true"
            required
          >
          </ng-select>
        </section>
        <!-- Emision y Vencimiento -->
        <div class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Fecha de Emision</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                formControlName="emision"
              />
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Fecha de Vencimiento</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                formControlName="vencimiento"
              />
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <!-- Descripcion -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field style="width: 100%">
              <mat-label>Descripcion</mat-label>
              <textarea matInput formControlName="descripcion"> </textarea>
            </mat-form-field>
          </div>
        </section>

        <h3>Imágenes</h3>

        <div class="column-center" style="gap: 10px">
          <div class="row-space-around" style="width: 100%">
            <app-upload-file
              (urlChange)="onUrlChange($event)"
              (loadingFile)="loadingFile($event)"
              textoBoton="Subir Imagen"
            ></app-upload-file>
          </div>
        </div>
        <mat-progress-bar
          color="accent"
          [mode]="this.loading ? 'indeterminate' : 'determinate'"
          style="margin-top: 1em"
        >
        </mat-progress-bar>
        <div
          *ngFor="let urlActual of documentoImagenes.value; let i = index"
          class="row-center"
          style="width: 100%; margin-top: 1em; margin-bottom: 1em"
        >
          <div class="column-center" style="width: 70%">
            <img
              [src]="urlActual"
              alt="Imagen"
              style="max-width: 100%; width: auto"
            />
          </div>
          <div class="column-center" style="width: 30%">
            <app-button
              matType="mat-fab extended"
              icon="cancel"
              color="warn"
              (click)="eliminarImagen(i)"
              text="Eliminar"
            ></app-button>
          </div>
          <br />
        </div>
      </mat-card-content>
      <!-- Botones  -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialog.closeAll()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  </mat-card>
}
