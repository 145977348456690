@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ servicio?.tipo }}</span>
      } @else {
        <span class="titulo">Crear Servicio</span>
      }
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos de Servicio </span>
      </div>

      <!-- Tipo // Vehiculo -->
      <section class="layout">
        <ng-select
          [items]="tipos"
          bindLabel="tipo"
          formControlName="tipo"
          placeholder="Tipo"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
        <ng-select
          [items]="vehiculos"
          bindLabel="identificacion"
          bindValue="_id"
          formControlName="idActivo"
          placeholder="Vehiculo"
          [notFoundText]="'No hay vehiculos creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
      </section>

      <!-- Cliente  -->
      <section class="column-center" style="width: 100%; gap: 16px">
        @if (!id) {
          <ng-select
            [items]="clientes"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idCliente"
            placeholder="Cliente"
            [notFoundText]="'No hay clientes creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
          </ng-select>
        }
      </section>
      <!-- Proveedor // Nombre Chofer-->
      <section class="layout">
        <div style="width: 50%">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre Chofer</mat-label>
            <input matInput formControlName="nombreChofer" />
          </mat-form-field>
        </div>
        <ng-select
          [items]="proveedores"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idProveedor"
          placeholder="Proveedor"
          [notFoundText]="'No hay proveedores creados'"
          style="width: 45.5%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
        <app-button
          matType="mat-fab"
          tooltip="Crear Proveedor"
          color="accent"
          (click)="this.crearProveedor()"
          icon="add"
        ></app-button>
      </section>
      <!-- Fecha // KM del Mantenimiento -->
      <section class="layout">
        <mat-form-field style="width: 100%">
          <mat-label>Fecha</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="fechaRealizacion"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <mat-label>Km del Mantenimiento</mat-label>
          <input matInput formControlName="kmDelMantenimiento" />
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <mat-label>Costo</mat-label>
          <input matInput formControlName="costo" />
        </mat-form-field>
      </section>
      <!-- Detalles -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Detalles</mat-label>
            <textarea matInput formControlName="detalles"> </textarea>
          </mat-form-field>
        </div>
      </section>
    </div>
    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        [disabled]="loading || !this.form.valid"
        matType="mat-fab extended"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
