import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MapaUnicoPuntoComponent } from '../../../auxiliares/mapa-unico-punto/mapa-unico-punto.component';
import { ColectivosRoutingModule } from './routing';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { DateRangeSelectorComponent } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { MatDialogActions } from '@angular/material/dialog';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { ListadoChoferesComponent } from './choferes/listado-choferes/listado-choferes.component';
import { CrearEditarChoferComponent } from './choferes/crear-editar-chofer/crear-editar-chofer.component';
import { ListadoCronogramasComponent } from './cronogramas/listado-cronogramas/listado-cronogramas.component';
import { CrearEditarCronogramaComponent } from './cronogramas/crear-editar-cronograma/crear-editar-cronograma.component';
import { AsignarVehiculoChoferComponent } from './choferes/asignar-vehiculo-chofer/asignar-vehiculo-chofer.component';
import { ListadoDocumentosComponent } from './documentos/listado-documentos/listado-documentos.component';
import { DocumentoComponent } from './documentos/documento/documento.component';
import { ListadoColectivosComponent } from './colectivos/listado-colectivos/listado-colectivos.component';
import { SetearOdometroComponent } from './colectivos/listado-colectivos/setear-odometro/setear-odometro.component';
import { DetallesRecorridoOlComponent } from './colectivos/detalles-recorrido-ol/detalles-recorrido-ol.component';
import { CrearEditarColectivoComponent } from './colectivos/crear-editar-colectivo/crear-editar-colectivo.component';
import { ListadoProveedoresComponent } from './proveedores/listado-proveedores/listado-proveedores.component';
import { CrearEditarProveedorComponent } from './proveedores/crear-editar-proveedor/crear-editar-proveedor.component';
import { ListadoRecordatoriosComponent } from './recordatorios/listado-recordatorios/listado-recordatorios.component';
import { CrearEditarRecordatorioComponent } from './recordatorios/crear-editar-recordatorio/crear-editar-recordatorio.component';
import { ListadoRecorridosComponent } from './recorridos/listado-recorridos/listado-recorridos.component';
import { CrearEditarRecorridoComponent } from './recorridos/crear-editar-recorrido/crear-editar-recorrido.component';
import { MapaParadasOlComponent } from './recorridos/mapa-paradas-ol/mapa-paradas-ol.component';
import { ListadoServiciosComponent } from './servicios/listado-servicios/listado-servicios.component';
import { CrearEditarServicioComponent } from './servicios/crear-editar-servicios/crear-editar-servicio.component';
import { CrearEditarDocumentoComponent } from './documentos/crear-editar-documento/crear-editar-documento.component';
import { UploadFileComponent } from '../../../auxiliares/upload-file/upload-file.component';
import { MapaCreadorComponent } from '../../../auxiliares/mapa-creador/mapa-creador.component';
import { MapaColectivosComponent } from './mapa-colectivos/mapa-colectivos.component';
import { FlotanteComponent } from './mapa-colectivos/flotante/flotante.component';
import { IslaComponent } from './mapa-colectivos/isla/isla.component';
import { PopupComponent } from './mapa-colectivos/popup/popup.component';
import { DespachosModule } from './despachos/despachos.module';
import { DetallesColectivoComponent } from './colectivos/detalles-colectivo/detalles-colectivo.component';
import { ListadoEventosHistoricoComponent } from '../../standalone/eventos-historico/listado-eventos-historico/listado-eventos-historico.component';
import { ListadoConfigsEventosComponent } from '../../standalone/configs-eventos/listado-configs-eventos/listado-configs-eventos.component';
import { ListadoConfigEventoUsuarioComponent } from '../../standalone/config-evento-usuario/listado-config-evento-usuario/listado-config-evento-usuario.component';
import { ListadoNotasComponent } from '../../standalone/notas/listado-notas/listado-notas.component';
import { DetallesColectivoInfoComponent } from './colectivos/detalles-colectivo-info/detalles-colectivo-info.component';
import { DetallesTrackeosComponent } from './colectivos/detalles-colectivo-viajes/detalles-trackeos/detalles-trackeos.component';
import { ListadoLogsTrackeosComponent } from './colectivos/detalles-colectivo-viajes/listado-logs-trackeos/listado-logs-trackeos.component';
import { ListadoTrackeosComponent } from './colectivos/detalles-colectivo-viajes/listado-trackeos/listado-trackeos.component';
import { ControlesComponent } from './colectivos/detalles-colectivo-viajes/viajes-paradas/controles/controles.component';
import { ParadaComponent } from './colectivos/detalles-colectivo-viajes/viajes-paradas/controles/parada/parada.component';
import { ViajeComponent } from './colectivos/detalles-colectivo-viajes/viajes-paradas/controles/viaje/viaje.component';
import { ViajesParadasComponent } from './colectivos/detalles-colectivo-viajes/viajes-paradas/viajes-paradas.component';
import { EventosTecnicosComponent } from '../../standalone/eventos-tecnicos/eventos-tecnicos.component';

@NgModule({
  declarations: [
    //Mapa
    MapaColectivosComponent,
    IslaComponent,
    FlotanteComponent,
    PopupComponent,
    //Choferes
    ListadoChoferesComponent,
    CrearEditarChoferComponent,
    AsignarVehiculoChoferComponent,
    //Cronogramas
    ListadoCronogramasComponent,
    CrearEditarCronogramaComponent,
    //Despachos
    // ListadoComponent,
    // DespachosComponent,
    //Documentos
    ListadoDocumentosComponent,
    CrearEditarDocumentoComponent,
    DocumentoComponent,
    //Colectivos
    ListadoColectivosComponent,
    SetearOdometroComponent,
    DetallesRecorridoOlComponent,
    DetallesColectivoInfoComponent,
    DetallesColectivoComponent,
    ViajesParadasComponent,
    ControlesComponent,
    ViajeComponent,
    ParadaComponent,
    ListadoTrackeosComponent,
    ListadoLogsTrackeosComponent,
    DetallesTrackeosComponent,
    CrearEditarColectivoComponent,
    MapaParadasOlComponent,
    //Proveedores
    ListadoProveedoresComponent,
    CrearEditarProveedorComponent,
    //Recordatorios
    ListadoRecordatoriosComponent,
    CrearEditarRecordatorioComponent,
    //Recorridos
    ListadoRecorridosComponent,
    CrearEditarRecorridoComponent,
    MapaParadasOlComponent,
    //Servicios
    ListadoServiciosComponent,
    CrearEditarServicioComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    ColectivosRoutingModule,
    MapaUnicoPuntoComponent,
    MapaFacilComponent,
    DateRangeSelectorComponent,
    MatDialogActions,
    MapDrawComponent,
    UploadFileComponent,
    MapaCreadorComponent,
    DespachosModule,
    ListadoEventosHistoricoComponent,
    ListadoConfigsEventosComponent,
    ListadoConfigEventoUsuarioComponent,
    ListadoNotasComponent,
    EventosTecnicosComponent,
  ],
})
export class ColectivosModule {}
