import { Injectable } from '@angular/core';
import { IQueryParam, IListado, IReporte } from 'modelos/src';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class UltimoReportesService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IReporte>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/ultimoreportes`, { params });
  }
}
