<section class="layout">
  <!-- Input Search -->
  @if (search) {
    <div class="grow1">
      <form autocomplete="off" style="width: 100%">
        <mat-form-field class="no-hint form-field-primary" style="width: 100%">
          <mat-label>Buscar</mat-label>
          <input
            matInput
            type="text"
            #inputSearch
            [(ngModel)]="search.value"
            [ngModelOptions]="{ standalone: true }"
          />

          <button
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="search.value = ''; cambioFiltro()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
  }

  <div>
    <div *ngIf="filtros?.length">
      <!-- Boton Filtro -->
      <app-button
        #filterMenuTrigger="matMenuTrigger"
        matType="mat-fab extended"
        color="accent"
        icon="filter_alt"
        [text]="(isHandset$ | async) ? '' : 'Filtro'"
        [ngClass]="filtroActivo ? 'activado' : ''"
        [matMenuTriggerFor]="filterMenu"
      ></app-button>

      <!-- Elementos Menu Filtro -->
      <mat-menu #filterMenu="matMenu">
        <div (click)="$event.stopPropagation()" style="padding: 1em">
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 16px;
              justify-content: end;
            "
          >
            <!-- Cerrar menu -->
            <p style="text-align: right; font-weight: 500">
              <span
                (click)="filterMenuTrigger.closeMenu()"
                style="cursor: pointer"
                class="text"
              >
                Cerrar
              </span>
            </p>

            <p style="text-align: right; font-weight: 500">
              <span
                (click)="limpiarFiltros()"
                style="cursor: pointer"
                class="text"
              >
                Limpiar filtros
              </span>
            </p>
          </div>
          <form autocomplete="off">
            <!-- Hijos -->
            @if (showHijos && cliente?.tipoCliente !== "Final") {
              <div class="hijos">
                <mat-checkbox
                  [(ngModel)]="query.includeChildren"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="cambioFiltro()"
                >
                  Datos de mis clientes
                </mat-checkbox>

                <input
                  class="input-number"
                  type="number"
                  min="0"
                  (change)="cambioFiltro()"
                  [(ngModel)]="query.childrenLevel"
                  [disabled]="
                    query.includeChildren === false || !query.includeChildren
                  "
                  placeholder="Niveles"
                />
              </div>
            }

            <ng-container *ngFor="let dato of filtros; let i = index">
              <!-- Select -->
              <mat-form-field
                *ngIf="dato.tipo === 'select'"
                style="min-width: 300px"
              >
                <mat-label>{{ dato.label }}</mat-label>
                <mat-select
                  [name]="dato.label"
                  [(ngModel)]="dato.filter.value"
                  (selectionChange)="cambioFiltro()"
                >
                  <mat-option
                    class="text"
                    *ngFor="let elemento of dato.elementos"
                    [value]="
                      dato.selectValue ? elemento[dato.selectValue] : elemento
                    "
                  >
                    {{
                      dato.selectLabel ? elemento[dato.selectLabel] : elemento
                    }}
                  </mat-option>
                </mat-select>
                <button
                  *ngIf="dato.filter.value"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="
                    dato.filter.value = undefined;
                    $event.stopPropagation();
                    cambioFiltro()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Input -->
              <mat-form-field *ngIf="dato.tipo === 'input'">
                <mat-label>{{ dato.label }}</mat-label>
                <input
                  matInput
                  type="search"
                  [name]="dato.label"
                  [(ngModel)]="dato.filter.value"
                  (ngModelChange)="cambioFiltro()"
                />
              </mat-form-field>

              <!-- Date Range -->
              <mat-form-field *ngIf="dato.tipo === 'dateRange'">
                <mat-label>{{ dato.label }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    placeholder="Desde"
                    [name]="dato.label"
                    [(ngModel)]="dato.desde"
                    (ngModelChange)="cambioFiltro()"
                  />
                  <input
                    matEndDate
                    placeholder="Hasta"
                    [name]="dato.label"
                    [(ngModel)]="dato.hasta"
                    (ngModelChange)="cambioFiltro()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>

              <!-- Ng-select async -->
              <ng-select
                *ngIf="dato.tipo === 'ngselect-async'"
                [items]="asyncData$![i] | async"
                [typeahead]="asyncInput$![i]"
                [minTermLength]="3"
                [placeholder]="dato.label"
                [bindLabel]="dato.selectLabel!"
                [bindValue]="dato.selectValue!"
                [minTermLength]="3"
                loadingText="Cargando..."
                typeToSearchText="Buscar..."
                notFoundText="No se encontraron resultados"
                appendTo="body"
                (change)="cambioFiltro()"
                [(ngModel)]="dato.filter.value"
                [ngModelOptions]="{ standalone: true }"
                style="min-width: 300px"
              >
              </ng-select>

              <!-- Ng-select -->
              <ng-select
                *ngIf="dato.tipo === 'ngselect'"
                [items]="dato.elementos!"
                [placeholder]="dato.label"
                [bindLabel]="dato.selectLabel!"
                [bindValue]="dato.selectValue!"
                loadingText="Cargando..."
                typeToSearchText="Buscar..."
                notFoundText="No se encontraron resultados"
                appendTo="body"
                (change)="cambioFiltro()"
                [(ngModel)]="dato.filter.value"
                [ngModelOptions]="{ standalone: true }"
                [multiple]="!!dato.multiple"
                [closeOnSelect]="!dato.multiple"
                style="min-width: 300px"
              >
              </ng-select>

              <!-- RegExp -->
              <mat-form-field *ngIf="dato.tipo === 'regexp'">
                <mat-label>{{ dato.label }}</mat-label>
                <input
                  #inputSearch
                  matInput
                  type="search"
                  [placeholder]="dato.label"
                  [(ngModel)]="dato.filter.value"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  *ngIf="dato.filter.value"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="
                    dato.filter.value = undefined;
                    $event.stopPropagation();
                    cambioFiltro()
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </form>
        </div>
      </mat-menu>
    </div>
  </div>
</section>
