import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrearEditarCodigosDispositivosComponent } from '../../standalone/codigos-dispositivos/crear-editar-codigos-dispositivos/crear-editar-codigos-dispositivos.component';
import { ListadoCodigosDispositivoComponent } from '../../standalone/codigos-dispositivos/listado-codigos-dispositivos/listado-codigos-dispositivos.component';
import { ListadoModelosDispositivosComponent } from '../../standalone/modelos-dispositivos/listado-modelos-dispositivos/listado-modelos-dispositivos.component';
import { CrearEditarModeloDispositivoComponent } from '../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { ListadoCategoriasEventosComponent } from './categorias-eventos/listado-categorias-eventos/listado-categorias-eventos.component';
import { ListadoTiposEventosComponent } from './tipos-eventos/listado-tipos-eventos/listado-tipos-eventos.component';
import { ListadoConfigEventoUsuarioComponent } from '../../standalone/config-evento-usuario/listado-config-evento-usuario/listado-config-evento-usuario.component';
import { CrearEditarConfigEventoUsuarioDialogComponent } from '../../standalone/config-evento-usuario/crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';

const routes: Routes = [
  // Codigos Dispositivos
  {
    path: 'codigosDispositivos/:tipo',
    component: ListadoCodigosDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/crear',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/editar/:id',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },
  // Modelos Dispositivos
  {
    path: 'modelosDispositivos/:tipo',
    component: ListadoModelosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/crear',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/editar/:id',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
  //
  {
    path: 'categoriasEventos',
    component: ListadoCategoriasEventosComponent,
    canActivate: [],
  },
  {
    path: 'tiposEventos',
    component: ListadoTiposEventosComponent,
    canActivate: [],
  },
  {
    path: 'config-evento-usuario',
    component: ListadoConfigEventoUsuarioComponent,
    canActivate: [],
  },
  {
    path: 'config-evento-usuario/crear',
    component: CrearEditarConfigEventoUsuarioDialogComponent,
    canActivate: [],
  },
  {
    path: 'config-evento-usuario/editar/:id',
    component: CrearEditarConfigEventoUsuarioDialogComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfiguracionRoutingModule {}
