@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content>
        <!-- Clientes -->
        <ng-select
          [items]="clientes"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idCliente"
          placeholder="Cliente"
          [notFoundText]="'No hay clientes creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
          (change)="clienteElegido($event)"
        >
        </ng-select>

        <!-- Identificación -->
        <mat-form-field style="width: 100%">
          <mat-label>Identificación</mat-label>
          <input matInput formControlName="identificacion" />
        </mat-form-field>

        <!-- Tracker -->
        <section class="layout">
          <ng-select
            [items]="trackers"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idTracker"
            placeholder="Tracker"
            [notFoundText]="'No hay Trackers'"
            style="width: 100%"
            multiple="false"
            searchable="true"
            appendTo="body"
          >
          </ng-select>
          <app-button
            [disabled]="!this.form.value.idCliente"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="crearTracker()"
          ></app-button>
        </section>
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
