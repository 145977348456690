@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
      <!-- Header -->
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>Poner en espera</h2>
      </ng-container>

      <!-- Content -->
      <mat-card-content style="max-height: 60vh; overflow: auto">
        <!-- Aplazar por minutos -->
        <mat-form-field>
          <mat-label>Poner en espera por</mat-label>
          <input
            type="number"
            placeholder="Poner en espera por"
            matInput
            [matAutocomplete]="auto"
            (change)="onMinutosChange(+$any($event.target).value)"
          />
          <span matTextSuffix>Minutos</span>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onMinutosChange($event.option.value)"
          >
            <mat-option [value]="5">5</mat-option>
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="15">15</mat-option>
            <mat-option [value]="20">20</mat-option>
            <mat-option [value]="30">30</mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!-- Nota -->
        <mat-form-field>
          <mat-label>Nota</mat-label>
          <textarea matInput formControlName="nota"></textarea>
        </mat-form-field>

        <!-- Nota Interna -->
        <mat-form-field>
          <mat-label>Nota Interna</mat-label>
          <textarea matInput formControlName="notaInterna"></textarea>
        </mat-form-field>
      </mat-card-content>

      <mat-divider style="margin-top: 1em"></mat-divider>

      <!-- Acciones -->
      <mat-dialog-actions
        style="display: flex; justify-content: flex-end; gap: 1em"
      >
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="dialogRef.close()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
