<mat-card
  class="m-2"
  style="width: 500px"
  fxLayout="column"
  fxLayoutAlign="space-between start"
>
  <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h2 mat-dialog-title cdkDragHandle>
      Asignar Vehiculo a {{ usuario.usuario }}
    </h2>
  </ng-container>

  <mat-card-content style="margin: 1em">
    <mat-form-field style="width: 100%">
      <mat-label>Vehiculo</mat-label>
      <mat-select [(value)]="vehiculo" placeholder="Vehiculo">
        <mat-option *ngFor="let option of vehiculos" [value]="option">
          {{ option?.identificacion }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>

  <mat-dialog-actions align="end">
    <app-button
      matType="mat-fab extended"
      color="warn"
      (click)="close()"
      text="Volver"
      icon="arrow_back"
    ></app-button>
    <app-button
      matType="mat-fab extended"
      color="accent"
      text="Asignar"
      icon="save"
      (click)="this.asignarVehiculo()"
    ></app-button>
  </mat-dialog-actions>
</mat-card>
