<mat-tab-group
  [(selectedIndex)]="selectedTab"
  (selectedTabChange)="tabChanged($event)"
>
  <!-- Historial de Eventos -->
  <mat-tab label="Tabla">
    <ng-template matTabContent>
      <app-clientes-tabla></app-clientes-tabla>
    </ng-template>
  </mat-tab>
  <!-- Info -->
  <mat-tab label="Grafico">
    <ng-template matTabContent>
      <app-clientes-grafico></app-clientes-grafico>
    </ng-template>
  </mat-tab>
</mat-tab-group>
