import { Injectable } from '@angular/core';
import {
  ITratamientoEvento,
  IListado,
  IQueryParam,
  IUpdateTratamientoEvento,
  ICreateTratamientoEvento,
} from 'modelos/src';
import { HttpClientService } from '../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class TratamientoEventosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ITratamientoEvento>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/tratamientoeventos`, { params });
  }

  public getById(id: string): Promise<ITratamientoEvento> {
    return this.http.get(`/tratamientoeventos/${id}`);
  }

  public create(dato: ICreateTratamientoEvento): Promise<ITratamientoEvento> {
    return this.http.post(`/tratamientoeventos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateTratamientoEvento,
  ): Promise<ITratamientoEvento> {
    return this.http.put(`/tratamientoeventos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/tratamientoeventos/${id}`);
  }
}
