/* eslint-disable @typescript-eslint/no-explicit-any */
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Observable } from 'rxjs';
import { IColumnas } from '../tabla.component';
import { map, shareReplay } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatMenuModule,
  MAT_MENU_DEFAULT_OPTIONS,
} from '@angular/material/menu';
import { ButtonComponent } from '../../button/button.component';

@Component({
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCheckboxModule,
        DragDropModule,
        ButtonComponent,
    ],
    selector: 'app-column-select',
    templateUrl: './column-select.component.html',
    styleUrls: ['./column-select.component.scss'],
    providers: [
        {
            provide: MAT_MENU_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'shadow' },
        },
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnSelectComponent implements OnInit {
  @Input() columnas: IColumnas<any>[] = [];

  private columnsDef: string[] = [];
  @Output() columnsDefChange = new EventEmitter<string[]>();

  @Input() icon: string = 'view_column';
  @Input() text: string = 'Columnas';
  @Input() drag?: boolean = true;
  @Input() saveName?: string;
  @Input() buttonColumns?: boolean = true;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  private getSavedColumns(): string[] | void {
    if (this.saveName) {
      const savedColumns = localStorage.getItem(this.saveName);
      if (savedColumns) {
        const culumnasGuardadas = JSON.parse(savedColumns) as IColumnas<any>[];
        // Valida la cantidad de columnas guardadas
        if (this.columnas.length !== culumnasGuardadas.length) {
          this.deleteSavedColumns();
          return;
        }

        const valido = culumnasGuardadas.every((col) => {
          const headerDef =
            col.header.sort || col.row.field || col.header.label;
          return this.columnas.some((col2) => {
            const headerDef2 =
              col2.header?.sort || col2.row?.field || col2.header?.label;
            return headerDef === headerDef2;
          });
        });

        // Si es valido, retorna las columnas guardadas
        if (valido) {
          this.columnas = culumnasGuardadas;
          this.columnasToColumnsDef();
          return this.columnsDef;
        } else {
          this.deleteSavedColumns();
        }
      }
    }
    return;
  }

  private saveColumns() {
    if (this.saveName) {
      localStorage.setItem(this.saveName, JSON.stringify(this.columnas));
    }
  }

  private deleteSavedColumns() {
    if (this.saveName) {
      localStorage.removeItem(this.saveName);
    }
  }

  private columnasToColumnsDef() {
    const columnas: string[] = this.columnas
      .filter((col) => !col.header.hidden)
      .map(
        (col) =>
          (col.header.sort || col.row.field || col.header.label) as string,
      );
    this.columnsDef = columnas;
    return columnas;
  }

  // CdkDragDrop<any>
  public columnMenuDropped(event: any): void {
    moveItemInArray(
      this.columnas,
      event.item.data.columnIndex,
      event.currentIndex,
    );
    this.columnasToColumnsDef();
    this.saveColumns();
    this.emitColumns();
  }

  public toggleSelectedColumn(columnHeader: string) {
    const colFound = this.columnas.find(
      (col) => col.header.label === columnHeader,
    );
    if (colFound) colFound.header.hidden = !colFound.header.hidden;
    this.columnasToColumnsDef();
    this.saveColumns();
    this.emitColumns();
  }

  private emitColumns() {
    window.requestAnimationFrame(() =>
      this.columnsDefChange.emit(this.columnsDef),
    );
  }

  ngOnInit() {
    const savedColumns = this.getSavedColumns();
    if (!savedColumns) {
      this.columnasToColumnsDef();
    }
    this.emitColumns();
  }
}
