import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MapaUnicoPuntoComponent } from '../../../auxiliares/mapa-unico-punto/mapa-unico-punto.component';
import { ActivosRoutingModule } from './routing';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { DateRangeSelectorComponent } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadoActivosComponent } from './activos/listado-activos/listado-activos.component';
import { CrearEditarActivoComponent } from './activos/crear-editar-activo/crear-editar-activo.component';
import { MapaActivosComponent } from './mapa-activos/mapa-activos.component';
import { FlotanteActivosComponent } from './mapa-activos/flotante-activos/flotante-activos.component';
import { IslaActivosComponent } from './mapa-activos/isla-activos/isla-activos.component';
import { PopupActivosComponent } from './mapa-activos/popup-activos/popup-activos.component';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { DetallesActivoInfoComponent } from './activos/detalles-activo-info/detalles-activo-info.component';
import { DetallesActivoComponent } from './activos/detalles-activo/detalles-activo.component';
import { InfoPuntoComponent } from './activos/detalles-activos-mapa/info-punto/info-punto.component';
import { ListadoConfigsEventosComponent } from '../../standalone/configs-eventos/listado-configs-eventos/listado-configs-eventos.component';
import { ListadoNotasComponent } from '../../standalone/notas/listado-notas/listado-notas.component';
import { ListadoConfigEventoUsuarioComponent } from '../../standalone/config-evento-usuario/listado-config-evento-usuario/listado-config-evento-usuario.component';
import { ListadoEventosHistoricoComponent } from '../../standalone/eventos-historico/listado-eventos-historico/listado-eventos-historico.component';
import { EventosTecnicosComponent } from '../../standalone/eventos-tecnicos/eventos-tecnicos.component';

@NgModule({
  declarations: [
    //Activos
    ListadoActivosComponent,
    CrearEditarActivoComponent,
    DetallesActivoComponent,
    InfoPuntoComponent,
    DetallesActivoComponent,
    DetallesActivoInfoComponent,

    //Mapa Activos
    MapaActivosComponent,
    FlotanteActivosComponent,
    IslaActivosComponent,
    PopupActivosComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    ActivosRoutingModule,
    MapaUnicoPuntoComponent,
    MapaFacilComponent,
    DateRangeSelectorComponent,
    MapDrawComponent,
    ListadoNotasComponent,
    ListadoConfigsEventosComponent,
    ListadoConfigEventoUsuarioComponent,
    ListadoEventosHistoricoComponent,
    EventosTecnicosComponent,
  ],
})
export class ActivosModule {}
