import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateTipoEvento,
  IUpdateTipoEvento,
  ITipoEvento,
  ICategoriaTipoEvento,
} from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { TiposEventosService } from '../../../../../auxiliares/servicios/http/tipos-eventos.service';

@Component({
    selector: 'app-crear-editar-tipo-evento',
    templateUrl: './crear-editar-tipo-evento.component.html',
    styleUrl: './crear-editar-tipo-evento.component.scss',
    standalone: false
})
export class CrearEditarTipoEventoComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public categorias: ICategoriaTipoEvento[] = ['Alarma', 'Tracker'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITipoEvento,
    public helper: HelperService,
    private service: TiposEventosService,
    public dialogRef: MatDialogRef<CrearEditarTipoEventoComponent>,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.data?.nombre, Validators.required),
      categoria: new FormControl(this.data?.categoria, Validators.required),
    });
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        // Update
        await this.service.update(this.data._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateTipoEvento | IUpdateTipoEvento {
    const data: ICreateTipoEvento | IUpdateTipoEvento = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.title = this.data?._id
      ? `Editar ${this.data.nombre}`
      : 'Crear Tipo de Evento';
    this.createForm();
  }
}
