<div id="mapa-creador" class="map-container">
  <div class="flotante">
    <div class="row-flex-start" style="gap: 8px; margin-left: 0.3em">
      @if (dibujar.includes("LineString")) {
        @if (!dibujando && (!lineString.length || lineString.length === 0)) {
          <button
            mat-mini-fab
            color="accent"
            type="button"
            (click)="dibujarLineString()"
          >
            <mat-icon>timeline</mat-icon>
          </button>
        }
        @if (dibujando) {
          <button mat-mini-fab color="warn" type="button" (click)="parar()">
            <mat-icon>pause</mat-icon>
          </button>
        }
        @if (!dibujando && lineString.length > 1) {
          <button mat-mini-fab color="warn" type="button" (click)="continuar()">
            <mat-icon>play_arrow</mat-icon>
          </button>

          <button
            mat-mini-fab
            color="warn"
            type="button"
            (click)="snapToRoad()"
          >
            <mat-icon>edit_road</mat-icon>
          </button>
        }

        <button
          mat-mini-fab
          color="warn"
          type="button"
          (click)="eliminarUltimaCoordenada()"
        >
          <mat-icon>restart_alt</mat-icon>
        </button>

        <button mat-mini-fab color="warn" type="button" (click)="borrar()">
          <mat-icon>delete</mat-icon>
        </button>
      }
    </div>
  </div>
  @if (this.indicaciones.length > 0) {
    <div class="indicaciones magico-85">
      <div class="column-flex-start" style="width: 100%; gap: 5px">
        @for (i of this.indicaciones; track $index) {
          <span class="texto disable-text-selection">
            {{ i }}
          </span>
        }
      </div>
    </div>
  }
</div>
