import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  ICreateProveedor,
  IListado,
  IQueryParam,
  IUpdateProveedor,
  IProveedor,
  TipoProveedor,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ProveedoresService } from '../../proveedores/proveedores.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Coordinate } from 'ol/coordinate';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
    selector: 'app-crear-editar-proveedor',
    templateUrl: './crear-editar-proveedor.component.html',
    styleUrl: './crear-editar-proveedor.component.scss',
    standalone: false
})
export class CrearEditarProveedorComponent implements OnInit, OnDestroy {
  //DEMAS
  public form?: FormGroup;
  public title?: string;
  public tipos: TipoProveedor[] = ['Mecanico', 'Combustible'];
  public id?: string | null;
  public proveedor?: IProveedor;
  public loading: boolean = true;

  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;

  get datosPersonales() {
    return this.form?.get('datosPersonales') as FormGroup;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public param: string,
    private listados: ListadosService,
    public helper: HelperService,
    private service: ProveedoresService,
    public dialog: MatDialog,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      tipos: new FormControl(this.proveedor?.tipos, Validators.required),
      idCliente: new FormControl(
        this.proveedor?.idCliente,
        Validators.required,
      ),
      nombre: new FormControl(this.proveedor?.nombre, Validators.required),
      ubicacion: new FormControl(this.proveedor?.ubicacion),
    });
  }

  private async getProveedor() {
    if (this.id) this.proveedor = await this.service.getById(this.id);
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Proveedor`,
      `¿Desea ${this.title.toLowerCase()} el proveedor?`,
    );
    if (!res) return;
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Proveedor actualizado');
        this.dialog.closeAll();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Proveedor creado');
        this.dialog.closeAll();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public onUrlChange(url: string) {
    this.datosPersonales.setValue({ ...this.datosPersonales.value, foto: url });
  }

  public onPointChange(ubicacion: Coordinate) {
    this.form?.patchValue({ ubicacion: ubicacion });
    console.log(this.form?.value.imagenes);
  }

  public getData(): ICreateProveedor | IUpdateProveedor {
    const data: ICreateProveedor | IUpdateProveedor = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.id = this.param;
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await this.getProveedor();
    } else {
      await this.listarClientes();
    }
    this.createForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
