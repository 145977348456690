import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios';
import { IDispositivoAlarma } from 'modelos/src';
import { IParamEventoUsuario } from '../../../standalone/config-evento-usuario/crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Component({
    selector: 'app-detalles-alarma',
    templateUrl: './detalles-alarma.component.html',
    styleUrl: './detalles-alarma.component.scss',
    standalone: false
})
export class DetallesAlarmaComponent implements OnInit {
  public selectedTab = 0;
  public idAlarma = '';
  public alarma: IDispositivoAlarma;

  public alarma$: Subscription;

  public params?: IParamEventoUsuario;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listados: ListadosService,
  ) {}

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }
  public async listarAlarma(): Promise<void> {
    this.alarma$?.unsubscribe();
    this.alarma$ = this.listados
      .subscribe<IDispositivoAlarma>('dispositivoAlarma', this.idAlarma)
      .subscribe((data) => {
        this.alarma = data;
        console.log(`listado de dispositivoAlarma`, data);
      });
    await this.listados.getLastValue('dispositivoAlarma', this.idAlarma);
  }

  public alarmas() {
    this.router.navigate(['main/alarmas/alarmasDomiciliarias'], {});
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.activatedRoute.paramMap);
    this.idAlarma = params.get('id');

    await this.listarAlarma();

    // Los parametros para el componente config evento usuario
    this.params = {
      tipoEntidad: 'Alarma',
      agrupacion: 'Entidad',
      idEntidad: this.idAlarma,
      alarma: this.alarma,
      botonCrear:
        HelperService.getConfigCliente().moduloAlarmasDomiciliarias
          ?.crearDispositivos,
      crear: 'Dialog',
    };

    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
  }
}
