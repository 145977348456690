import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-clientes-listado',
    templateUrl: './clientes-listado.component.html',
    styleUrl: './clientes-listado.component.scss',
    standalone: false
})
export class ListadoClientesComponent implements OnInit {
  public selectedTab = 0;

  public loading: boolean = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
  }
}
