import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IServicio,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ServiciosService } from '../../servicios/servicios.service';
import { MatDialog } from '@angular/material/dialog';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';

@Component({
    selector: 'app-listado-servicios',
    templateUrl: './listado-servicios.component.html',
    styleUrl: './listado-servicios.component.scss',
    standalone: false
})
export class ListadoServiciosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoServiciosComponent.name;
  public servicios: IServicio[] = [];
  public vehiculos: IActivo[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IServicio>[];
  //Subs
  public servicios$?: Subscription;
  public vehiculos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Servicio',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['tipo'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query
  public filter: IFilter<IServicio> = {};
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  public filterVehiculos: IFilter<IServicio> = {};
  public queryVehiculos: IQueryParam = {
    filter: JSON.stringify(this.filterVehiculos),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ServiciosService,
    private navigator: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public activosService: ActivosService,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<IServicio> = JSON.parse(this.query.filter);
      this.query.filter = JSON.stringify(filter);
    }
    this.servicios$?.unsubscribe();
    this.servicios$ = this.listados
      .subscribe<IListado<IServicio>>('servicios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.servicios = data.datos;
        console.log(`listado de servicios`, data);
      });
    await this.listados.getLastValue('servicios', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(servicio: IServicio) {
    this.navigator.navigate(['editar', servicio._id], {
      relativeTo: this.route,
    });
  }

  public ventanaDocumentos(data: IServicio) {
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'listar',
      'servicio',
      data._id,
    ]);
  }

  public async eliminar(servicio: IServicio) {
    const res = await this.dialogService.confirm(
      'Eliminar Servicio',
      `¿Desea eliminar el servicio ${servicio.tipo}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(servicio._id!);
      this.helper.notifSuccess('Servicio eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Tipo',
          sortable: true,
        },
        row: {
          field: 'tipo',
        },
      },
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaRealizacion',
          pipe: {
            pipe: DatePipe,
            args: 'dd/MM/yyyy',
          },
        },
      },
      {
        header: {
          label: 'Vehiculo',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato.activo?.identificacion;
          },
        },
      },
      {
        header: {
          label: 'Chofer',
          sortable: true,
        },
        row: {
          field: 'nombreChofer',
        },
      },
      {
        header: {
          label: 'Costo',
          sortable: true,
        },
        row: {
          field: 'costo',
        },
      },
    ];
    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        this.columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }
    this.columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  ngOnDestroy() {
    this.servicios$?.unsubscribe();
  }
}
