import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IQueryParam,
  ICliente,
  ICategoriaEvento,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriaEventosService } from '../../../../../auxiliares/servicios/http/categoria-eventos.service';
import { CrearEditarCategoriaEventoComponent } from '../crear-editar-categoria-evento/crear-editar-categoria-evento.component';

@Component({
    selector: 'app-listado-categorias-eventos',
    templateUrl: './listado-categorias-eventos.component.html',
    styleUrl: './listado-categorias-eventos.component.scss',
    standalone: false
})
export class ListadoCategoriasEventosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoCategoriasEventosComponent.name;
  public datos: ICategoriaEvento[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ICategoriaEvento>[];
  //Subs
  public codigosEventos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Código de Evento',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query

  public filter: IFilter<ICategoriaEvento> = {};
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: CategoriaEventosService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      await Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<ICategoriaEvento> = JSON.parse(this.query.filter);
      this.query.filter = JSON.stringify(filter);
    }
    this.codigosEventos$?.unsubscribe();
    this.codigosEventos$ = this.listados
      .subscribe<IListado<ICategoriaEvento>>('categoriaEventos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de categoriaEventos`, data);
      });
    await this.listados.getLastValue('categoriaEventos', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  // Acciones
  public crear() {
    const config: MatDialogConfig = {
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarCategoriaEventoComponent, config);
  }

  public async editar(tipoEvento: ICategoriaEvento) {
    const config: MatDialogConfig = {
      width: '600px',
      disableClose: true,
      data: tipoEvento,
    };
    this.dialog.open(CrearEditarCategoriaEventoComponent, config);
  }

  public async eliminar(tipoevento: ICategoriaEvento) {
    const res = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea eliminar el código de evento ${tipoevento.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(tipoevento._id!);
      this.helper.notifSuccess('Eliminación correcta');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Nombre
      {
        header: {
          label: 'Prioridad',
          sortable: true,
        },
        row: {
          field: 'prioridad',
        },
      },
      // Color
      {
        header: {
          label: 'Color',
        },
        row: {
          html: (dato) => {
            let html = `<div style="display: flex; gap: 1em; align-items: center">`;
            if (dato.color) {
              html += `<span style="color: ${dato.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
            }
            html += `</div>`;
            return html;
          },
        },
      },
      // Notificar
      {
        header: {
          label: 'Notificación Push',
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.notificar ? iconCheck : iconClose;
          },
        },
      },
      // Atender
      {
        header: {
          label: 'Requiere Atención',
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.atender ? iconCheck : iconClose;
          },
        },
      },
      // No Derivar
      {
        header: {
          label: 'No Derivar',
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.noDerivar ? iconCheck : iconClose;
          },
        },
      },
      // Sonido
      {
        header: {
          label: 'Sonido',
          sortable: true,
        },
        row: {
          field: 'sonido',
          noData: 'Sin sonido seleccionado',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  async ngOnDestroy() {
    this.codigosEventos$?.unsubscribe();
  }
}
