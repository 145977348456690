import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { View, Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';
import { Draw, Snap, Modify } from 'ol/interaction';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { OpenLayersService } from '../servicios/openLayers.service';
import Text from 'ol/style/Text';
import { HelperService } from '../servicios/helper.service';

import Fill from 'ol/style/Fill';
import VectorSource from 'ol/source/Vector';
import { RecorridosService } from '../../modulos/entidades/colectivos/recorridos/recorridos.service';

export interface Parada {
  ubicacion: Coordinate;
  nombre?: string;
}

export interface CreadorPolygon {
  coords: Coordinate[];
  color?: string;
}

@Component({
    selector: 'app-mapa-creador',
    imports: [CommonModule, AuxiliaresModule],
    templateUrl: './mapa-creador.component.html',
    styleUrl: './mapa-creador.component.scss'
})
export class MapaCreadorComponent implements OnInit, OnChanges {
  // Qué dibujar
  @Input() public dibujar: string[] = [];
  // LineString
  @Input() lineString: Coordinate[] = [];
  @Output() lineStringChange = new EventEmitter<Coordinate[]>();
  @Input() color = 'blue';

  // Icon -- Marker
  @Input() points: Parada[] = [];
  @Output() pointsChange = new EventEmitter<Parada[]>();
  @Input() pointLabel?: string;
  @Input() pointType = 'Paradas';
  @Input() checkDistanceFromLineString?: boolean;

  // Indicaciones
  @Input() indicaciones: string[] = [];

  // Polygons
  @Input() polygons: CreadorPolygon[] = [];

  public draw?: Draw;
  public snap?: Snap;
  public modify?: Modify;
  public dibujando = false;

  // OpenLayers
  private map?: Map;
  public tileLayer = OpenLayersService.mapTile();
  public drawLayer = OpenLayersService.drawVectorLayer();
  public paradasLayer = OpenLayersService.paradasVectorLayer();
  public paradasTextLayer = OpenLayersService.paradasTextVectorLayer();
  public polygonsLayer = OpenLayersService.polygonsVectorLayer();

  constructor(
    private helper: HelperService,
    private service: RecorridosService,
  ) {}

  private async initMap(): Promise<void> {
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      controls: [],
      layers: [
        this.tileLayer,
        this.drawLayer,
        this.paradasLayer,
        this.paradasTextLayer,
        this.polygonsLayer,
      ],
      target: 'mapa-creador',
      view: new View({
        center: await OpenLayersService.getCurrentPosition(),
        zoom: 13,
      }),
    });

    if (this.polygons.length > 0) {
      this.addPolygons();
    }
    if (this.paradasLayer.getSource()?.getFeatures().length) {
      this.setBounds(this.paradasLayer.getSource());
    }
  }

  // LineString
  public async dibujarLineString() {
    if (this.dibujando) return;
    this.dibujando = true;

    if (this.lineString?.length > 0) {
      const feature: Feature<Geometry> = new Feature({
        geometry: new LineString(this.lineString),
      });
      const s = new Style({
        stroke: new Stroke({
          color: this.color,
          width: 4,
        }),
      });
      feature.setStyle(s);
      this.drawLayer.getSource()?.addFeature(feature);
    } else {
      this.draw = new Draw({
        source: this.drawLayer.getSource()!,
        type: 'LineString',
        style: OpenLayersService.styles['LineString'],
      });
      this.map?.addInteraction(this.draw);
    }

    this.snap = new Snap({ source: this.drawLayer.getSource()! });
    this.modify = new Modify({ source: this.drawLayer.getSource()! });

    if (this.color) {
      this.drawLayer.setStyle(
        new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        }),
      );
    }

    this.handleDrawEnd();
    this.handleModifyEnd();
  }

  private handleDrawEnd() {
    this.draw?.on('drawend', (event) => {
      /**
       * Geometry es genérico hay que castearlo a lo que se está dibujando
       * @todo Hacer esto bien, porque lo estoy casteando a mano
       */
      const l = event?.feature.getGeometry() as LineString;
      if (!l) return;
      const coords = l.getCoordinates();
      this.lineString = coords;
      this.lineStringChange.emit(this.lineString);
      if (this.draw) this.map?.removeInteraction(this.draw);
      if (this.snap) this.map?.removeInteraction(this.snap);
      if (this.modify) this.map?.removeInteraction(this.modify);
      setTimeout(() => {
        this.dibujando = false;
      }, 1000);
    });
  }

  private handleModifyEnd() {
    this.modify?.on('modifyend', (event) => {
      const l = event?.features?.getArray()[0]?.getGeometry() as LineString;
      if (!l) return;
      const coords = l.getCoordinates();
      this.lineString = coords;
      this.lineStringChange.emit(this.lineString);
    });
  }

  public async parar() {
    if (!this.map) return;
    if (this.snap) this.map.removeInteraction(this.snap);
    if (this.modify) this.map.removeInteraction(this.modify);
    if (this.draw) this.map.removeInteraction(this.draw);
    if (this.checkDistanceFromLineString && this.points.length > 0) {
      this.checkPoints();
    }
    this.dibujando = false;
  }

  public async continuar() {
    if (!this.map) return;

    if (this.lineString.length === 0) {
      if (this.draw) this.map.addInteraction(this.draw);
    }
    if (this.snap) this.map.addInteraction(this.snap);
    if (this.modify) this.map.addInteraction(this.modify);

    this.dibujando = true;
  }

  public borrar() {
    this.drawLayer?.getSource()?.clear();
    this.lineString = [];
    this.lineStringChange.emit(this.lineString);
    /// Solo borro los puntos si es necesario
    if (this.checkDistanceFromLineString) {
      this.points = [];
      this.pointsChange.emit(this.points);
      this.clearPointsLayer();
    }
    this.parar();
  }

  public eliminarUltimaCoordenada() {
    if (this.lineString.length === 0) return;
    this.lineString.pop();
    this.lineStringChange.emit(this.lineString);
    this.drawLayer?.getSource()?.clear();
    this.addLineStringToDrawLayer();
    this.checkPoints();
    if (this.lineString.length === 1) {
      this.borrar();
      return;
    }
  }

  public async snapToRoad() {
    if (this.lineString.length === 0) {
      return this.helper.notifError(
        'Primero debe dibujar el recorrido',
        'Cerrar',
      );
    }
    try {
      const snapped = await this.service.snapToRoadOL(this.lineString);

      if (snapped) {
        this.borrar();
        this.lineString = snapped;
        this.lineStringChange.emit(this.lineString);
        this.addLineStringToDrawLayer();
      } else {
        this.helper.notifError(
          'Error al intentar ajustar el recorrido',
          'Cerrar',
        );
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(
        'Error al intentar ajustar el recorrido',
        'Cerrar',
      );
    }
  }

  private addLineStringToDrawLayer() {
    if (this.lineString.length === 0) return;
    const feature: Feature<Geometry> = new Feature({
      geometry: new LineString(this.lineString),
    });
    const s = new Style({
      stroke: new Stroke({
        color: this.color || 'purple',
        width: 4,
      }),
    });
    feature.setStyle(s);
    this.drawLayer.getSource()?.addFeature(feature);
    this.setBounds(this.drawLayer.getSource());
  }

  /// Icon -- Marker
  private async dibujarPuntos() {
    if (!this.map) return;
    if (this.pointType === 'Paradas') {
      this.handleParadas();
    }
  }

  private handleParadas() {
    // Cargo las que haya (EDIT)
    if (this.points?.length > 0) {
      this.points.forEach((p, idx) => {
        this.addIconAndTextParada(p, idx.toString(), this.pointLabel);
      });
    }

    this.handleClick();
  }

  private handleClick() {
    // ONCLICK
    this.map?.on('singleclick', (e) => {
      if (this.dibujando) return;
      const ubicacion = e.coordinate;
      if (this.checkDistanceFromLineString && this.lineString.length === 0) {
        return this.helper.notifError(
          'Primero debe dibujar el recorrido',
          'Cerrar',
        );
      }
      if (!ubicacion) {
        return this.helper.notifError(
          'Error al obtener la ubicación',
          'Cerrar',
        );
      }

      if (this.checkDistanceFromLineString) {
        if (
          OpenLayersService.leastDistanceFromLineString(
            ubicacion,
            this.lineString,
          ) > 25
        ) {
          return this.helper.notifError(
            'La parada debe estar cerca del recorrido',
            'Cerrar',
          );
        }
      }
      const parada: Parada = {
        ubicacion,
      };

      this.points.push(parada);
      this.addIconAndTextParada(
        parada,
        `${this.points.length - 1}`,
        this.pointLabel,
      );
      this.pointsChange.emit(this.points);
    });
  }

  private addIconAndTextParada(p: Parada, id: string, label?: string) {
    // Icon
    const source = this.paradasLayer.getSource();
    if (!source) return;
    const feature: Feature<Geometry> = new Feature({
      geometry: new Point(p.ubicacion),
    });
    feature.setId(id);
    source.addFeature(feature);
    // Text
    const sourceText = this.paradasTextLayer.getSource();
    if (!sourceText) return;
    const featureText: Feature<Geometry> = new Feature({
      geometry: new Point(p.ubicacion),
    });
    let texto;
    if (p.nombre) {
      texto = p.nombre;
    } else if (label) {
      texto = label + ` ${+id + 1}`;
    } else {
      texto = '';
    }
    const text = new Text({
      text: texto,
      font: 'bold 12px sans-serif',
      offsetY: 5,
    });
    featureText.setStyle(new Style({ text }));
    featureText.setId(id);
    sourceText.addFeature(featureText);
  }

  private removeIconAndTextParada(id: string) {
    const source = this.paradasLayer.getSource();
    if (!source) return;
    const feature = source.getFeatureById(id);
    if (!feature) return;
    source.removeFeature(feature);

    const sourceText = this.paradasTextLayer.getSource();
    if (!sourceText) return;
    const featureText = sourceText.getFeatureById(id);
    if (!featureText) return;
    sourceText.removeFeature(featureText);
  }

  private checkPoints() {
    if (!this.checkDistanceFromLineString) return;
    if (!this.lineString.length) return;
    if (!this.points.length) return;

    const points = this.points;
    let b = false;
    // Borro los puntos
    for (const p of points) {
      if (
        OpenLayersService.leastDistanceFromLineString(
          p.ubicacion,
          this.lineString,
        ) > 25
      ) {
        this.points = this.points.filter((point) => point !== p);
        b = true;
      }
    }
    if (b) {
      this.helper.notifWarn(
        `Puntos eliminados por estar lejos de la línea`,
        'Cerrar',
      );
    }
    // Recargo los puntos
    this.renombrarPuntos();
    this.pointsChange.emit(this.points);
  }

  private renombrarPuntos() {
    this.clearPointsLayer();
    for (const p of this.points) {
      this.removeIconAndTextParada(this.points.indexOf(p).toString());
      this.addIconAndTextParada(
        p,
        this.points.indexOf(p).toString(),
        this.pointLabel,
      );
    }
  }

  private clearPointsLayer() {
    this.paradasLayer.getSource()?.clear();
    this.paradasTextLayer.getSource()?.clear();
  }

  private panTo(c: Coordinate) {
    if (!this.map) return;
    this.map.getView().animate({ center: c, duration: 1000 });
  }

  // Fondo

  private addPolygons() {
    if (!this.map) return;
    if (this.polygons.length === 0) return;
    this.polygons.forEach((p) => {
      const feature: Feature<Geometry> = new Feature({
        geometry: new Polygon([p.coords]),
      });
      const s = new Style({
        stroke: new Stroke({
          color: p.color || 'blue',
          width: 4,
        }),
        fill: new Fill({
          color: this.color
            ? HelperService.hexToRgba(this.color, 0.1)
            : 'rgba(0, 0, 255, 0.1)',
        }),
      });
      feature.setStyle(s);
      this.polygonsLayer.getSource()?.addFeature(feature);
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    this.map?.getView().fit(extent, { padding: [50, 50, 50, 50] });
  }

  //// HOOKS
  async ngOnInit(): Promise<void> {
    await this.initMap();
    if (this.lineString?.length > 0) {
      this.dibujarLineString();
    }
    /// Siempre agarro markers con el click
    this.dibujarPuntos();
  }

  async ngOnChanges(c: SimpleChanges): Promise<void> {
    if (c['points'] && c['points'].currentValue !== c['points'].previousValue) {
      this.renombrarPuntos();
    }

    if (
      c['polygons'] &&
      c['polygons'].currentValue !== c['polygons'].previousValue
    ) {
      this.polygonsLayer.getSource()?.clear();
      this.addPolygons();
    }
  }
}
