import { Injectable } from '@angular/core';
import {
  IDispositivoAlarma,
  IListado,
  IQueryParam,
  IUpdateDispositivoAlarma,
  ICreateDispositivoAlarma,
  ICoordenadas,
  ConfigComunicadorNanocomm,
} from 'modelos/src';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

export interface DatosConfigNanocomm {
  datos: ConfigComunicadorNanocomm;
  telefono: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlarmasDomiciliariasService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IDispositivoAlarma>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/dispositivoalarmas`, { params });
  }

  public getById(id: string): Promise<IDispositivoAlarma> {
    return this.http.get(`/dispositivoalarmas/${id}`);
  }

  public create(dato: ICreateDispositivoAlarma): Promise<IDispositivoAlarma> {
    return this.http.post(`/dispositivoalarmas`, dato);
  }

  public update(
    id: string,
    dato: IUpdateDispositivoAlarma,
  ): Promise<IDispositivoAlarma> {
    return this.http.put(`/dispositivoalarmas/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/dispositivoalarmas/${id}`);
  }

  public async geocodeDireccion(direccion: string): Promise<ICoordenadas> {
    return this.http.post(`/geocode/geocode`, { text: direccion });
  }

  public enviarSMSNanocomm(body: DatosConfigNanocomm): Promise<void> {
    return this.http.post(`/dispositivoalarmas/configNanocomm/`, body);
  }
}
