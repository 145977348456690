import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRecorrido } from 'modelos/src';

@Component({
    selector: 'app-flotante-activos',
    templateUrl: './flotante-activos.component.html',
    styleUrl: './flotante-activos.component.scss',
    standalone: false
})
export class FlotanteActivosComponent {
  @Input() recorridos: IRecorrido[] = [];
  @Output() recorridosChange = new EventEmitter<IRecorrido[]>();
  @Output() activosChange = new EventEmitter();
  @Input() activos?: number;
  public activosShow: boolean = true;
  constructor() {}

  public onRecorridoChange(recorridos: IRecorrido[]) {
    this.recorridosChange.emit(recorridos);
  }
  public onActivosChange() {
    this.activosShow = !this.activosShow;
    this.activosChange.emit();
  }
}
