import { LoginService } from './../../login/login.service';
import { Component, OnChanges, OnInit } from '@angular/core';
import { LoadingService } from '../../../auxiliares/servicios/loading.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ThemesService } from '../../../auxiliares/servicios/themes.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { Subscription } from 'rxjs';
import { ICliente, ITipoCliente } from 'modelos/src';
import { Router } from '@angular/router';
import { PushNotificationsService } from '../../../auxiliares/servicios/push-notifications.service';
import { ListadoEventosVivoService } from '../../../auxiliares/servicios/listado-eventos-vivo';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  standalone: false,
})
export class NavigationComponent implements OnInit, OnChanges {
  private cliente$?: Subscription;
  public usuario = LoginService.getUsuario();
  public clientePropio?: ICliente;
  public verClientes = true;
  public verActivos = false;
  public crearActivos = false;
  public verColectivos = false;
  public crearColectivos = false;
  public verAlarmas = false;
  public crearAlarmas = false;
  public verEventosTecnicos = false;
  //
  public crearUsuarios = false;
  public verUsuarios = false;
  public crearApikeys = false;
  public verApikeys = false;
  public crearServicios = false;
  public verServicios = false;

  public notificacionesActivas?: boolean;

  public expandedPanel?: number;

  constructor(
    public loading: LoadingService,
    public helper: HelperService,
    public temas: ThemesService,
    private listados: ListadosService,
    private router: Router,
    public pushNotificationsService: PushNotificationsService,
    public listadoEventosVivoService: ListadoEventosVivoService,
  ) {
    this.listadoEventosVivoService.iniciarServicio();
  }

  public editarClientePropio() {
    window.location.href = `/main/clientes/editar/${this.clientePropio?._id}`;
  }

  private async clienteUpdate() {
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<ICliente>('clientePropio', {})
      .subscribe((data) => {
        console.log('clientePropio', data);
        this.clientePropio = data;
        if (data.config) {
          this.temas.setTema(data.config);
          // Setea ver clientes
          const tipos: ITipoCliente[] = ['Mayorista', 'Minorista'];
          const tipoCliente = data.tipoCliente as ITipoCliente;
          this.verClientes = tipos.includes(tipoCliente);
          this.verAlarmas = data.config.moduloAlarmasDomiciliarias?.activo;
          this.crearAlarmas =
            data.config.moduloAlarmasDomiciliarias?.crearDispositivos;
          this.verColectivos = data.config.moduloColectivos?.activo;
          this.crearColectivos =
            data.config.moduloColectivos?.crearDispositivos;
          this.verActivos = data.config.moduloActivos?.activo;
          this.crearActivos = data.config.moduloActivos?.crearDispositivos;
          this.verUsuarios = data.config.moduloAdministracion?.activo;
          this.crearUsuarios = data.config.moduloAdministracion?.crearUsuarios;
          this.verApikeys = data.config.moduloAdministracion?.activo;
          this.crearApikeys = data.config.moduloAdministracion?.crearApikeys;
          this.verServicios = data.config.moduloAdministracion?.activo;
          this.crearServicios =
            data.config.moduloAdministracion?.crearServicios;
          this.verEventosTecnicos = data.config.moduloEventosTecnicos?.activo;
          this.checkPermisos();
        }
      });
    await this.listados.getLastValue('clientePropio', {});
  }

  private checkPermisos() {
    const clienteLocal = LoginService.getCliente();
    // Nivel 0
    const esNivel0 =
      this.clientePropio?.nivel === 0 || clienteLocal.nivel === 0;
    if (esNivel0) {
      this.helper.esNivel0.set(true);
      this.helper.esClienteFinal.set(false);
      this.helper.verModuloActivos.set(true);
      this.helper.verModuloColectivos.set(true);
      this.helper.verModuloAlarmas.set(true);
      this.helper.verModuloAdministracion.set(true);
      this.helper.puedeEditar.set(true);
      this.helper.verModuloEventosTecnicos.set(true);
    } else {
      // Cliente final
      const clienteFinal = this.clientePropio?.tipoCliente === 'Final';
      this.helper.esClienteFinal.set(clienteFinal);
      // Activos
      const activoCliente =
        this.clientePropio?.config?.moduloActivos?.activo ||
        clienteLocal.config.moduloActivos.activo;
      const activoUsuario = this.usuario?.permisos?.moduloActivos;
      this.helper.verModuloActivos.set(activoCliente && activoUsuario);
      // Colectivos
      const colectivoCliente =
        this.clientePropio?.config?.moduloColectivos?.activo ||
        clienteLocal.config.moduloColectivos.activo;
      const colectivoUsuario = this.usuario?.permisos?.moduloColectivos;
      this.helper.verModuloColectivos.set(colectivoCliente && colectivoUsuario);
      // Alarmas
      const alarmaCliente =
        this.clientePropio?.config?.moduloAlarmasDomiciliarias?.activo ||
        clienteLocal.config.moduloAlarmasDomiciliarias.activo;
      const alarmaUsuario = this.usuario?.permisos?.moduloAlarmasDomiciliarias;
      this.helper.verModuloAlarmas.set(alarmaCliente && alarmaUsuario);
      // Administración
      const administracionCliente =
        this.clientePropio?.config?.moduloAdministracion?.activo ||
        clienteLocal.config.moduloAlarmasDomiciliarias.activo;
      const administracionUsuario =
        this.usuario?.permisos?.moduloAdministracion;
      this.helper.verModuloAdministracion.set(
        administracionCliente && administracionUsuario,
      );
      // Eventos Técnicos
      const eventosTecnicosCliente =
        this.clientePropio?.config?.moduloEventosTecnicos?.activo ||
        clienteLocal.config?.moduloEventosTecnicos?.activo;
      const eventosTecnicosUsuario =
        this.usuario?.permisos?.moduloEventosTecnicos;
      this.helper.verModuloEventosTecnicos.set(
        eventosTecnicosCliente && eventosTecnicosUsuario,
      );
      // Editar
      const roles = this.usuario?.roles;
      this.helper.puedeEditar.set(
        roles.includes('Administrador') || roles.includes('Operador'),
      );
    }
  }

  private openPanelInicial() {
    const ruta = this.router.url;
    if (ruta.includes('/main/activos')) {
      this.expandedPanel = 0;
    } else if (ruta.includes('/main/colectivos')) {
      this.expandedPanel = 1;
    } else if (ruta.includes('/main/alarmas')) {
      this.expandedPanel = 2;
    } else if (ruta.includes('/main/configuracion')) {
      this.expandedPanel = 3;
    } else if (ruta.includes('/main/administracion')) {
      this.expandedPanel = 4;
    }
  }

  public async activarNotificaciones() {
    const estado = await this.pushNotificationsService.requestPermission();
    if (estado === 'granted') {
      await this.pushNotificationsService.iniciarPermisos();
      this.notificacionesActivas = estado === 'granted';
    }
  }

  async ngOnInit() {
    this.checkPermisos();
    this.openPanelInicial();
    const [estado] = await Promise.all([
      this.pushNotificationsService.estadoPermisos(),
      this.clienteUpdate(),
    ]);
    this.notificacionesActivas = estado === 'granted';
  }

  async ngOnChanges() {
    await this.clienteUpdate();
  }
}
