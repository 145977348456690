/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

export interface IParamsService {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  private currentParams: IParamsService;
  constructor() {
    this.currentParams = JSON.parse(localStorage.getItem('params'));
  }

  public setParams(param: IParamsService): void {
    localStorage.setItem('params', JSON.stringify(param));
    this.currentParams = param;
  }
  public getParams(): IParamsService {
    return this.currentParams;
  }
}
