import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEvento, IListado, IPopulate, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  IRowIcon,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-eventos-historico',
    templateUrl: './eventos-historico.component.html',
    styleUrl: './eventos-historico.component.scss',
    standalone: false
})
export class EventosHistoricoComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = EventosHistoricoComponent.name;
  public eventosHistorico: IEvento[] = [];
  public eventosHistorico$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEvento>[];
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['valores.titulo', 'valores.mensaje'],
  };
  private activo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idActivo',
    },
    label: 'Activo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarActivos$',
    searchOn: ['identificacion'],
  };
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  private titulo: IFiltroTabla = {
    elementos: [
      'Activo fuera de ubicación',
      'Activo dentro de ubicación',
      'Alarma Activada',
    ],
    filter: {
      field: 'valores.titulo',
    },
    label: 'Evento',
    tipo: 'select',
  };
  private fecha: IFiltroTabla = {
    filter: {
      field: 'fechaCreacion',
    },
    label: 'Fecha',
    tipo: 'dateRange',
  };
  public filtros: IFiltroTabla[] = [
    this.titulo,
    this.fecha,
    this.activo,
    this.cliente,
  ];

  // FILTROS

  public populate: IPopulate[] = [
    {
      path: 'alarma',
      populate: [
        {
          path: 'domicilio',
          select: 'direccion',
        },
        {
          path: 'modelo',
          select: 'marca modelo',
        },
        {
          path: 'comunicador',
          select: 'marca modelo',
        },
      ],
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'tracker',
      select: 'identificacion',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.eventosHistorico$?.unsubscribe();
    this.eventosHistorico$ = this.listados
      .subscribe<IListado<IEvento>>('eventosHistorico', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.eventosHistorico = data.datos;
        console.log(`listado de eventosHistorico`, data);
      });
    await this.listados.getLastValue('eventosHistorico', this.query);
  }
  // Acciones
  public async detalles(evento: IEvento) {
    this.navigator.navigate(['detalles', evento._id], {
      relativeTo: this.route,
    });
  }
  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IEvento>[] = [
      // Fecha
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: ['dd/MM/yyyy HH:mm'],
          },
        },
      },
      // Categoria Codigo evento
      {
        header: {
          label: 'Categoría',
        },
        row: {
          html: (dato) => {
            if (dato.codigoEvento) {
              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.valores?.color) {
                html += `<span style="color: ${dato.valores?.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${dato.codigoEvento}</span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Categoría',
        },
      },
      // Nombre evento
      {
        header: {
          label: 'Evento',
        },
        row: {
          parse(dato) {
            return dato.valores?.titulo;
          },
        },
      },
      // Detalles
      {
        header: {
          label: 'Detalles',
        },
        row: {
          parse(dato) {
            return dato.valores?.['mensaje'];
          },
        },
      },
      // Activo / Alarma
      {
        header: {
          label: 'Activo / Alarma',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.activo) {
              return `${dato.activo?.identificacion}`;
            }
            if (dato.alarma) {
              // let text = `${dato.alarma?.nombre} - ${dato.alarma?.domicilio?.direccion}`;
              let text = `${dato.alarma?.nombre}`;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
          noData: 'Sin asignar',
        },
      },
      // Dispositivo
      {
        header: {
          label: 'Dispositivo',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.tracker) {
              return `${dato.tracker?.identificacion}`;
            }
            if (dato.alarma) {
              let text = '';
              const comunicador = dato.alarma?.comunicador;
              if (comunicador) {
                text = `${comunicador?.marca} ${comunicador?.modelo}`;
              }
              const alarma = dato.alarma.modelo;
              if (alarma) {
                text += ` - ${alarma.marca} ${alarma.modelo}`;
              }
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
          noData: 'Sin identificación',
        },
      },
      // Notificar
      {
        header: {
          label: 'Requiere Atención',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.notificar ? iconCheck : iconClose;
          },
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.eventosHistorico$?.unsubscribe();
  }
}
