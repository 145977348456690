@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <h2 mat-dialog-title cdkDragHandle>
          {{ title }}
        </h2>
      </ng-container>
    </div>

    <!-- Contenido -->
    <div>
      <!-- Nombre de los Codigos -->
      <mat-form-field style="width: 100%">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" required />
      </mat-form-field>
      <!-- Listado de Códigos -->
      <div
        class="mat-elevation-z4"
        style="padding: 0.5em; border-radius: 5px"
        formArrayName="codigos"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <span class="recorrido-titulo">Códigos</span>
        <br />
        <br />
        @for (f of codigosForm.controls; track f; let i = $index) {
          <section
            class="layout"
            [formGroupName]="i"
            style="align-items: baseline"
          >
            <div
              class="row-center grow1"
              style="gap: 1em; margin: 0 8px"
              cdkDrag
            >
              <!-- Drag indicator -->
              <div style="height: 46px">
                <div class="custom-placeholder" *cdkDragPlaceholder></div>
                <mat-icon cdkDragHandle color="accent" style="cursor: move">
                  drag_indicator
                </mat-icon>
              </div>

              <!-- Codigo -->
              <mat-form-field style="max-width: 25%">
                <mat-label>Codigo</mat-label>
                <input matInput formControlName="codigo" />
              </mat-form-field>

              <!-- Descripcion -->
              <mat-form-field>
                <mat-label>Descripcion</mat-label>
                <input matInput formControlName="descripcion" />
              </mat-form-field>

              <!-- Categorias eventos -->
              <ng-select
                [items]="categoriasEventos"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idCategoriaEvento"
                placeholder="Categoría"
                [notFoundText]="'No hay categorías de eventos'"
                multiple="false"
                searchable="true"
                style="min-width: 20%"
                appendTo="body"
              ></ng-select>

              <!-- Mostrar Zonas -->
              @if (tipo === "Alarma") {
                <div class="column" style="white-space: nowrap; height: 68px">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      height: 25px;
                    "
                  >
                    <mat-label>Mostrar Zona</mat-label>
                    <mat-checkbox formControlName="mostrarZona"> </mat-checkbox>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      height: 25px;
                    "
                  >
                    <mat-label>Mostrar Usuario</mat-label>
                    <mat-checkbox formControlName="mostrarUsuario">
                    </mat-checkbox>
                  </div>
                </div>
              }

              <!-- Eliminar -->
              <button
                mat-mini-fab
                color="warn"
                type="button"
                (click)="deleteEvento(i)"
                style="margin-bottom: 22px"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </section>
        }
        <div class="row-center">
          <app-button
            [matType]="'mat-fab extended'"
            color="accent"
            (click)="addCodigo()"
            text="Agregar código"
            icon="add"
          ></app-button>
        </div>
      </div>
    </div>

    <!-- Botones -->
    <div class="row-flex-end" style="gap: 10px; margin-top: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
