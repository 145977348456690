<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content style="min-height: 50px">
  <div [innerHTML]="message"></div>
</mat-dialog-content>

<br />

<mat-dialog-actions align="end" style="gap: 8px">

  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="matDialogRef.close(false)"
    [text]="btnCancelText"
  ></app-button>

  <app-button
    matType="mat-fab extended"
    color="accent"
    (click)="matDialogRef.close(true)"
    [text]="btnOkText"
  ></app-button>

</mat-dialog-actions>
