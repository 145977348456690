import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IActivo } from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TraccarService } from '../../../../../../auxiliares/servicios/http/traccar.service';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';

@Component({
    selector: 'app-setear-odometro',
    templateUrl: './setear-odometro.component.html',
    styleUrls: ['./setear-odometro.component.scss'],
    standalone: false
})
export class SetearOdometroComponent implements OnInit {
  public loading = true;

  public formulario?: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IActivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SetearOdometroComponent>,
    private service: TraccarService,
    private helper: HelperService,
  ) {}

  private createForm(): void {
    this.formulario = this.fb.group({
      hours: [null, Validators.required],
      totalDistance: [null, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private getUpdateData() {
    return {
      deviceId: this.data?.idUnicoTraccar,
      hours: this.formulario?.value.hours,
      totalDistance: this.formulario?.value.totalDistance * 1000,
    };
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getUpdateData();
      await this.service.updateTotalDistanceAndHoursOfTheDevice(data);
      this.helper.notifSuccess('Seteado correctamente');
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.loading = false;
  }
}
