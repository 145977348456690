<div style="padding: 0.5em; min-width: 50%" (click)="$event.stopPropagation()">
  <ng-container *ngIf="!mostrarConfig">
    <!-- Titulo -->
    <div class="row-space-between">
      <!-- Sin Notificaciones para mostrar -->
      <h2 *ngIf="!totalCount" style="padding: 0 0.5em; font-size: 30px">
        Sin Notificaciones
      </h2>

      <!-- Viendo notif sin leer -->
      <h1
        *ngIf="totalCount && !verLeidas"
        style="padding-left: 0.5em; font-size: 20px"
      >
        <span
          style="
            border-radius: 15px;
            padding: 0 15px;
            margin-right: 0.5em;
            background-color: var(--warn-color);
            color: var(--warn-contrast-color);
          "
          >{{ totalCount }}</span
        >
        {{ totalCount === 1 ? "Notificación" : "Notificaciones" }} sin leer
      </h1>

      <!-- Viendo notif leidas -->
      <h1 *ngIf="totalCount && verLeidas" style="padding-left: 0.5em">
        {{ totalCount }}
        {{ totalCount === 1 ? "Notificación" : "Notificaciones" }}
      </h1>

      <div>
        <!-- No Leídas  -->
        <p style="padding-right: 0.5em; margin: 0">
          <small
            style="cursor: pointer"
            [style.color]="'green'"
            (click)="toggleVerTodas()"
          >
            <span>{{ !verLeidas ? "ver leídas" : "ver no leídas" }}</span>
          </small>
        </p>
        <!-- Marcar leídas -->
        <p style="padding-right: 0.5em; margin: 0" *ngIf="totalCount">
          <small
            style="cursor: pointer"
            [style.color]="'red'"
            (click)="marcarTodasComoLeidas()"
          >
            <span>marcar todas como leídas</span>
          </small>
        </p>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>

    <!-- Imagen -->
    <!-- <ng-container *ngIf="!totalCount">
      <img src="assets/images/no_notif.png" alt="" style="width: 400px" />
    </ng-container> -->

    <!-- Notificaciones -->
    <ng-container *ngIf="totalCount" style="padding: min(1vw, 1vh); width: 50%">
      <ng-container *ngFor="let n of notificaciones">
        <div class="row">
          <div
            [ngClass]="{
              'notif-light-leidas': !helper.darkTheme && verLeidas,
              'notif-dark-leidas': helper.darkTheme && verLeidas,
              'notif-no-leidas': !verLeidas,
            }"
            style="border-radius: 10px; padding: min(1vw, 1vh); margin-top: 1%"
          >
            <table>
              <th style="align-content: center; width: 400px">
                <div style="align-items: flex-start">
                  <h3 style="font-size: small; margin: 0; text-align: start">
                    {{ n.titulo }}
                  </h3>
                  <p
                    style="
                      font-size: small;
                      margin: 0;
                      text-align: start;
                      line-height: normal;
                      max-width: 350px;
                    "
                  >
                    {{ n.mensaje }}
                  </p>
                  <p
                    style="
                      margin: 0;
                      font-size: x-small;
                      text-align: start;
                      line-height: normal;
                    "
                    class="fecha-notificacion"
                  >
                    {{ n.fechaCreacion | date: "short" }}
                  </p>
                </div>
              </th>
              <th style="align-content: center; width: 100px">
                <div>
                  <button
                    *ngIf="!verLeidas"
                    mat-icon-button
                    matTooltip="Marcar como leida"
                    (click)="marcarComoLeida(n)"
                  >
                    <mat-icon color="accent">archive</mat-icon>
                  </button>
                  <button
                    *ngIf="verLeidas"
                    mat-icon-button
                    matTooltip="Marar como no leida"
                    (click)="marcarComoNoLeida(n)"
                  >
                    <mat-icon color="accent">unarchive</mat-icon>
                  </button>
                  <button
                    *ngIf="verLeidas"
                    mat-icon-button
                    matTooltip="Eliminar"
                    (click)="eliminar(n)"
                  >
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>
              </th>
            </table>
          </div>
        </div>
      </ng-container>

      <!-- Paginacion -->
      <mat-paginator
        [length]="totalCount"
        [pageSize]="5"
        [pageSizeOptions]="[5]"
        [showFirstLastButtons]="true"
        (page)="pageEvent($event)"
      ></mat-paginator>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="mostrarConfig">
    <app-configurar-notificaciones></app-configurar-notificaciones>
  </ng-container>

  <mat-divider class="divider"></mat-divider>
</div>
