import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { provideHttpClient } from '@angular/common/http';
import { DialogModule } from './dialog/dialog.module';
import { TablaComponent } from './tabla/tabla.component';
import { NgxColorsModule } from 'ngx-colors';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { ButtonComponent } from './button/button.component';
import { AutocompleteDireccionComponent } from './autocomplete-direccion/autocomplete-direccion.component';
import { MostrarServiciosComponent } from './mostrar-servicios/mostrar-servicios.component';
import { DateRangeSelectorComponent } from './date-range-selector/date-range-selector.component';
// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  override upSecondLabel = 'Agregar un segundo';

  /** A label for the down second button (used by screen readers).  */
  override downSecondLabel = 'Restar un segundo';

  /** A label for the up minute button (used by screen readers).  */
  override upMinuteLabel = 'Agregar un minuto';

  /** A label for the down minute button (used by screen readers).  */
  override downMinuteLabel = 'Restar un minuto';

  /** A label for the up hour button (used by screen readers).  */
  override upHourLabel = 'Agregar una hora';

  /** A label for the down hour button (used by screen readers).  */
  override downHourLabel = 'Restar una hora';

  /** A label for the previous month button (used by screen readers). */
  override prevMonthLabel = 'Mes anterior';

  /** A label for the next month button (used by screen readers). */
  override nextMonthLabel = 'Siguiente mes';

  /** A label for the previous year button (used by screen readers). */
  override prevYearLabel = 'Año anterior';

  /** A label for the next year button (used by screen readers). */
  override nextYearLabel = 'Siguiente año';

  /** A label for the previous multi-year button (used by screen readers). */
  override prevMultiYearLabel = 'Últimos 21 años';

  /** A label for the next multi-year button (used by screen readers). */
  override nextMultiYearLabel = 'Próximos 21 años';

  /** A label for the 'switch to month view' button (used by screen readers). */
  override switchToMonthViewLabel = 'Cambiar a vista de mes';

  /** A label for the 'switch to year view' button (used by screen readers). */
  override switchToMultiYearViewLabel = 'Elegir año';

  /** A label for the cancel button */
  override cancelBtnLabel = 'Cancelar';

  /** A label for the set button */
  override setBtnLabel = 'Aceptar';

  /** A label for the range 'from' in picker info */
  override rangeFromLabel = 'Desde';

  /** A label for the range 'to' in picker info */
  override rangeToLabel = 'Hasta';

  /** A label for the hour12 button (AM) */
  override hour12AMLabel = 'AM';

  /** A label for the hour12 button (PM) */
  override hour12PMLabel = 'PM';
}

@NgModule({
  imports: [
    CommonModule,
    TablaComponent,
    ButtonComponent,
    MostrarServiciosComponent,
    AutocompleteDireccionComponent,
    DateRangeSelectorComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DialogModule,
    TablaComponent,
    AutocompleteDireccionComponent,
    // Componentes
    ButtonComponent,
    MostrarServiciosComponent,
    DateRangeSelectorComponent,
    // LIBRERIAS
    NgxColorsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    provideHttpClient(),
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
  ],
})
export class AuxiliaresModule {}
