<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante">
    <app-flotante-alarmas [alarmas]="alarmas?.length"></app-flotante-alarmas>
  </div>
  @if (alarma) {
    <div id="isla" class="ol-isla">
      <app-isla-alarma
        [(alarma)]="alarma"
        (alarmaChange)="onClose()"
      ></app-isla-alarma>
    </div>
  }
</div>
