import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEvento, IFilter, IPopulate, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TratamientoComponent } from '../tratar-evento/tratamiento/tratamiento.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { IRegExpSearch } from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
@Component({
  selector: 'app-eventos-vivo',
  templateUrl: './eventos-vivo.component.html',
  styleUrl: './eventos-vivo.component.scss',
  standalone: false,
})
export class EventosVivoComponent implements OnInit, OnDestroy {
  public modoAgrupacion = localStorage.getItem('modoAgrupacion') || '2';
  public categorias: { nombre: string; color: string; prioridad: number }[] =
    [];
  public entidades: { nombre: string }[] = [];
  public eventosPorCategoria: { [key: string]: IEvento[] } = {};
  public eventosPorEntidad: { [key: string]: IEvento[] } = {};

  public panelExpanded: string;

  //// Tabla nueva
  public name = EventosVivoComponent.name;
  public eventosVivo: IEvento[] = [];
  public eventosVivo$?: Subscription;
  public totalCount = 0;
  public columnas =
    this.tipoCliente() === 'Final'
      ? [
          'categoria',
          'evento',
          'detallesEvento',
          'activo / alarma',
          'estado',
          'fecha',
          'hora',
          'acciones',
        ]
      : [
          'categoria',
          'evento',
          'detallesEvento',
          'activo / alarma',
          'estado',
          'cliente',
          'fecha',
          'hora',
          'acciones',
        ];
  public columnasAgrupadoCategoria =
    this.tipoCliente() === 'Final'
      ? [
          'evento',
          'detallesEvento',
          'activo / alarma',
          'estado',
          'fecha',
          'hora',
          'acciones',
        ]
      : [
          'evento',
          'detallesEvento',
          'activo / alarma',
          'estado',
          'cliente',
          'fecha',
          'hora',
          'acciones',
        ];
  public columnasAgrupadoEntidad =
    this.tipoCliente() === 'Final'
      ? [
          'categoria',
          'evento',
          'detallesEvento',
          'estado',
          'fecha',
          'hora',
          'acciones',
        ]
      : [
          'categoria',
          'evento',
          'detallesEvento',
          'estado',
          'cliente',
          'fecha',
          'hora',
          'acciones',
        ];

  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS

  public filter: IFilter<IEvento> = {
    estado: 'nuevo',
  };
  public populate: IPopulate[] = [
    {
      path: 'configEvento',
    },
    {
      path: 'alarma',
      populate: {
        path: 'domicilio',
        select: 'direccion',
      },
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
  ) {}

  public tipoCliente() {
    return HelperService.getTipoCliente();
  }

  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<IEvento> = JSON.parse(this.query.filter);
      this.query.filter = JSON.stringify(filter);
    }
    this.eventosVivo$?.unsubscribe();
    this.eventosVivo$ = this.listados
      .subscribe<IEvento[]>('eventosEnVivo', this.query)
      .subscribe((data) => {
        this.totalCount = data.length;
        this.eventosVivo = data;

        // Arma el array de categorias unicas
        const nombresCategorias = new Set<string>();
        data.forEach((e) => {
          nombresCategorias.add(e.codigoEvento);
        });
        const categoriasArray: {
          nombre: string;
          color: string;
          prioridad: number;
        }[] = [];
        nombresCategorias.forEach((nombre) => {
          categoriasArray.push({
            nombre,
            color: data.find((e) => e.codigoEvento === nombre)?.valores.color,
            prioridad: data.find((e) => e.codigoEvento === nombre)?.prioridad,
          });
        });
        categoriasArray.sort((a, b) => a.prioridad - b.prioridad);
        this.categorias = categoriasArray;

        // Arma el objeto de eventos por categoria
        this.eventosPorCategoria = {};
        this.categorias.forEach((c) => {
          this.eventosPorCategoria[c.nombre] = data.filter(
            (e) => e.codigoEvento === c.nombre,
          );
        });

        // Arma el objeto de eventos por entidad
        const nombresEntidades = new Set<string>();
        data.forEach((e) => {
          nombresEntidades.add(e.alarma?.nombre || e.activo?.identificacion);
        });
        const entidadesArray: { nombre: string }[] = [];
        nombresEntidades.forEach((nombre) => {
          entidadesArray.push({ nombre });
        });
        entidadesArray.sort((a, b) => a.nombre.localeCompare(b.nombre));
        this.entidades = entidadesArray;

        // Arma el objeto de eventos por entidad
        this.eventosPorEntidad = {};
        this.entidades.forEach((e) => {
          this.eventosPorEntidad[e.nombre] = data.filter(
            (ev) =>
              ev.alarma?.nombre === e.nombre ||
              ev.activo?.identificacion === e.nombre,
          );
        });

        console.log(`listado de eventosEnVivo`, data);
        console.log(`categorias`, this.categorias);
        console.log(`eventosPorCategoria`, this.eventosPorCategoria);
      });
    await this.listados.getLastValue('eventosEnVivo', this.query);
  }

  public ajustarDireccion(text: string) {
    if (!text) return '';
    if (text.length >= 50) {
      text = text.slice(0, 50);
      text += '...';
    }
    return text;
  }

  public getBackgroundColor(e: IEvento) {
    return `background-color: ${e.valores.color}`;
  }
  public async cambioFiltro() {
    await this.listar();
  }

  // Acciones
  public async tratar(evento: IEvento) {
    const dialogs = this.dialog.openDialogs;
    const idAbierto = dialogs.find(
      (d) => d.componentInstance.data._id === evento._id,
    );
    if (!idAbierto) {
      this.dialog.open(TratamientoComponent, {
        data: evento,
        hasBackdrop: false,
        minWidth: '1100px',
        maxWidth: '1500px',
      });
    }
  }

  public cambioModoAgrupacion(modo: MatButtonToggleChange) {
    localStorage.setItem('modoAgrupacion', modo.value);
  }
  // Hooks

  async ngOnInit() {
    Promise.all([this.listar()]);
  }

  async ngOnDestroy() {
    this.eventosVivo$?.unsubscribe();
    this.dialog.closeAll();
  }
}
