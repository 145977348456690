import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ITipoEvento,
  IQueryParam,
  IListado,
  TipoEnvio,
  IUsuario,
  ICategoriaEvento,
  ICodigoDispositivo,
  ICliente,
  ICreateTipoEvento,
  IDispositivoAlarma,
  IActivo,
  ICreateConfigEventoUsuario,
  IUpdateConfigEventoUsuario,
  IConfigEventoUsuario,
} from 'modelos/src';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { ClientesHastaSelectorComponent } from '../../../../auxiliares/clientes-hasta-selector/clientes-hasta-selector.component';
import { TiposEventosService } from '../../../../auxiliares/servicios/http/tipos-eventos.service';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';
import { ConfigEventoUsuarioService } from '../../../../auxiliares/servicios/http/config-evento-usuario.service';

@Component({
    imports: [CommonModule, AuxiliaresModule, ClientesHastaSelectorComponent],
    selector: 'app-crear-editar-config-evento',
    templateUrl: './crear-editar-config-evento.component.html',
    styleUrl: './crear-editar-config-evento.component.scss'
})
export class CrearEditarConfigEventoComponent implements OnInit, OnDestroy {
  //DEMAS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  public id?: string;
  public idTracker?: string;
  public idAlarma?: string;
  public dispositivo?: IDispositivoAlarma | IActivo;
  public form?: FormGroup;
  public title?: string;
  public editar: boolean = false;
  public configEvento?: IConfigEventoUsuario;
  public usuarios: IUsuario[] = [];
  public codigosEvento: ICodigoDispositivo[] = [];
  public categoriasEvento: ICategoriaEvento[] = [];
  public tiposEventos: ITipoEvento[] = [];
  public clienteDispositivo: ICliente;
  public loading: boolean = false;
  public mostrarZona: boolean = false;
  //Subs
  public categorias$: Subscription;
  public tiposEventos$: Subscription;
  public trackers$: Subscription;
  public alarmas$: Subscription;
  public usuarios$?: Subscription;

  public tiposEnvio: TipoEnvio[] = [
    // 'SMS',
    // 'WhatsApp',
    // 'Llamada',
    'Notificacion Push',
  ];
  get configZona() {
    return this.form?.get('configZona') as FormGroup;
  }
  private query: IQueryParam = {};
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: HelperService,
    private service: ConfigEventoUsuarioService,
    private tipoEventoService: TiposEventosService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private listados: ListadosService,
    private paramsService: ParamsService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idTipoEvento: new FormControl(
        this.configEvento?.idTipoEvento,
        Validators.required,
      ),
      idCategoriaEvento: new FormControl(this.configEvento?.idCategoriaEvento),
      notificar: new FormControl(this.configEvento?.notificar || false),
      codigoReportado: new FormControl(this.configEvento?.codigoReportado),
      noDerivar: new FormControl(this.configEvento?.noDerivar || false),
      idEntidad: new FormControl(this.configEvento?.idEntidad || this.id),
      atender: new FormControl(this.configEvento?.atender || false),
      idsClientesQuePuedenAtender: new FormControl(
        this.configEvento?.idsClientesQuePuedenAtender ||
          this.dispositivo?.idsClientesQuePuedenAtender,
      ),
      idsUsuarios: new FormControl(this.configEvento?.idsUsuarios),
      configZona: new FormGroup({
        particion: new FormControl(this.configEvento?.configZona?.particion),
        zona: new FormControl(this.configEvento?.configZona?.zona),
      }),
    });
  }
  public async listarTipoEvento(): Promise<void> {
    const tipo = this.idAlarma ? 'Alarma' : 'Tracker';
    const query: IQueryParam = { filter: JSON.stringify({ categoria: tipo }) };
    this.tiposEventos$?.unsubscribe();
    this.tiposEventos$ = this.listados
      .subscribe<IListado<ITipoEvento>>('tiposEventos', query)
      .subscribe((data) => {
        this.tiposEventos = data.datos;
        console.log(`listado de tiposEventos`, data);
      });
    await this.listados.getLastValue('tiposEventos', query);
  }
  private async listarUsuarios(): Promise<void> {
    const query: IQueryParam = {
      select: 'usuario datosPersonales.telefono permisos',
      sort: 'usuario',
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }

  public async listarCategorias(): Promise<void> {
    this.categorias$?.unsubscribe();
    this.categorias$ = this.listados
      .subscribe<IListado<ICategoriaEvento>>('categoriaEventos', this.query)
      .subscribe((data) => {
        this.categoriasEvento = data.datos;
        console.log(`listado de categoriaEventos`, data);
      });
    await this.listados.getLastValue('categoriaEventos', this.query);
  }

  //
  public async onSubmit() {
    this.loading = true;
    const res = await this.dialogService.confirm(
      `Confirme la acción`,
      `¿Desea ${this.title.toLowerCase()} ?`,
    );
    if (!res) return;
    try {
      const data = this.getData();
      if (this.editar) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Código Personalizado actualizado');
        this.dialog.closeAll();
      } else {
        // // Create
        this.idAlarma
          ? (data.idEntidad = this.idAlarma)
          : (data.idEntidad = this.idTracker);
        await this.service.create(data);
        this.helper.notifSuccess('Código Personalizado creado');
        this.dialog.closeAll();
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
      this.loading = false;
    }
  }

  public async addTag(t: string) {
    const tipoEvento: ICreateTipoEvento = {
      nombre: t,
      categoria: this.idAlarma ? 'Alarma' : 'Tracker',
    };
    const res = await this.tipoEventoService.create(tipoEvento);
    this.form.patchValue({ idTipoEvento: res._id });
  }

  public searchCodigo = (term: string, item: ICodigoDispositivo) => {
    if (item.codigo?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.descripcion?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  public setCategoria(c: ICategoriaEvento) {
    if (c) {
      this.form.patchValue({ notificar: c.notificar });
      this.form.patchValue({ noDerivar: c.noDerivar });
      this.form.patchValue({ atender: c.atender });
    } else {
      this.form.patchValue({ notificar: false });
      this.form.patchValue({ noDerivar: false });
      this.form.patchValue({ atender: false });
    }
  }

  public setIdsClientesQuePuedenAtender(c: string[]) {
    this.form.patchValue({ idsClientesQuePuedenAtender: c });
  }
  public setCodigo(cod: ICodigoDispositivo) {
    if (cod) {
      this.form.patchValue({ idCategoriaEvento: cod.idCategoriaEvento });
      this.setCategoria(
        this.categoriasEvento.find((c) => c._id === cod.idCategoriaEvento),
      );

      if (cod.mostrarZona) this.mostrarZona = cod.mostrarZona;
      else this.mostrarZona = cod.mostrarZona;
    } else {
      this.form.patchValue({ idCategoriaEvento: null });
      this.setCategoria(null);
      this.mostrarZona = false;
    }
  }

  public getData(): ICreateConfigEventoUsuario | IUpdateConfigEventoUsuario {
    const data: ICreateConfigEventoUsuario | IUpdateConfigEventoUsuario =
      this.form?.value;
    return data;
  }

  async ngOnInit() {
    if (this.data?.activo) {
      this.idTracker = this.data?.activo?.idTracker;
      this.dispositivo = this.data?.activo;
      this.clienteDispositivo = this.data?.activo?.cliente;
      this.codigosEvento = this.data?.activo?.tracker?.modelo?.codigos?.codigos;
    }
    if (this.data?.alarma) {
      this.idAlarma = this.data?.alarma?._id;
      this.dispositivo = this.data?.alarma;
      this.clienteDispositivo = this.data?.alarma?.cliente;
      this.codigosEvento = this.data?.alarma?.modelo?.codigos?.codigos;
    }
    if (this.data?.configEvento) {
      this.id = this.data.configEvento.id;
      this.editar = true;
      this.configEvento = this.data?.configEvento;
      this.mostrarZona =
        this.configEvento?.configZona?.zona ||
        this.configEvento?.configZona?.particion
          ? true
          : false;
    } else {
      this.editar = false;
    }
    this.title = this.editar
      ? 'Editar Código Personalizado'
      : 'Crear Código Personalizado';
    this.createForm();
    await Promise.all([
      this.listarTipoEvento(),
      this.listarUsuarios(),
      this.listarCategorias(),
    ]);
    console.log(this.paramsService.getParams());
  }

  public async ngOnDestroy(): Promise<void> {
    this.categorias$?.unsubscribe();
    this.tiposEventos$?.unsubscribe();
    this.usuarios$?.unsubscribe();
    this.trackers$?.unsubscribe();
  }
}
