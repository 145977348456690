@if (!loading) {
  <div class="row-center">
    <span class="subtitle1">Información del activo</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Grupo:</span>
      <span class="dato">{{ activo?.grupo?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Cliente:</span>
      <span class="dato">{{ activo?.cliente?.nombre }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Categoria:</span>
      <span class="dato">{{ activo?.categoria }}</span>
    </div>
  </div>
  @if (activo?.categoria === "Vehículo") {
    <div class="row-center" style="gap: 15px">
      <div class="row-center">
        <span class="subtitle1">Informacion del Vehiculo</span>
      </div>
      <div>
        <span class="datoName" style="padding-right: 5px">Tipo:</span>
        <span class="dato">{{ activo?.vehiculo?.tipo }}</span>
      </div>
      <div>
        <span class="datoName" style="padding-right: 5px">Patente:</span>
        <span class="dato">{{ activo?.vehiculo?.patente }}</span>
      </div>
      <div>
        <span class="datoName" style="padding-right: 5px">Estado:</span>
        <span class="dato">{{ activo?.vehiculo?.estado }}</span>
      </div>
    </div>
  }
  @if (this.reporte) {
    <br />
    <div class="column-center">
      <span class="subtitle1">Ultima Ubicacion Reportada</span>
      <div style="width: 600px; height: 600px">
        <app-mapa-facil [data]="datoMapa"></app-mapa-facil>
      </div>
    </div>
  }
}
