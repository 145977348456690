<section class="layout">
  <div class="mapa">
    <app-mapa-creador
      [dibujar]="['LineString']"
      [(lineString)]="lineString"
      (lineStringChange)="onLineStringChange($event)"
      [color]="formulario?.get('color')?.value"
      [(points)]="points"
      (pointsChange)="onPointsChange($event)"
      [pointLabel]="'Parada #'"
      [checkDistanceFromLineString]="true"
      [indicaciones]="indicaciones"
      [polygons]="terminalesMapa"
    ></app-mapa-creador>
  </div>
  <div class="paradas">
    <mat-accordion
      style="width: 100%"
      cdkDropList
      [cdkDropListData]="paradas"
      (cdkDropListDropped)="drop($event)"
    >
      @for (p of paradas; track $index) {
        <mat-expansion-panel cdkDrag class="drag">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div
                class="row-space-between"
                style="width: 100%; padding-left: 0.5em"
              >
                <span>{{ p.nombre }}</span>
                <div class="row-center">
                  <span>#{{ $index }}</span>
                  <button
                    mat-icon-button
                    (click)="removeParada(p)"
                    type="button"
                  >
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="column-center" style="width: 100%">
            <mat-form-field style="width: 100%">
              <mat-label>Nombre</mat-label>
              <input matInput [(ngModel)]="p.nombre" />
            </mat-form-field>

            <mat-form-field style="width: 100%">
              <mat-label>Destino</mat-label>
              <input matInput [(ngModel)]="p.destino" />
            </mat-form-field>

            <mat-form-field style="width: 100%">
              <mat-label>Por</mat-label>
              <input matInput [(ngModel)]="p.por" />
            </mat-form-field>
            <div class="row-space-evenly" style="width: 100%; gap: 8px">
              <mat-checkbox [(ngModel)]="p.subida">Subida</mat-checkbox>
              <mat-checkbox [(ngModel)]="p.bajada">Bajada</mat-checkbox>
            </div>
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</section>
