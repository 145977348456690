import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IRecorrido,
  ICreateRecorrido,
  IUpdateRecorrido,
} from 'modelos/src';
import { Coordinate } from 'ol/coordinate';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

@Injectable({
  providedIn: 'root',
})
export class RecorridosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IRecorrido>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/recorridos`, { params });
  }

  public getById(id: string): Promise<IRecorrido> {
    return this.http.get(`/recorridos/${id}`);
  }

  public create(dato: ICreateRecorrido): Promise<IRecorrido> {
    return this.http.post(`/recorridos`, dato);
  }

  public update(id: string, dato: IUpdateRecorrido): Promise<IRecorrido> {
    return this.http.put(`/recorridos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/recorridos/${id}`);
  }

  // Herramientas

  public snapToRoadOL(dato: Coordinate[]): Promise<Coordinate[]> {
    return this.http.post(`/osrm/snapToRoadOL`, dato);
  }

  public routeOL(dato: Coordinate[]): Promise<Coordinate[]> {
    return this.http.post(`/osrm/routeOL`, dato);
  }
}
