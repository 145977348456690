<div class="row-center">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    [matBadge]="notificacionesSinLeer ? notificacionesSinLeer : null"
    matBadgePosition="above after"
    matBadgeColor="accent"
    class="material-icons-outlined"
    aria-hidden="false"
  >
    <mat-icon>notifications_active</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu" xPosition="after">
  <app-listado-notificaciones></app-listado-notificaciones>
</mat-menu>
