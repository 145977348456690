import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IApikey,
  ICreateApikey,
  IUpdateApikey,
} from 'modelos/src';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';

@Injectable({
  providedIn: 'root',
})
export class ApikeysService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IApikey>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/apikeys`, { params });
  }

  public getById(id: string): Promise<IApikey> {
    return this.http.get(`/apikeys/${id}`);
  }

  public create(dato: ICreateApikey): Promise<IApikey> {
    return this.http.post(`/apikeys`, dato);
  }

  public update(id: string, dato: IUpdateApikey): Promise<IApikey> {
    return this.http.put(`/apikeys/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/apikeys/${id}`);
  }
}
