import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IUbicacion,
  ICreateUbicacion,
  IUpdateUbicacion,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class UbicacionService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IUbicacion>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/ubicacions`, { params });
  }

  public getById(id: string): Promise<IUbicacion> {
    return this.http.get(`/ubicacions/${id}`);
  }

  public create(dato: ICreateUbicacion): Promise<IUbicacion> {
    return this.http.post(`/ubicacions`, dato);
  }

  public update(id: string, dato: IUpdateUbicacion): Promise<IUbicacion> {
    return this.http.put(`/ubicacions/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/ubicacions/${id}`);
  }
}
