import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IRecorrido } from 'modelos/src';

@Component({
    selector: 'app-flotante',
    templateUrl: './flotante.component.html',
    styleUrl: './flotante.component.scss',
    standalone: false
})
export class FlotanteComponent {
  @Input() recorridos: IRecorrido[] = [];
  @Output() recorridosChange = new EventEmitter<IRecorrido[]>();
  @Input() vehiculos?: number;
  public activosShow: boolean = true;
  public searchTerm: string = '';

  onInput(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  constructor() {}

  public onRecorridoChange(recorridos: IRecorrido[]) {
    this.recorridosChange.emit(recorridos);
  }

  public onOpen(select: NgSelectComponent) {
    select.searchTerm = this.searchTerm;
    select.filter(this.searchTerm);
  }

  public searchRecorridos = (term: string, item: IRecorrido) => {
    if (item.nombre?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.destino?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.nombreFlota?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };
}
