import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingService } from '../servicios/loading.service';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

export interface DateRange {
  start?: string;
  end?: string;
}

@Component({
    selector: 'app-date-range-selector',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    templateUrl: './date-range-selector.component.html',
    styleUrl: './date-range-selector.component.scss'
})
export class DateRangeSelectorComponent {
  @Input() public dateRange?: DateRange;
  @Output() public dateRangeChange = new EventEmitter<DateRange>();
  @Input() public maxDate = new Date();
  @Input() public placeholder = 'Rango';

  get start(): Date {
    return this.dateRange ? new Date(this.dateRange.start) : new Date();
  }

  get end(): Date {
    return this.dateRange ? new Date(this.dateRange.end) : new Date();
  }

  constructor(public loading: LoadingService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onDateTimeChange(e: any) {
    const value = e.value as string[];
    const dateRange: DateRange = {
      start: new Date(value[0]).toISOString(),
      end: new Date(value[1]).toISOString(),
    };
    this.dateRange = dateRange;
    this.dateRangeChange.emit(dateRange);
  }
}
