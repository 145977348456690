import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

import { AsignarVehiculoChoferComponent } from '../asignar-vehiculo-chofer/asignar-vehiculo-chofer.component';
import { UsuariosService } from '../../../administracion/usuarios/usuarios.service';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';

interface IUsuarioTabla extends IUsuario {
  vehiculo?: string;
}
@Component({
    selector: 'app-listado-choferes',
    templateUrl: './listado-choferes.component.html',
    styleUrl: './listado-choferes.component.scss',
    standalone: false
})
export class ListadoChoferesComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoChoferesComponent.name;
  public usuarios: IUsuarioTabla[] = [];

  public vehiculos: IActivo[] = [];

  public totalCount = 0;
  public columnas?: IColumnas<IUsuarioTabla>[];

  //Subs
  public usuarios$?: Subscription;
  public clientes$?: Subscription;
  public vehiculos$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar:
      this.helper.puedeEditar() &&
      HelperService.getConfigCliente().moduloColectivos?.crearDispositivos,
    tooltip: 'Crear Chofer',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['usuario', 'identificacionInterna'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public filtros: IFiltroTabla[] = [];

  public filter: IFilter<IUsuario> = {
    roles: 'Chofer',
  };
  public populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  public filterVehiculos: IFilter<IUsuario> = {};
  public queryVehiculos: IQueryParam = {
    filter: JSON.stringify(this.filterVehiculos),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: UsuariosService,
    private navigator: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public activosService: ActivosService,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([
        this.listar(),
        this.listarClientes(),
        this.listarVehiculos(),
      ]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<IUsuario> = JSON.parse(this.query.filter);
      filter.roles = 'Chofer';
      this.query.filter = JSON.stringify(filter);
    }
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
        this.vehiculosUsuarioTabla();
      });
    await this.listados.getLastValue('usuarios', this.query);
  }

  public async listarVehiculos(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('activos', this.queryVehiculos)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
        this.vehiculosUsuarioTabla();
      });
    await this.listados.getLastValue('activos', this.queryVehiculos);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private vehiculosUsuarioTabla() {
    const map = new Map(this.vehiculos.map((v) => [v?.vehiculo?.idChofer, v]));
    this.usuarios.forEach((u) => {
      const match = map.get(u._id);
      if (match) {
        u.vehiculo = match.identificacion;
      }
    });
  }
  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(usuario: IUsuario) {
    this.navigator.navigate(['editar', usuario._id], {
      relativeTo: this.route,
    });
  }
  // ASIGNAR VEHICULO AL CHOFER
  public asignarVehiculo(data: IUsuario) {
    this.dialog.open(AsignarVehiculoChoferComponent, {
      data,
    });
  }

  public ventanaDocumentos(data: IUsuario) {
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'listar',
      'usuario',
      data._id,
    ]);
  }

  public async verificarChofer(usuario: IUsuario): Promise<void> {
    const veh = this.vehiculos.find((v) => v.vehiculo.idChofer == usuario._id);
    if (veh && veh._id) {
      await this.activosService.update(veh._id, {
        vehiculo: { idChofer: null },
      });
    }
  }

  public async eliminar(usuario: IUsuario) {
    const res = await this.dialogService.confirm(
      'Eliminar Chofer',
      `¿Desea eliminar el chofer ${usuario.usuario}?`,
    );
    if (!res) return;
    try {
      await this.verificarChofer(usuario);
      await this.service.deleteChofer(usuario._id!);
      this.helper.notifSuccess('Chofer eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'usuario',
        },
      },
      {
        header: {
          label: 'Identificación',
          sortable: true,
        },
        row: {
          field: 'identificacionInterna',
        },
      },
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Vehiculo',
          sortable: true,
        },
        row: {
          field: 'vehiculo',
          noData: 'Sin Vehiculo',
        },
      },
    ];
    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        this.columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    this.columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'directions_bus',
            tooltip: 'Asignar Vehiculo',
            click: (dato) => this.asignarVehiculo(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'description',
            tooltip: 'Documentos',
            click: (dato) => this.ventanaDocumentos(dato),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  ngOnDestroy() {
    this.usuarios$?.unsubscribe();
  }
}
