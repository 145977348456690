import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IReporte,
  ICreateReporte,
  IUpdateReporte,
  ICoordenadas,
  ICategoriaActivo,
  TipoVehiculo,
} from 'modelos/src';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http.service';
import { HttpParams } from '@angular/common/http';

export interface IFiltroLastReporte {
  idsActivos?: string[];
  idsTrackers?: string[];
  idsParadas?: string[];
  idsRecorridos?: string[];
  agruparPor?: 'tracker' | 'activo';
  desde?: string;
  hasta?: string;
  includeChildren?: boolean;
  childrenLevel?: number;
  categoriaActivo?: ICategoriaActivo;
  tipoVehiculo?: TipoVehiculo;
}

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IReporte>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes`, { params });
  }

  public getReportesSnappeados(
    queryParams?: IQueryParam,
  ): Promise<ICoordenadas[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes/snapped`, { params });
  }

  public getReportesRecorridoActivos(
    queryParams?: IQueryParam,
  ): Promise<ICoordenadas[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes/RecorridoActivos`, { params });
  }

  public getLastFiltered(query: IFiltroLastReporte): Promise<IReporte[]> {
    let params = new HttpParams();
    if (query.idsActivos?.length && query.idsActivos?.length > 0) {
      params = params.set('idsActivos', JSON.stringify(query.idsActivos));
    }
    if (query.idsTrackers?.length && query.idsTrackers?.length > 0) {
      params = params.set('idsTrackers', JSON.stringify(query.idsTrackers));
    }
    if (query.idsParadas?.length && query.idsParadas?.length > 0) {
      params = params.set('idsParadas', JSON.stringify(query.idsParadas));
    }
    if (query.idsRecorridos?.length && query.idsRecorridos?.length > 0) {
      params = params.set('idsRecorridos', JSON.stringify(query.idsRecorridos));
    }
    if (query.agruparPor) {
      params = params.set('agruparPor', query.agruparPor);
    }
    if (query.categoriaActivo) {
      params = params.set('categoriaActivo', query.categoriaActivo);
    }
    if (query.tipoVehiculo) {
      params = params.set('tipoVehiculo', query.tipoVehiculo);
    }
    if (query.desde) {
      params = params.set('desde', query.desde);
    }
    if (query.hasta) {
      params = params.set('hasta', query.hasta);
    }
    if (query.includeChildren) {
      params = params.set('includeChildren', query.includeChildren.toString());
    } else params = params.set('includeChildren', true);
    if (query.childrenLevel) {
      params = params.set('childrenLevel', query.childrenLevel.toString());
    } else params = params.set('childrenLevel', 0);

    return this.http.get(`/reportes/getLastFiltered`, {
      params,
    });
  }

  public getById(id: string): Promise<IReporte> {
    return this.http.get(`/reportes/${id}`);
  }

  public create(dato: ICreateReporte): Promise<IReporte> {
    return this.http.post(`/reportes`, dato);
  }

  public update(id: string, dato: IUpdateReporte): Promise<IReporte> {
    return this.http.put(`/reportes/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/reportes/${id}`);
  }
}
