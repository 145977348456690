import { Injectable } from '@angular/core';
import {
  IModeloDispositivo,
  IListado,
  IQueryParam,
  IUpdateModeloDispositivo,
  ICreateModeloDispositivo,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ModelosDispositivosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IModeloDispositivo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/modelodispositivos`, { params });
  }

  public getById(id: string): Promise<IModeloDispositivo> {
    return this.http.get(`/modelodispositivos/${id}`);
  }

  public create(dato: ICreateModeloDispositivo): Promise<IModeloDispositivo> {
    return this.http.post(`/modelodispositivos`, dato);
  }

  public update(
    id: string,
    dato: IUpdateModeloDispositivo,
  ): Promise<IModeloDispositivo> {
    return this.http.put(`/modelodispositivos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/modelodispositivos/${id}`);
  }
}
