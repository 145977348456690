import { Component } from '@angular/core';
import { ENV, VERSION } from '../../../../environments/environment';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { LoginService } from '../../login/login.service';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrl: './version.component.scss',
    standalone: false
})
export class VersionComponent {
  public version = VERSION;
  public env = ENV;

  constructor(private dialog: DialogService) {}

  public async logout() {
    const res = await this.dialog.confirm(
      'Cerrar sesión',
      '¿Estás seguro de que deseas cerrar sesión?',
    );
    if (res) {
      LoginService.removeLoginInfo();
      window.location.href = '/';
    }
  }
}
