<mat-card>
  <!-- Header -->
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Content -->
    <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
      <!-- Nombre -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" />
      </mat-form-field>

      <!-- Prioridad -->
      <mat-form-field>
        <mat-label>Prioridad</mat-label>
        <input matInput formControlName="prioridad" type="number" />
      </mat-form-field>

      <!-- Color -->
      <mat-form-field>
        <mat-label>Color</mat-label>
        <input matInput readonly formControlName="color" />
        <ngx-colors
          class="suffix"
          matSuffix
          ngx-colors-trigger
          formControlName="color"
        >
        </ngx-colors>
      </mat-form-field>

      <!-- Sonido -->
      <mat-form-field>
        <mat-label>Sonido</mat-label>
        <mat-select formControlName="sonido">
          @for (s of sonidos; track s) {
            <mat-option [value]="s">{{ s }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- Checkbox -->
      <div class="row-center">
        <!-- Notificar -->
        <div style="margin: 1em">
          <mat-label>Notificación Push</mat-label>
          <mat-checkbox formControlName="notificar"> </mat-checkbox>
        </div>
        <!-- Atender -->
        <div style="margin: 1em">
          <mat-label>Requiere Atención</mat-label>
          <mat-checkbox formControlName="atender"> </mat-checkbox>
        </div>
        <!-- No derivar -->
        <div style="margin: 1em">
          <mat-label> No Derivar</mat-label>
          <mat-checkbox formControlName="noDerivar"> </mat-checkbox>
        </div>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="dialogRef.close()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>

  <mat-progress-bar
    color="accent"
    [mode]="loading ? 'indeterminate' : 'determinate'"
  >
  </mat-progress-bar>
</mat-card>
