import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CategoriaTecnica,
  estadoEventoTecnico,
  IEventoTecnico,
  IFilter,
  IListado,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { EventosTecnicosService } from '../eventos-tecnicos.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../../../login/login.service';
import { CrearTecnicoRapidoComponent } from '../../tecnicos/crear-tecnico-rapido/crear-tecnico-rapido.component';

export interface ICEETData {
  id?: string;
  idAlarma?: string;
  idTracker?: string;
  idActivo?: string;
  categoria?: CategoriaTecnica;
  /**
   * @param idsCQAET - IDs de los clientes que pueden atender el Servicio Técnico
   */
  idsCQAET?: string[];
}

@Component({
  selector: 'app-crear-editar-eventos-tecnicos',
  templateUrl: './crear-editar-eventos-tecnicos.component.html',
  styleUrl: './crear-editar-eventos-tecnicos.component.scss',
  standalone: false,
})
export class CrearEditarEventosTecnicosComponent implements OnInit, OnDestroy {
  public loading = signal(false);
  public data = inject(MAT_DIALOG_DATA) as ICEETData;
  ///
  public form?: FormGroup;

  public evento?: IEventoTecnico;
  public evento$?: Subscription;

  public tecnicos?: IUsuario[];
  public tecnicos$?: Subscription;

  private cliente = LoginService.getCliente();

  public estados: estadoEventoTecnico[] = [
    'Pendiente',
    'Asignado',
    'En Atención',
    'Pendiente de Aprobación',
    'Finalizado',
  ];

  public categorias: CategoriaTecnica[] = ['Alarma', 'Tracker'];

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: EventosTecnicosService,
    private dialog: MatDialog,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      // Selects
      estado: new FormControl(this.evento?.estado || 'Pendiente', [
        Validators.required,
      ]),
      categoria: new FormControl(
        this.evento?.categoria || this.data.categoria,
        [Validators.required],
      ),
      // Inputs
      titulo: new FormControl(this.evento?.titulo, [Validators.required]),
      descripcion: new FormControl(this.evento?.descripcion, [
        Validators.required,
      ]),
      // Ids
      idTracker: new FormControl(this.evento?.idTracker || this.data.idTracker),
      idAlarma: new FormControl(this.evento?.idAlarma || this.data.idAlarma),
      idActivo: new FormControl(this.evento?.idActivo || this.data.idActivo),
      idsClientesQuePuedenAtender: new FormControl(
        this.evento?.idsClientesQuePuedenAtender ||
          this.cliente?.config?.idsClientesQuePuedenAtenderEventosTecnicos,
      ),
      idsClientesAtendiendo: new FormControl(
        this.evento?.idsClientesAtendiendo,
      ),
      idTecnicoAsignado: new FormControl(this.evento?.idTecnicoAsignado),
    });
  }

  private async listar() {
    this.evento$?.unsubscribe();
    this.evento$ = this.listados
      .subscribe<IEventoTecnico>('eventoTecnico', this.data.id!)
      .subscribe((data) => {
        this.evento = data;
        console.log(`listado de eventoTecnico en CREAR EDITAR`, data);
      });
    await this.listados.getLastValue('eventoTecnico', this.data.id!);
  }

  private async listarTecnicos(): Promise<void> {
    const filter: IFilter<IUsuario> = {
      roles: 'Técnico',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'datosPersonales.nombre',
      limit: 0,
      select: 'datosPersonales usuario',
    };
    this.tecnicos$?.unsubscribe();
    this.tecnicos$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.tecnicos = data.datos;
        console.log(`listado de tecnicos`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.data?.id ? 'Editar' : 'Crear'} Servicio Técnico`,
      `¿Desea ${this.data?.id ? 'editar' : 'crear'} Servicio Técnico?`,
    );
    if (!res) return;
    try {
      this.loading.set(true);
      const data = this.form?.value;
      if (this.data.id) {
        // Update
        await this.service.update(this.data.id, data);
        this.helper.notifSuccess('Servicio Técnico actualizado');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Servicio Técnico creado');
      }
      this.dialog.closeAll();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    } finally {
      this.loading.set(false);
    }
  }

  public async onAddTecnico() {
    this.dialog.open(CrearTecnicoRapidoComponent, {});
  }

  /////// HOOKS

  async ngOnInit() {
    await this.listarTecnicos();
    if (this.data?.id) {
      await this.listar();
    }
    this.createForm();
  }

  ngOnDestroy() {
    this.evento$?.unsubscribe();
    this.tecnicos$?.unsubscribe();
  }
}
