import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICodigosDispositivo } from 'modelos/src';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';

@Component({
    imports: [CommonModule, AuxiliaresModule],
    selector: 'app-detalles-codigos-dispositivos',
    templateUrl: './detalles-codigos-dispositivos.component.html',
    styleUrl: './detalles-codigos-dispositivos.component.scss'
})
export class DetallesCodigosDispositivosComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ICodigosDispositivo) {}
}
