import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private helperService: HelperService,
    private router: Router,
    private login: LoginService,
  ) {}

  canActivate() {
    if (this.login.isLogged()) {
      return true;
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }

  canActivateChild() {
    if (this.login.isLogged()) {
      return true;
    } else {
      this.helperService.notifWarn('Debe iniciar sesión');
      return this.router.parseUrl('/login');
    }
  }
}
