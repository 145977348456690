<mat-form-field style="width: 100%">
  <mat-label>Dirección</mat-label>
  <input
    #inputDireccion
    [(ngModel)]="direccion"
    type="text"
    placeholder="Dirección"
    matInput
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect()">
    @for (option of direcciones; track option) {
      <mat-option [value]="option">{{ option }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
