import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DialogService } from '../dialog/dialog.service';
import { HelperService } from '../servicios/helper.service';
import { LoadingService } from '../servicios/loading.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../auxiliares.module';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    imports: [CommonModule, AuxiliaresModule]
})
export class UploadFileComponent {
  @Input() public disabled: boolean = false;
  @Input() public textoBoton: string = 'Subir Imagen';
  @Input() url?: string;
  @Output() loadingFile = new EventEmitter<boolean>();
  @Output() urlChange = new EventEmitter<string>();

  constructor(
    private helper: HelperService,
    private dialogService: DialogService,
    public loading: LoadingService,
  ) {}

  public async setFile(event: Event): Promise<void> {
    const resp = await this.dialogService.confirm(
      'Subir Imagen',
      '¿Está seguro que desea subir la imagen?',
    );
    if (!resp) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = (event as any).target?.files?.item(0);
    if (!file) return;
    const nombreCompleto = file?.name;
    const ext = nombreCompleto?.split('.').pop();
    const name = nombreCompleto?.split('.')[0];
    const nombreImagen = `${name}_${Date.now()}.${ext}`;

    this.loadingFile.emit(true);
    const res = await this.helper.subirImagen(file, nombreImagen);
    this.url = res.url;
    this.urlChange.emit(this.url);
    this.loadingFile.emit(false);
  }
}
