/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { OpenLayersService } from '../servicios/openLayers.service';
import { Feature, Map, View } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import { getVectorContext } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { Coordinate } from 'ol/coordinate';
import { MapaFacilData } from './interfaces';
import VectorSource from 'ol/source/Vector';
import { IReporte } from 'modelos/src';

@Component({
    selector: 'app-mapa-facil',
    imports: [CommonModule, AuxiliaresModule],
    templateUrl: './mapa-facil.component.html',
    styleUrl: './mapa-facil.component.scss'
})
export class MapaFacilComponent implements OnInit, OnChanges {
  @Input() public data?: MapaFacilData;
  @Output() public selectedData? = new EventEmitter<any>();
  /// OPENLAYERS
  public map?: Map;
  public tileLayer = OpenLayersService.mapTile();
  // Vehiculos
  public vehiculosVectorLayer = OpenLayersService.vehiculosVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  public hayVehiculos = false;
  // Paradas
  public paradasVectorLayer = OpenLayersService.paradasVectorLayer();
  public paradasTextVectorLayer = OpenLayersService.paradasTextVectorLayer();
  public hayParadas = false;
  /// Punto Simple
  public puntoSimpleLayer = OpenLayersService.ubicacionVectorLayer();
  public hayPuntosSimples = false;
  /// LineStrings
  /// ------ Polylines
  public polylineVectorOptions = OpenLayersService.polylineVectorLayer();
  public hayPolylines = false;
  /// ------ Activos
  public hayPuntosActivos = false;
  public ubicacionLayer = OpenLayersService.smallUbicacionVectorLayer();
  public ubicacionLayerAgrupado =
    OpenLayersService.smallUbicacionAgrupadoVectorLayer();
  public endActivoLayer = OpenLayersService.activoVectorLayer();
  /// TRIP
  public startLayer = OpenLayersService.startTripLayer();
  public endLayer = OpenLayersService.endTripLayer();
  public geoMarkerLayer = OpenLayersService.geoMarkerLayer();

  public currentTrip: LineString | undefined;
  private lastTime = 0;
  private distance = 0;
  private animating = false;
  public speed = 30;
  constructor() {}

  private async initMap() {
    if (!this.data) {
      return;
    }
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'mapa-facil',
      controls: [],
      layers: [
        this.tileLayer,
        this.polylineVectorOptions,
        this.paradasVectorLayer,
        this.paradasTextVectorLayer,
        this.orientationVectorOptions,
        this.vehiculosVectorLayer,
        this.puntoSimpleLayer,
        // Activo reportes pasados y actual
        this.ubicacionLayer,
        this.ubicacionLayerAgrupado,
        this.endActivoLayer,
        // Trip
        this.startLayer,
        this.endLayer,
        this.geoMarkerLayer,
      ],
      view: new View({
        center:
          this.data.center || (await OpenLayersService.getCurrentPosition()),
        zoom: this.data.zoom || 16,
      }),
    });

    if (this.data.markers) {
      this.addMarkers();
    }

    if (this.data.polylines) {
      this.addPolylines();
    }
    if (this.data.polylinesActivos) {
      this.addPolylinesActivos();
    }
    // if (this.data.polygons) {
    //   this.addPolygons();
    // }
    await this.handleClick();
  }

  private addMarkers() {
    if (!this.data?.markers) return;
    for (const m of this.data.markers) {
      if (m.label === 'vehiculo') {
        // Bus
        const source = this.vehiculosVectorLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        // Compass
        const orientationSource = this.orientationVectorOptions.getSource();
        if (!orientationSource) continue;
        const orientationFeature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        let s: Style;
        if (m.orientation) {
          const radian = (m.orientation * Math.PI) / 180;
          s = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
        } else {
          s = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
        }
        orientationFeature.setStyle(s);
        orientationSource.addFeature(orientationFeature);
        this.hayVehiculos = true;
      }
      if (m.label === 'parada') {
        // Icon
        const source = this.paradasVectorLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        // Text
        if (!m.text) continue;
        const textSource = this.paradasTextVectorLayer.getSource();
        if (!textSource) continue;
        const textFeature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        const text = new Text({
          text: `#${m.text}`,
          font: 'bold 12px sans-serif',
          offsetY: 5,
        });
        textFeature.setStyle(new Style({ text }));
        textSource.addFeature(textFeature);
        this.hayParadas = true;
      }
      if (m.label === 'puntoSimple') {
        // Icon
        const source = this.puntoSimpleLayer.getSource();
        if (!source) continue;
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(m.coor),
        });
        source.addFeature(feature);
        if (!this.data.center) this.map?.getView().setCenter(m.coor);
        this.hayPuntosSimples = true;
      }
    }
  }

  private addPolylines() {
    if (!this.data?.polylines) return;
    let centrar;
    for (const p of this.data.polylines) {
      if (!p.points) return;
      const source = this.polylineVectorOptions.getSource();
      if (!source) return;
      const lineStringFeature: Feature<Geometry> = new Feature({
        geometry: new LineString(p.points),
      });
      const s = new Style({
        stroke: new Stroke({
          color: p.color || 'red',
          width: p.width || 4,
          lineDash: p.dash ? [4, 8] : undefined,
        }),
      });
      lineStringFeature.setStyle(s);
      this.hayPolylines = true;
      if (p.arrow)
        lineStringFeature.setStyle(this.styleFunctionArrow(lineStringFeature));
      source.addFeature(lineStringFeature);
      // TRIP
      if (p.trip) {
        this.crearTrip(p.points);
      }
      centrar = p.centrar;
    }
    if (centrar === true) {
      this.setBounds(this.polylineVectorOptions.getSource());
    }
  }

  private addPolylinesActivos() {
    if (!this.data?.polylinesActivos) return;
    let centrar;
    for (const p of this.data.polylinesActivos) {
      if (!p.reportes) return;
      const source = this.polylineVectorOptions.getSource();
      if (!source) return;
      let points: Coordinate[] = p.reportes.map((r) => {
        return OpenLayersService.lonLatToCoordinate(
          r.geojson.coordinates[0],
          r.geojson.coordinates[1],
        );
      });
      points = points.filter((p) => p !== null);
      const lineStringFeature: Feature<Geometry> = new Feature({
        geometry: new LineString(points),
      });
      const s = new Style({
        stroke: new Stroke({
          color: p.color || 'red',
          width: p.width || 4,
          lineDash: p.dash ? [4, 8] : undefined,
        }),
      });
      lineStringFeature.setStyle(s);
      this.hayPolylines = true;
      if (p.arrow)
        lineStringFeature.setStyle(this.styleFunctionArrow(lineStringFeature));
      source.addFeature(lineStringFeature);
      // Mostrar puntos de los reportes
      this.hayPuntosActivos = true;
      if (p.reportes && p.reporteAcumulado)
        this.showPointsAgrupado(p.reportes, p.reporteAcumulado);
      else if (p.reportes) this.showPoints(p.reportes);
      centrar = p.centrar;
    }
    if (centrar === true) {
      this.setBounds(this.polylineVectorOptions.getSource());
    }
  }
  //Mostrar los puntos del recorrido, y el ultimo activo destacado
  private showPoints(reportes: IReporte[]) {
    const ubicacionSource = this.ubicacionLayer.getSource();
    if (!ubicacionSource) return;
    reportes.map((r, i) => {
      const ubicacion = new Feature({
        geometry: new Point(
          OpenLayersService.lonLatToCoordinate(
            r.geojson.coordinates[0],
            r.geojson.coordinates[1],
          ),
        ),
        data: r,
        tipo: 'puntoActivo',
      });
      if (i === reportes.length - 1) {
        const endActivoLayer = this.endActivoLayer.getSource();
        if (endActivoLayer) {
          endActivoLayer.addFeature(ubicacion);
        } else {
          ubicacionSource.addFeature(ubicacion);
        }
      } else {
        ubicacionSource.addFeature(ubicacion);
      }
    });
  }

  //Mostrar los puntos del recorrido con diferencia si esta agrupado o no , y el ultimo activo destacado
  private showPointsAgrupado(reportes: IReporte[], reporteAcumulado: number[]) {
    const ubicacionSource = this.ubicacionLayer.getSource();
    if (!ubicacionSource) return;
    const ubicacionAgrupadoSource = this.ubicacionLayerAgrupado.getSource();
    if (!ubicacionAgrupadoSource) return;
    reportes.map((r, i) => {
      const ubicacion = new Feature({
        geometry: new Point(
          OpenLayersService.lonLatToCoordinate(
            r.geojson.coordinates[0],
            r.geojson.coordinates[1],
          ),
        ),
        data: r,
        tipo: 'puntoActivo',
      });
      if (i === reportes.length - 1) {
        const endActivoLayer = this.endActivoLayer.getSource();
        if (endActivoLayer) {
          endActivoLayer.addFeature(ubicacion);
        } else {
          if (reporteAcumulado[i] > 1)
            ubicacionAgrupadoSource.addFeature(ubicacion);
          else ubicacionSource.addFeature(ubicacion);
        }
      } else {
        if (reporteAcumulado[i] > 1)
          ubicacionAgrupadoSource.addFeature(ubicacion);
        ubicacionSource.addFeature(ubicacion);
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    this.map?.getView().fit(extent, { padding: [50, 50, 50, 50] });
  }

  private crearTrip(trip: Coordinate[]) {
    this.map?.getView().setCenter(trip[0]);
    this.currentTrip = new LineString(trip);

    this.addStartAndEnd(this.currentTrip);

    this.addGeoMarker(this.currentTrip);
    //
    this.startAnimation();
  }

  private addStartAndEnd(route: LineString) {
    /// Start
    const startSource = this.startLayer.getSource();
    if (!startSource) return;
    const start = new Point(route.getFirstCoordinate());
    const startMarker = new Feature({
      geometry: start,
    });
    startSource.addFeature(startMarker);
    /// End
    const endSource = this.endLayer.getSource();
    if (!endSource) return;
    const end = new Point(route.getLastCoordinate());
    const endMarker = new Feature({
      geometry: end,
    });
    endSource.addFeature(endMarker);
  }

  private addGeoMarker(route: LineString) {
    const source = this.geoMarkerLayer.getSource();
    if (!source) return;
    const position = new Point(route.getFirstCoordinate());
    const geoMarker = new Feature({
      geometry: position,
    });
    source.addFeature(geoMarker);
  }

  private moveFeature(event: RenderEvent) {
    if (!this.animating) return;
    const time = event.frameState?.time;
    const elapsedTime = time! - this.lastTime;

    const position = this.geoMarkerLayer
      ?.getSource()
      ?.getFeatures()[0]
      .getGeometry() as Point;

    this.distance = (this.distance + (this.speed * elapsedTime) / 1e6) % 2;
    this.lastTime = time!;
    if (!this.currentTrip) return;
    const currentCoordinate = this.currentTrip.getCoordinateAt(
      this.distance > 1 ? 2 - this.distance : this.distance,
    );
    position?.setCoordinates(currentCoordinate);
    const vectorContext = getVectorContext(event);
    vectorContext.drawGeometry(position);
    // tell OpenLayers to continue the postrender animation
    this.map?.render();
    if (this.distance > 1) {
      this.distance = 0;
      position.setCoordinates(this.currentTrip.getFirstCoordinate());
    }
  }

  private startAnimation() {
    this.animating = true;
    this.lastTime = Date.now();
    this.geoMarkerLayer.on('postrender', (e) => this.moveFeature(e));
  }

  private stopAnimation() {
    this.animating = false;
    this.currentTrip = undefined;
    this.lastTime = 0;
    this.geoMarkerLayer.un('postrender', (e) => this.moveFeature(e));
  }

  private styleFunctionArrow(feature: Feature<Geometry>) {
    const geometry = feature.getGeometry() as LineString;
    const oldStyles = feature.getStyle() as Style;
    const stroke = oldStyles.getStroke();
    const styles = [
      // linestring
      new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
          lineDash: stroke?.getLineDash() ? [4, 8] : undefined,
        }),
      }),
    ];

    geometry?.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'assets/map/arrow.png',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        }),
      );
    });

    return styles;
  }

  private async reloadLayers() {
    if (!this.map) return;
    this.stopAnimation();
    this.map.removeLayer(this.vehiculosVectorLayer);
    this.map.removeLayer(this.paradasVectorLayer);
    this.map.removeLayer(this.paradasTextVectorLayer);
    this.map.removeLayer(this.polylineVectorOptions);
    this.map.removeLayer(this.ubicacionLayer);
    this.map.removeLayer(this.ubicacionLayerAgrupado);
    this.map.removeLayer(this.endActivoLayer);
    this.map.removeLayer(this.puntoSimpleLayer);
    // Trip
    this.map.removeLayer(this.startLayer);
    this.map.removeLayer(this.endLayer);
    this.map.removeLayer(this.geoMarkerLayer);

    this.vehiculosVectorLayer = OpenLayersService.vehiculosVectorLayer();
    this.paradasVectorLayer = OpenLayersService.paradasVectorLayer();
    this.paradasTextVectorLayer = OpenLayersService.paradasTextVectorLayer();
    this.polylineVectorOptions = OpenLayersService.polylineVectorLayer();
    this.ubicacionLayer = OpenLayersService.smallUbicacionVectorLayer();
    this.ubicacionLayerAgrupado =
      OpenLayersService.smallUbicacionAgrupadoVectorLayer();
    this.endActivoLayer = OpenLayersService.activoVectorLayer();
    this.puntoSimpleLayer = OpenLayersService.ubicacionVectorLayer();
    // Trip
    this.startLayer = OpenLayersService.startTripLayer();
    this.endLayer = OpenLayersService.endTripLayer();
    this.geoMarkerLayer = OpenLayersService.geoMarkerLayer();

    this.map.addLayer(this.vehiculosVectorLayer);
    this.map.addLayer(this.paradasVectorLayer);
    this.map.addLayer(this.paradasTextVectorLayer);
    this.map.addLayer(this.polylineVectorOptions);
    this.map.addLayer(this.ubicacionLayer);
    this.map.addLayer(this.ubicacionLayerAgrupado);
    this.map.addLayer(this.endActivoLayer);
    this.map.addLayer(this.puntoSimpleLayer);
    // Trip
    this.map.addLayer(this.startLayer);
    this.map.addLayer(this.endLayer);
    this.map.addLayer(this.geoMarkerLayer);

    this.addMarkers();
    this.addPolylines();
    this.addPolylinesActivos();

    this.map.render();
  }

  public layerToggle(layer: string) {
    if (!this.map) return;
    if (layer === 'vehiculos') {
      this.vehiculosVectorLayer.setVisible(
        !this.vehiculosVectorLayer.getVisible(),
      );
      this.orientationVectorOptions.setVisible(
        !this.orientationVectorOptions.getVisible(),
      );
    }

    if (layer === 'paradas') {
      this.paradasVectorLayer.setVisible(!this.paradasVectorLayer.getVisible());
      this.paradasTextVectorLayer.setVisible(
        !this.paradasTextVectorLayer.getVisible(),
      );
    }

    if (layer === 'puntosActivos') {
      this.ubicacionLayer.setVisible(!this.ubicacionLayer.getVisible());
    }

    if (layer === 'puntosSimples') {
      this.puntoSimpleLayer.setVisible(!this.puntoSimpleLayer.getVisible());
    }

    if (layer === 'polylines') {
      this.polylineVectorOptions.setVisible(
        !this.polylineVectorOptions.getVisible(),
      );
    }

    if (layer === 'trip') {
      this.startLayer.setVisible(!this.startLayer.getVisible());
      this.endLayer.setVisible(!this.endLayer.getVisible());
      this.geoMarkerLayer.setVisible(!this.geoMarkerLayer.getVisible());
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'puntoActivo') {
              /// ES UN PUNTOACTIVO
              return feature;
            }
            /// ES OTRA COSA
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );
      if (!feature) return;
      const data = feature.get('data') as IReporte;
      if (feature.get('tipo') === 'puntoActivo') {
        this.selectedData.emit(data);
      }
    });
  }

  /// Hooks

  async ngOnInit(): Promise<void> {
    this.map?.dispose();
    await this.initMap();
  }

  async ngOnChanges(c: SimpleChanges): Promise<void> {
    if (c['data'].currentValue !== c['data'].previousValue) {
      this.reloadLayers();
    }
  }
}
