<div class="flotante-contenedor">
  @if (vehiculos) {
    <div class="flotante-vehiculos">
      <span>Vehiculos:</span>
      <span>{{ vehiculos }}</span>
    </div>
  }
  <div class="flotante-recorridos">
    @if (recorridos && recorridos.length > 0) {
      <div class="grow1">
        <div class="row-center">
          <ng-select
            #select
            [items]="recorridos"
            placeholder="Recorridos"
            style="width: 100%; margin: 0"
            multiple
            appendTo="body"
            bindLabel="nombre"
            [searchFn]="searchRecorridos"
            (change)="onRecorridoChange($event)"
            closeOnSelect="false"
            [clearSearchOnAdd]="false"
            (input)="onInput($any($event.target).value)"
            (open)="onOpen(select)"
          >
            <!-- (open)="$any(select).filterValue = searchTerm" -->
            <ng-template ng-option-tmp let-item="item">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 0.5em;
                "
              >
                <p>
                  <strong>{{ item.nombre }}</strong>
                  @if (item.grupo?.nombre) {
                    <span>
                      <strong> - Línea: </strong> {{ item.grupo?.nombre }}
                    </span>
                  }
                  @if (item.por) {
                    <span> <strong>Por: </strong> {{ item.por }} </span>
                  }
                  @if (item.destino) {
                    <span> <strong>A: </strong> {{ item.destino }} </span>
                  }
                </p>
                <ngx-colors
                  ngx-colors-trigger
                  disabled
                  [(ngModel)]="item.color"
                ></ngx-colors>
              </div>
            </ng-template>
          </ng-select>

          <!-- <mat-form-field style="width: 100%" class="no-hint">
            <mat-label>Recorridos</mat-label>
            <mat-select
              [multiple]="true"
              (valueChange)="onRecorridoChange($event)"
            >
              @for (r of recorridos; track r) {
                <mat-option [value]="r">
                  <div class="row-center" style="gap: 15px">
                    <strong>Nombre:</strong> {{ r.nombre }}
                    @if (r.grupo?.nombre) {
                      <strong>Grupo:</strong> {{ r.grupo?.nombre }}
                    }
                    @if (r.por) {
                      <strong>Por:</strong> {{ r.por }}
                    }
                    @if (r.destino) {
                      <strong>A:</strong> {{ r.destino }}
                    }
                    <ngx-colors
                      ngx-colors-trigger
                      disabled
                      [(ngModel)]="r.color"
                    ></ngx-colors>
                  </div>
                </mat-option>
              }
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
    } @else {
      <div class="grow1">
        <span class="no-info" style="margin-left: 1em"> Sin Recorridos </span>
      </div>
    }
  </div>
</div>
