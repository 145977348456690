<app-tabla
  [totalCount]="totalCount"
  [datos]="notas"
  [columnas]="columnas"
  [name]="name"
  [search]="search"
  [(query)]="query"
  (queryChange)="listar()"
  [paginatorArriba]="false"
  [showHijos]="false"
  [crear]="botonCrear"
></app-tabla>
