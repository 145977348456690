<!-- Título -->
<div class="row-center">
  <span class="title1">{{ tracker?.nombre }}</span>
</div>
<mat-tab-group
  [(selectedIndex)]="selectedTab"
  (selectedTabChange)="tabChanged($event)"
>
  <mat-tab label="Info">
    <ng-template matTabContent>
      <app-detalles-tracker-info></app-detalles-tracker-info>
    </ng-template>
  </mat-tab>
  <mat-tab label="Historial Eventos">
    <ng-template matTabContent>
      <app-detalles-tracker-eventos></app-detalles-tracker-eventos>
    </ng-template>
  </mat-tab>
  <mat-tab label="Códigos Eventos">
    <ng-template matTabContent>
      <app-listado-configs-eventos
        [dispositivo]="tracker"
        [tipo]="'Tracker'"
      ></app-listado-configs-eventos>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.trackers()"
    text="Volver"
    icon="arrow_back"
  ></app-button>
</div>
