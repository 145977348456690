import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DespachosComponent } from './despachos/despachos.component';
import { ListadoComponent } from './listado/listado.component';

const routes: Routes = [
  { path: '', component: DespachosComponent },
  { path: 'listado', component: ListadoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DespachosRoutingModule {}
