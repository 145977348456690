import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuariosService } from '../usuarios.service';
import {
  IActivo,
  ICliente,
  ICreateUsuario,
  IDispositivoAlarma,
  IListado,
  IQueryParam,
  IUpdateUsuario,
  IUsuario,
  Rol,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { MatDialogRef } from '@angular/material/dialog';

interface DatoAgrupado {
  nombre: string;
  grupo: string;
  id: string;
}

@Component({
  selector: 'app-crear-editar-usuario',
  templateUrl: './crear-editar-usuario.component.html',
  styleUrl: './crear-editar-usuario.component.scss',
  standalone: false,
})
export class CrearEditarUsuarioComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public roles: Rol[] = ['Administrador', 'Operador', 'Chofer', 'Consultor'];
  // Sexos según Fer
  public sexos = [
    { label: 'Masculino', value: true },
    { label: 'Femenino', value: false },
  ];
  public usuario?: IUsuario;
  public usuario$?: Subscription;

  public clientes?: ICliente[];
  public clientes$?: Subscription;
  public activos?: IActivo[];
  public activos$?: Subscription;
  public alarmas?: IDispositivoAlarma[];
  public alarmas$?: Subscription;

  public entidades: DatoAgrupado[] = [];
  public selected: string[] = [];

  get datosPersonales() {
    return this.form?.get('datosPersonales') as FormGroup;
  }

  get permisos() {
    return this.form?.get('permisos') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarUsuarioComponent>,
    private service: UsuariosService,
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private paramsService: ParamsService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      usuario: new FormControl(this.usuario?.usuario, Validators.required),
      hash: new FormControl(
        null,
        !this.usuario ? Validators.required : undefined,
      ),
      roles: new FormControl(this.usuario?.roles, Validators.required),
      idCliente: new FormControl(this.usuario?.idCliente),
      datosPersonales: new FormGroup({
        nombre: new FormControl(this.usuario?.datosPersonales?.nombre),
        dni: new FormControl(this.usuario?.datosPersonales?.dni),
        sexo: new FormControl(this.usuario?.datosPersonales?.sexo),
        email: new FormControl(this.usuario?.datosPersonales?.email),
        direccion: new FormControl(this.usuario?.datosPersonales?.direccion),
        pais: new FormControl(this.usuario?.datosPersonales?.pais),
        telefono: new FormControl(this.usuario?.datosPersonales?.telefono),
        fechaNacimiento: new FormControl(
          this.usuario?.datosPersonales?.fechaNacimiento,
        ),
      }),
      permisos: new FormGroup({
        idsEntidades: new FormControl(this.usuario?.permisos?.idsEntidades),
        moduloColectivos: new FormControl(
          this.usuario?.permisos?.moduloColectivos !== false,
        ),
        moduloAlarmasDomiciliarias: new FormControl(
          this.usuario?.permisos?.moduloAlarmasDomiciliarias !== false,
        ),
        moduloActivos: new FormControl(
          this.usuario?.permisos?.moduloActivos !== false,
        ),
        moduloAdministracion: new FormControl(
          this.usuario?.permisos?.moduloAdministracion !== false,
        ),
        moduloEventosTecnicos: new FormControl(
          this.usuario?.permisos?.moduloEventosTecnicos !== false,
        ),
      }),
    });
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarActivos() {
    const query: IQueryParam = {
      select: 'identificacion categoria',
      includeChildren: true,
    };
    this.activos$?.unsubscribe();
    this.activos$ = this.listados
      .subscribe<IListado<IActivo>>('activos', query)
      .subscribe((data) => {
        this.activos = data.datos;
        console.log(`listado de activos`, data);
      });
    await this.listados.getLastValue('activos', query);
  }

  private async listarAlarmas() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<IListado<ICliente>>('dispositivosAlarmas', query)
      .subscribe((data) => {
        this.alarmas = data.datos;
        console.log(`listado de alarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }

  private armarGrupos() {
    for (const a of this.activos) {
      const d: DatoAgrupado = {
        nombre: a.identificacion,
        id: a._id,
        grupo: a.categoria,
      };
      this.entidades.push(d);
    }
    for (const a of this.alarmas) {
      const d: DatoAgrupado = {
        nombre: a.nombre,
        id: a._id,
        grupo: 'Alarma',
      };
      this.entidades.push(d);
    }
  }

  public onEntidadesChange(dato: DatoAgrupado[]) {
    for (const e of dato) {
      if (e.grupo === 'Alarma') {
        this.permisos?.get('moduloAlarmasDomiciliarias')?.setValue(true);
      }
      if (e.grupo === 'Normal') {
        this.permisos?.get('moduloActivos')?.setValue(true);
      }
      if (e.grupo === 'Vehículo') {
        this.permisos?.get('moduloColectivos')?.setValue(true);
      }
    }
  }

  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.usuario?._id) {
        // Update
        await this.service.update(this.usuario?._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateUsuario | IUpdateUsuario {
    const data: ICreateUsuario | IUpdateUsuario = this.form?.value;
    return data;
  }

  async ngOnInit() {
    const editando = this.paramsService.getParams()['editando'];
    if (editando) {
      this.usuario = this.paramsService.getParams()['usuario'];
    }
    this.title = editando ? `Editar ${this.usuario.usuario}` : 'Crear Usuario';
    this.createForm();

    await Promise.all([
      this.listarClientes(),
      this.listarActivos(),
      this.listarAlarmas(),
    ]);
    this.armarGrupos();
  }

  ngOnDestroy(): void {
    this.usuario$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.activos$?.unsubscribe();
    this.alarmas$?.unsubscribe();
  }
}
