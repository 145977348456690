import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { estadoEvento, ICreateTratamientoEvento } from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { TratamientoEventosService } from '../../../../../auxiliares/servicios/http/tratamientos-eventos.service';

@Component({
    selector: 'app-agregar-nota',
    templateUrl: './agregar-nota.component.html',
    styleUrl: './agregar-nota.component.scss',
    standalone: false
})
export class AgregarNotaComponent implements OnInit {
  public loading: boolean = true;
  public form?: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { idsEventos: string[]; estado: estadoEvento },
    public helper: HelperService,
    private service: TratamientoEventosService,
    public dialogRef: MatDialogRef<AgregarNotaComponent>,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idsEventos: new FormControl(this.data?.idsEventos),
      nota: new FormControl(),
      notaInterna: new FormControl(),
      estado: new FormControl(this.data?.estado),
    });
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      await this.service.create(data);
      this.helper.notifSuccess('Creado correctamente');
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateTratamientoEvento {
    const data: ICreateTratamientoEvento = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.createForm();
    this.loading = false;
  }
}
