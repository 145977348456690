@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ usuario?.usuario }}</span>
      } @else {
        <span class="titulo">Crear Chofer</span>
      }
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos de Chofer </span>
      </div>
      <!-- Nombre y Contraseña -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="usuario" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Identificación Interna</mat-label>
            <input matInput formControlName="identificacionInterna" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="hash" />
          </mat-form-field>
        </div>
      </section>

      <!-- Cliente // Roles -->
      <section class="column-center" style="width: 100%; gap: 16px">
        @if (!id) {
          <ng-select
            [items]="clientes"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idCliente"
            placeholder="Cliente"
            [notFoundText]="'No hay clientes creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
          </ng-select>
        }
      </section>
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
      [formGroup]="datosPersonales"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos Personales </span>
      </div>
      <!-- Nombre - DNI - SEXO -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>DNI</mat-label>
            <input matInput formControlName="dni" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sexo">
              @for (s of sexos; track s) {
                <mat-option [value]="s.value">{{ s.label }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </section>

      <!-- EMAIL - DIRECCIÓN - PAÍS -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="direccion" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>País</mat-label>
            <input matInput formControlName="pais" />
          </mat-form-field>
        </div>
      </section>

      <!-- TELÉFONO - FECHA DE NACIMIENTO -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="telefono" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Fecha de Nacimiento</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="fechaNacimiento"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </section>
      <div class="row-center" style="width: 100%; gap: 15px">
        <app-upload-file
          (urlChange)="onUrlChange($event)"
          textoBoton="Subir Foto"
        ></app-upload-file>

        @if (datosPersonales.get("foto")?.value) {
          <div class="preview-container">
            <div class="preview" [style.background-image]="getFoto()"></div>
          </div>
        }
      </div>
    </div>

    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
