<app-tabla
  [totalCount]="totalCount"
  [datos]="eventosEspera"
  [columnas]="columnas"
  [name]="name"
  [pageSizeOptions]="helper.pageSizeOptions"
  [search]="search"
  [(query)]="query"
  (queryChange)="listar()"
  [paginatorArriba]="false"
  [showHijos]="true"
  [filtros]="filtros"
></app-tabla>
