<!-- Filtro y Columnas -->
<div class="filtro-container align-end hideScroll">
  <!-- Filtro -->
  @if (filtros) {
    <app-filtro
      style="width: 100%"
      [search]="search"
      [showHijos]="showHijos"
      [filtros]="filtros"
      [(query)]="query"
      (queryChange)="filterChange()"
    >
    </app-filtro>
  }
  <!-- Columnas -->
  @if (columnas) {
    <app-column-select
      [columnas]="columnas"
      (columnsDefChange)="columnsChange($event)"
      [saveName]="name"
      [buttonColumns]="buttonColumns"
    >
    </app-column-select>
  }

  <!-- Crear - Icono Imagen -->
  @if (crear?.mostrar && crear?.tipo === "img") {
    <img
      src="assets/iconos/mas.png"
      alt="crear"
      style="width: 59px; cursor: pointer"
      [matTooltip]="crear?.tooltip!"
      (click)="crear?.accion()"
    />
  }

  <!-- Crear - Icono Material -->
  @if (crear?.mostrar && crear?.tipo === "material" && crear?.icon) {
    <app-button
      matType="mat-fab"
      color="accent"
      [icon]="crear?.icon!"
      [matTooltip]="crear?.tooltip!"
      (click)="crear?.accion()"
      style="width: 59px"
    ></app-button>
  }

  <!-- Exportar - Icono Imagen -->
  @if (exportar?.mostrar && exportar?.tipo === "img") {
    <img
      src="assets/iconos/export-boton.png"
      alt="exportar"
      style="width: 59px; cursor: pointer"
      [matTooltip]="exportar?.tooltip!"
      (click)="exportar?.accion()"
    />
  }

  <!-- Exportar - Icono Material -->
  @if (exportar?.mostrar && exportar?.tipo === "material") {
    <button
      mat-fab
      color="accent"
      style="width: 59px"
      [matTooltip]="crear?.tooltip!"
      (click)="crear?.accion()"
    >
      <mat-icon>{{ crear?.icon }}</mat-icon>
    </button>
  }
</div>

<!-- Paginator Arriba -->
@if (paginatorArriba) {
  <div class="paginator-container-top fondo-85" *ngIf="paginatorArriba">
    <app-paginator
      #topPaginator
      [saveName]="name"
      [length]="totalCount"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="true"
      [(query)]="query"
      (queryChange)="paginationChange()"
    ></app-paginator>
  </div>
}

<!-- Tabla -->
@if (dataSource) {
  <mat-table
    class="responsive fondo-85"
    *ngIf="dataSource"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <mat-header-row *matHeaderRowDef="columnsDef; sticky: true">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsDef"> </mat-row>
    <ng-container *ngIf="columnas?.[0]?.footer">
      <mat-footer-row *matFooterRowDef="columnsDef"></mat-footer-row>
    </ng-container>

    <!-- Columnas -->
    <ng-container *ngFor="let col of columnas">
      <ng-container
        *ngIf="
          $any(col.header.sort || col.row.field || col.header.label) as colDef
        "
        [matColumnDef]="colDef"
      >
        <!-- Header Sortable -->
        <ng-container *ngIf="col.header.sortable">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ col.header.label }}
          </mat-header-cell>
        </ng-container>
        <!-- Header Not Sortable -->
        <ng-container *ngIf="!col.header.sortable">
          <mat-header-cell *matHeaderCellDef>
            {{ col.header.label }}
          </mat-header-cell>
        </ng-container>

        <!-- Row -->
        <mat-cell *matCellDef="let row">
          <!-- Mobile Label -->
          <span class="mobile-label" *ngIf="col.header.label !== 'Acciones'">
            {{ col.header.label }}
          </span>

          <div>
            <!-- Field Content -->
            <span *ngIf="col.row.field as field">
              <!-- Prefix -->
              <span *ngIf="field">{{ col.row.prefix }} </span>
              <!-- Si es solo field -->
              <ng-container
                *ngIf="
                  !col.row.parse &&
                  !col.row.icono &&
                  !col.row.html &&
                  !col.row.links &&
                  !col.row.acciones &&
                  !col.row.accionesGrouped
                "
              >
                <ng-container *ngIf="row[field] as value">
                  <span
                    [class]="col?.row?.claseCss?.(row)"
                    [matTooltip]="$any(col?.row?.tooltip?.(row))"
                  >
                    {{
                      value
                        | dynamicPipe: col.row.pipe?.pipe : col.row.pipe?.args
                    }}
                  </span>
                </ng-container>
                <!-- No Data -->
                <ng-container *ngIf="!row[field] && col?.row?.noData">
                  <span class="no-info">
                    {{ col?.row?.noData }}
                  </span>
                </ng-container>
              </ng-container>
              <!-- Suffix -->
              <span *ngIf="field"> {{ col.row.suffix }}</span>
            </span>
            <!-- Field Parsed -->
            <span *ngIf="col.row.parse as parse">
              <!-- Prefix -->
              <span *ngIf="parse(row) !== undefined"
                >{{ col.row.prefix }}
              </span>
              <span
                [class]="col?.row?.claseCss?.(row)"
                [matTooltip]="$any(col?.row?.tooltip?.(row))"
              >
                {{
                  parse(row)
                    | dynamicPipe: col.row.pipe?.pipe : col.row.pipe?.args
                }}
              </span>
              <!-- No Data -->
              <ng-container
                *ngIf="
                  (parse(row) === undefined || parse(row) === '') &&
                  col?.row?.noData
                "
              >
                <span class="no-info">
                  {{ col?.row?.noData }}
                </span>
              </ng-container>
              <!-- Suffix -->
              <span *ngIf="parse(row) !== undefined">
                {{ col.row.suffix }}</span
              >
            </span>
            <!-- Field icono -->
            <span *ngIf="col.row.icono as fnIcono">
              <span *ngIf="fnIcono(row) as icono" style="margin: 0.5em">
                <mat-icon
                  *ngIf="icono.tipo !== 'img'"
                  [style.color]="icono.color"
                  [matTooltip]="icono.tooltip!"
                >
                  {{ icono.icon }}
                </mat-icon>
                <img
                  *ngIf="icono.tipo === 'img'"
                  [matTooltip]="icono.tooltip!"
                  [src]="icono.icon"
                  alt="icono"
                  style="width: 30px"
                />
              </span>
            </span>
            <!-- Field link -->
            <span *ngIf="col.row.links as fnLinks">
              <span *ngIf="fnLinks(row) as links" style="margin: 0.5em">
                <ng-container *ngFor="let l of links; trackBy: trackBy">
                  <p
                    [class]="l.class"
                    [routerLink]="l.route"
                    [matTooltip]="$any(l.tooltip)"
                    (click)="l.click?.()"
                  >
                    {{ l.link }}
                  </p>
                </ng-container>
              </span>
            </span>
            <!-- Field lista -->
            <span *ngIf="col.row.lista as fnLista">
              <span
                *ngIf="fnLista(row) as lista"
                style="margin: 0"
                class="lista"
              >
                <ng-container *ngFor="let el of lista; trackBy: trackBy">
                  <div>
                    {{ el }}
                  </div>
                </ng-container>
              </span>
            </span>
            <!-- Field HTML -->
            <ng-container *ngIf="col.row.html as html2">
              <div *ngIf="html2(row)" [innerHTML]="html(html2(row))"></div>
              <ng-container *ngIf="!html2(row) && col?.row?.noData">
                <span class="no-info">
                  {{ col?.row?.noData }}
                </span>
              </ng-container>
            </ng-container>

            <!-- Field acciones -->
            <span *ngIf="col.row.acciones as acciones" class="grid-container">
              <ng-container *ngFor="let accion of acciones">
                @if (accion?.tipo !== "img" && !accion?.oculta?.(row)) {
                  <app-button
                    matType="mat-mini-fab"
                    [color]="accion.color || 'primary'"
                    [icon]="accion.icon!"
                    [matTooltip]="accion.tooltip"
                    ngClass="grid-item"
                    [disabled]="!!accion?.disabled?.(row)"
                    (click)="
                      !accion?.disabled?.(row) ? accion.click(row) : null
                    "
                  ></app-button>
                }
                <img
                  *ngIf="accion?.tipo === 'img' && !accion?.oculta?.(row)"
                  class="grid-item"
                  [matTooltip]="accion.tooltip"
                  [src]="accion.icon"
                  alt="icono"
                  style="width: 30px; cursor: pointer"
                  (click)="accion.click(row)"
                />
              </ng-container>
            </span>
            <!-- Field acciones agrupadas -->
            <span *ngIf="col.row.accionesGrouped as accionesGrouped">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let accion of accionesGrouped">
                  <button
                    *ngIf="!accion?.oculta?.(row)"
                    mat-menu-item
                    [disabled]="accion?.disabled?.(row)"
                    (click)="accion.click(row)"
                  >
                    <mat-icon [color]="accion.color">{{
                      accion.icon
                    }}</mat-icon>
                    <span>{{ accion.tooltip }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </span>
          </div>
        </mat-cell>

        <!-- Footer -->
        <mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="col.footer?.fn as fn">
            <div>
              <!-- Prefix -->
              <span> {{ col.footer?.prefix }} </span>
              <!-- Function -->
              <span>
                {{
                  fn(col)
                    | dynamicPipe
                      : col.footer?.pipe?.pipe
                      : col.footer?.pipe?.args
                }}
              </span>
              <!-- Suffix -->
              <span> {{ col.footer?.suffix }} </span>
            </div>
          </ng-container>
        </mat-footer-cell>
      </ng-container>
    </ng-container>
  </mat-table>
}

<!-- Paginator Abajo -->
@if (paginatorAbajo) {
  <div class="paginator-container fondo-85" *ngIf="paginatorAbajo">
    <app-paginator
      #botPaginator
      [saveName]="name"
      [length]="totalCount"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="true"
      [(query)]="query"
      (queryChange)="paginationChange()"
    ></app-paginator>
  </div>
}
