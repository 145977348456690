<h2
  mat-dialog-title
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
>
  Editar Despacho
</h2>
<mat-dialog-content class="mat-typography" class="column-center">
  @if (form && !loading()) {
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Salió</mat-label>
        <input
          matInput
          placeholder="12:15"
          pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
          formControlName="salio"
        />
        <mat-icon matSuffix>schedule</mat-icon>
      </mat-form-field>
      @if (vehiculos) {
        <ng-select
          [items]="vehiculos"
          bindLabel="identificacion"
          bindValue="_id"
          formControlName="idActivo"
          placeholder="Vehículo"
          [notFoundText]="'No hay vehículos creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
      }
      @if (choferes) {
        <ng-select
          [items]="choferes"
          bindLabel="identificacionInterna"
          bindValue="_id"
          formControlName="idChofer"
          placeholder="Chofer"
          [notFoundText]="'No hay choferes creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
      }
      @if (recorridos) {
        <ng-select
          [items]="recorridos"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idsRecorridos"
          placeholder="Recorridos"
          [notFoundText]="'No hay recorridos creados'"
          style="width: 100%"
          multiple="true"
          searchable="true"
        >
        </ng-select>
      }

      <div class="row-center" style="width: 100%">
        <mat-checkbox formControlName="cancelado">¿Cancelado?</mat-checkbox>
      </div>
    </form>
  }
  @if (loading()) {
    <div class="column-center" style="width: 100%; height: 100%">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="50"
        strokeWidth="5"
      ></mat-progress-spinner>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Cerrar</button>
  <button
    mat-raised-button
    [disabled]="loading"
    [mat-dialog-close]="true"
    cdkFocusInitial
    color="primary"
    type="button"
    (click)="editar()"
  >
    Editar
  </button>
</mat-dialog-actions>
